<template>
  <div
    id="user-detail-modal"
    ref="modal"
    aria-hidden="true"
    aria-labelledby="user-detail-modal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      v-if="triad && triad.apprentice"
      class="modal-dialog modal-dialog-centered modal-xl"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title textStyle">
            {{ $t("absence.sheet_from") }} {{ triad.apprentice.first_name }}
            {{ triad.apprentice.last_name }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body">
          <div class="widget">
            <div class="widget-heading">
              <h2 class="textStyle">{{ $t("absence.personnal_data") }}</h2>
              <button class="btn-icon">
                <FontAwesomeIcon icon="fa-light fa-pen" />
              </button>
            </div>
            <div class="widget-content">
              <div v-if="triad.apprentice.last_name" class="row">
                <div class="col-4">Nom :<br /></div>
                <div class="col-8">
                  <span class="fw-bold textStyle">{{
                    triad.apprentice.last_name
                  }}</span
                  ><br />
                </div>
              </div>
              <div v-if="triad.apprentice.first_name" class="row">
                <div class="col-4">Prénom :<br /></div>
                <div class="col-8">
                  <span class="fw-bold textStyle"
                    >{{ triad.apprentice.first_name }} </span
                  ><br />
                </div>
              </div>
              <div v-if="triad.apprentice.email" class="row">
                <div class="col-4">Email :<br /></div>
                <div class="col-8">
                  <span class="fw-bold textStyle"
                    >{{ triad.apprentice.email }} </span
                  ><br />
                </div>
              </div>
            </div>
          </div>
          <div class="widget">
            <div class="widget-heading">
              <h2 class="textStyle">{{ $t("absence.absences") }}</h2>
              <button class="btn-icon">
                <FontAwesomeIcon icon="fa-light fa-plus" />
              </button>
            </div>
            <div class="widget-content">
              <SortTableComponent :columns="columns" :datas="datas" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { mapState } from "pinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import { useTriadStore } from "@/store/triad/triad";

export default {
  name: "TriadUserDetailComponent",
  components: { SortTableComponent, FontAwesomeIcon },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      columns: [],
      datas: [],
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
  },
  watch: {
    id() {
      if (this.id) {
        const container = document.getElementById("user-detail-modal");
        const modal = new Modal(container);
        modal.show();
        this.$emit("opening");
      }
    },
  },
  mounted() {
    this.$refs.modal.addEventListener("hidden.bs.modal", this.hidden);
  },
  methods: {
    hidden() {
      this.$emit("hidden");
    },
  },
};
</script>

<style lang="scss" scoped></style>
