<template>
  <FormTabsBlockComponent
    :invalidTab="invalidTab"
    :loading="loading"
    :tabs="tabs"
    @formCancel="cancel"
    @formSubmit="submit"
  />
</template>

<script>
import FormTabsBlockComponent from "@/components/blocks/FormTabsBlockComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { notificationManager } from "@/services/utilities/notification-manager";
import { formValidator } from "@/services/form/form-validator";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";

export default {
  name: "ContractEditView",
  components: { FormTabsBlockComponent },
  data() {
    return {
      tabs: [],
      loading: false,
      invalidTab: null,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.id = this.$router.currentRoute.value.params.id;
      this.loading = true;
      if (this.id) {
        this.$router.currentRoute.value.meta.title = this.$tc(
          "global.edit.title",
          1,
          {
            name: this.$tc("studea_manager.contract.title", 1).toLowerCase(),
          },
        );
      }

      studeaManagerManager
        .form("contract", this.$route.params.trainingCourseId, this.id)
        .then((data) => {
          this.tabs = data.tabs;
          this.loading = false;
        });
    },

    cancel() {
      this.$router.push({ name: "studeaManagerContractList" });
    },

    submit() {
      let response = [];
      this.loading = true;
      this.invalidTab = null;

      Object.values(this.tabs).forEach((tab) => {
        if (!tab.hidden) {
          response.push({
            form: tab.models
              ? formManager.processForm(
                  tab.models,
                  false,
                  this.id && tab.code === "contract" ? this.id : null,
                )
              : [],
            code: tab.code,
          });
        }
      });

      response[1]["form"]["discr"] = "company";

      let urlCall =
        "/app/studea-manager/" +
        this.$route.params.trainingCourseId +
        "/contract/" +
        this.$route.params.triadId +
        (this.id ? "/" + this.id : "");
      (this.id
        ? apiConnection.put(urlCall, response)
        : apiConnection.post(urlCall, response)
      ).then((data) => {
        if (data && !data.hasError) {
          this.$router.push({ name: "studeaManagerContractList" });
          const message = this.$tc(
            "global." + (this.id ? "edit" : "add") + ".title",
            1,
            {
              name: this.$tc("studea_manager.contract.title", 2).toLowerCase(),
            },
          );
          notificationManager.showNotification("success", message);
        } else if (data.responses && data.responses[0].validationErrors) {
          notificationManager.showNotification(
            "error",
            this.$t("signed_error"),
          );
          Object.entries(this.tabs).forEach((tab) => {
            const errors = formValidator.processResponses(
              data.responses[0].validationErrors,
              tab[1].models,
            );
            if (errors.length) {
              this.invalidTab = this.id ? tab[1] : tab[0];
            }
          });
        }
        this.loading = false;
      });
    },

    validTab() {
      this.invalidTab = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
