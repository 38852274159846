<template>
  <div class="news__card">
    <div class="news__content">
      <div class="news__heading textStyle">
        <span
          :class="
            'fw-bold badge ' +
            (tagCorrespondance &&
            tagCorrespondance.length &&
            tagCorrespondance.find((element) => element.id === this.news.tag) &&
            tagCorrespondance.find((element) => element.id === this.news.tag)
              .color
              ? tagCorrespondance.find(
                  (element) => element.id === this.news.tag,
                ).color
              : 'blueBadge')
          "
          >{{
            news.tag &&
            tagCorrespondance &&
            tagCorrespondance.length &&
            tagCorrespondance.find((element) => element.id === news.tag) &&
            tagCorrespondance.find((element) => element.id === news.tag).name
              ? tagCorrespondance.find((element) => element.id === news.tag)
                  .name
              : $t("admin.news.list.title")
          }}</span
        >
        -
        <span class="text-black">
          {{ $t("admin.news.list.uploaded_date") }}
          {{ formatedDate(news.dateToDisplay) }}
        </span>
        <h3 class="news__title mt-2">{{ news.title }}</h3>
      </div>
      <div class="news__text mb-2">{{ news.subTitle }}</div>
      <router-link
        class="btn btnStyle btn-success"
        :to="{
          name: isAdminRoute() ? 'adminNewsShow' : 'newsShow',
          params: { id: news.id },
        }"
        type="button"
      >
        {{ $t("admin.news.list.see_more") }}
      </router-link>
    </div>
    <div class="news__img" v-if="picture">
      <img :alt="news.title" :src="picture" />
    </div>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";
import { mapState } from "pinia";
import { pictureManager } from "@/services/utilities/picture-manager";
import { routeManager } from "@/services/utilities/route-manager";
import { useNewsStore } from "@/store/news/news";
import { useUserStore } from "@/store/user/user";

export default {
  name: "NewsCardComponent",
  data() {
    return {
      picture: "",
      picutreMobile: "",
      isMobile: false,
    };
  },
  props: {
    news: {
      type: [],
      required: true,
      default: [],
    },
  },
  mounted() {
    if (window.innerWidth < 576) {
      this.picture = pictureManager.setPicture(this.news.picture, "mobile");
    } else {
      this.picture = pictureManager.setPicture(this.news.picture, "list");
    }
    window.addEventListener("resize", this.reportWindowSize);
  },
  unmounted() {
    window.removeEventListener("resize", this.reportWindowSize);
  },
  watch: {
    isMobile() {
      if (this.isMobile) {
        this.picture = pictureManager.setPicture(this.news.picture, "mobile");
      } else {
        this.picture = pictureManager.setPicture(this.news.picture, "list");
      }
    },
  },
  computed: {
    ...mapState(useNewsStore, {
      tagCorrespondance: (store) => store.tagCorrespondance,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  methods: {
    formatedDate(date) {
      return dateFormatter.format(date, "short");
    },

    reportWindowSize() {
      this.isMobile = window.innerWidth < 576;
    },

    isAdminRoute() {
      return routeManager.isNewsAdminRoute(this.$route.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  &__card {
    border: 1px solid $grey;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    height: 220px;
    border-radius: 6px;
    margin-bottom: 20px;

    @include up-to-lg {
      flex-direction: row;
    }

    @include mobile {
      height: auto;
      flex-direction: column-reverse;
    }
  }

  &__heading {
    margin-bottom: 15px;
    font-size: 14px;

    .redBadge {
      background-color: $red !important;
    }

    .blueBadge {
      background-color: $blue !important;
    }

    .greenBadge {
      background-color: $green !important;
    }

    .yellowBadge {
      background-color: $yellow !important;
    }
  }

  &__title {
    font-size: 17px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__item + &__item {
    margin-top: 20px;
  }

  &__content {
    flex: 0 1 70%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .btn {
      width: fit-content;
    }
  }

  &__img {
    flex: 0 1 30%;
    @include up-to-lg {
      flex: 0 1 25%;
    }
    height: auto;

    @include mobile {
      height: 175px;
      flex: 0 1 auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @include up-to-xxl {
      max-width: 180px;
    }
    @include up-to-xl {
      max-width: unset;
    }
  }
}
</style>
