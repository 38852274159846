<template>
  <div
    :class="'skill' + (name ? ' -sub-' + index : '')"
    v-if="skills && Object.keys(skills).length"
  >
    <div class="skill__row -heading">
      <h3 class="skill__name mb-0">{{ name }}</h3>
      <div class="skill__res">
        <div v-if="isVisible('apprentice')" class="skill__col -apprentice">
          <span v-if="triad && triad.apprentice && triad.apprentice.fullName">
            {{ triad.apprentice.fullName }}
          </span>
          <span v-else>--</span>
        </div>
        <div v-if="isVisible('tutor')" class="skill__col -tutor">
          <span v-if="triad && triad.tutor && triad.tutor.fullName">
            {{ triad.tutor.fullName }}
          </span>
          <span v-else>--</span>
        </div>
        <div
          v-if="isVisible('apprentice_master')"
          class="skill__col -apprenticeMaster"
        >
          <span
            v-if="
              triad && triad.apprenticeMaster && triad.apprenticeMaster.fullName
            "
          >
            {{ triad.apprenticeMaster.fullName }}
          </span>
          <span v-else>--</span>
        </div>
        <div
          v-if="isVisible('studea_manager')"
          class="skill__col -studeaManager"
        >
          <span
            v-if="studeaManagerProfileLabel && studeaManagerProfileLabel.title"
            >{{ studeaManagerProfileLabel.title }}</span
          >
        </div>
      </div>
      <div class="skill__btn"></div>
    </div>
    <div v-for="skill in skills" :key="skill.id">
      <div class="skill__row">
        <div v-if="skillId === skill.id">
          <BlockLoader :loading="historicLoading" />
        </div>
        <div class="skill__name">
          {{ skill.name }}
          <span
            v-if="skill.tooltip"
            class="form-info"
            v-tooltip="{ title: skill.tooltip }"
          >
            <FontAwesomeIcon icon="fa-light fa-circle-question" />
          </span>
        </div>
        <div class="skill__res">
          <div v-if="isVisible('apprentice')" class="skill__col">
            <div
              v-if="isVisible('apprentice')"
              class="skill__col -mobile -apprentice"
            >
              <span
                v-if="triad && triad.apprentice && triad.apprentice.fullName"
              >
                {{ triad.apprentice.fullName }}
              </span>
              <span v-else>--</span>
            </div>
            <SkillLevelComponent
              :skill="skill"
              :reply="reply('apprentice')"
              :profile="1"
              :maxSkillLevel="
                triad.trainingCourse ? triad.trainingCourse.maxSkillLevel : 0
              "
              type="apprentice"
              @select="update"
            />
            <div
              v-if="
                replyHistoric &&
                replyHistoric.apprentice &&
                replyHistoric.apprentice.length &&
                skillId === skill.id
              "
            >
              <div class="historic__container">
                <div
                  v-for="apprenticeReply in replyHistoric.apprentice"
                  :key="apprenticeReply.id"
                  class="historic -apprentice"
                >
                  <span>{{ apprenticeReply.skillReply.user.fullName }}</span
                  ><br />
                  <span class="fst-italic fw-light"
                    >{{ formatDate(apprenticeReply.skillReply.date) }} -
                  </span>
                  <span>
                    {{ apprenticeReply.value }}/{{
                      trainingCourse.maxSkillLevel
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="isVisible('tutor')" class="skill__col">
            <div v-if="isVisible('tutor')" class="skill__col -mobile -tutor">
              <span v-if="triad && triad.tutor && triad.tutor.fullName">
                {{ triad.tutor.fullName }}
              </span>
              <span v-else>--</span>
            </div>
            <SkillLevelComponent
              :profile="2"
              :reply="reply('tutor')"
              :skill="skill"
              :maxSkillLevel="
                triad.trainingCourse ? triad.trainingCourse.maxSkillLevel : 0
              "
              type="tutor"
              @select="update"
            />

            <div
              v-if="
                replyHistoric &&
                replyHistoric.tutor &&
                replyHistoric.tutor.length &&
                skillId === skill.id
              "
            >
              <div class="historic__container">
                <div
                  v-for="tutorReply in replyHistoric.tutor"
                  :key="tutorReply.id"
                  class="historic -tutor"
                >
                  <span>{{ tutorReply.skillReply.user.fullName }}</span
                  ><br />
                  <span class="fst-italic fw-light"
                    >{{ formatDate(tutorReply.skillReply.date) }} -
                  </span>
                  <span>
                    {{ tutorReply.value }}/{{
                      trainingCourse.maxSkillLevel
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div v-if="isVisible('apprentice_master')" class="skill__col">
            <div
              v-if="isVisible('apprentice_master')"
              class="skill__col -mobile -apprenticeMaster"
            >
              <span
                v-if="
                  triad &&
                  triad.apprenticeMaster &&
                  triad.apprenticeMaster.fullName
                "
              >
                {{ triad.apprenticeMaster.fullName }}
              </span>
            </div>
            <SkillLevelComponent
              :skill="skill"
              :reply="reply('apprenticeMaster')"
              :profile="3"
              :maxSkillLevel="
                triad.trainingCourse ? triad.trainingCourse.maxSkillLevel : 0
              "
              type="apprenticeMaster"
              @select="update"
            />

            <div
              v-if="
                replyHistoric &&
                replyHistoric.apprenticeMaster &&
                replyHistoric.apprenticeMaster.length &&
                skillId === skill.id
              "
            >
              <div class="historic__container">
                <div
                  v-for="apprenticeMasterReply in replyHistoric.apprenticeMaster"
                  :key="apprenticeMasterReply.id"
                  class="historic -apprenticeMaster"
                >
                  <span>{{
                    apprenticeMasterReply.skillReply.user.fullName
                  }}</span
                  ><br />
                  <span class="fst-italic fw-light"
                    >{{ formatDate(apprenticeMasterReply.skillReply.date) }} -
                  </span>
                  <span>
                    {{ apprenticeMasterReply.value }}/{{
                      trainingCourse.maxSkillLevel
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="isVisible('studea_manager')" class="skill__col">
            <div
              v-if="isVisible('studea_manager')"
              class="skill__col -mobile -studeaManager"
            >
              <span
                v-if="
                  studeaManagerProfileLabel && studeaManagerProfileLabel.title
                "
                >{{ studeaManagerProfileLabel.title }}</span
              >
            </div>
            <SkillLevelComponent
              :profile="4"
              :reply="reply('studeaManager')"
              :skill="skill"
              :maxSkillLevel="
                triad.trainingCourse ? triad.trainingCourse.maxSkillLevel : 0
              "
              type="studeaManager"
              @select="update"
            />
            <div
              v-if="
                replyHistoric &&
                replyHistoric.studeaManager &&
                replyHistoric.studeaManager.length &&
                skillId === skill.id
              "
            >
              <div class="historic__container">
                <div
                  v-for="studeaManagerReply in replyHistoric.studeaManager"
                  :key="studeaManagerReply.id"
                  class="historic -studeaManager"
                >
                  <span>{{ studeaManagerReply.skillReply.user.fullName }}</span
                  ><br />
                  <span class="fst-italic fw-light">
                    {{ formatDate(studeaManagerReply.skillReply.date) }} -
                  </span>
                  <span>
                    {{ studeaManagerReply.value }}/{{
                      trainingCourse.maxSkillLevel
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="skill__btn">
          <button
            v-if="showJustificativeAddButton(skill)"
            :class="
              'btn-icon -justificative-file ' +
              (isJustificativeFile(skill.id) ? 'green' : '')
            "
            @click="openJustificativeForm(skill.id)"
            :disabled="!checkUserAnswer(skill.id, userProfile)"
            v-tooltip="{
              title: checkUserAnswer(skill.id, userProfile)
                ? $t('sendJustificativePiece')
                : $t('impossibleJustificativeSending'),
            }"
          >
            <FontAwesomeIcon icon="fa-light fa-paperclip" />
          </button>
          <button
            class="btn-icon ms-2"
            @click="showHistoric(skill.id)"
            v-tooltip="{ title: $t('seeHistoric') }"
          >
            <FontAwesomeIcon
              v-if="this.skillId !== skill.id"
              icon="fa-light fa-chart-line"
            />
            <FontAwesomeIcon v-else icon="fa-light fa-xmark" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import SkillLevelComponent from "@/components/skills/SkillLevelComponent.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { triadManager } from "@/services/triad/triad-manager";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { usePlatformStore } from "@/store/platform/platform";
import { useUserStore } from "@/store/user/user";

export default {
  name: "SkillFrontListComponent",
  components: { FontAwesomeIcon, BlockLoader, SkillLevelComponent },
  props: {
    skills: {
      type: [],
      required: true,
      default: [],
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    replyHistoric: {
      type: Object,
      required: false,
      default: null,
    },
    skillId: {
      type: Number,
      required: false,
      default: null,
    },
    historicLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
      currentSessionSkillReply: (store) => store.currentSessionSkillReply,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(usePlatformStore, {
      studeaManagerProfileLabel: (store) => store.studeaManagerProfileLabel,
    }),
    ...mapState(useUserStore, {
      userProfile: (store) => store.userProfile,
    }),
  },
  methods: {
    showHistoric(skill) {
      this.$emit("showHistoric", skill);
    },

    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    isVisible(role) {
      if (this.triad.trainingCourse && this.triad.trainingCourse.skillRoles) {
        switch (role) {
          case "apprentice":
            return this.triad.trainingCourse.skillRoles.includes(1);
          case "tutor":
            return this.triad.trainingCourse.skillRoles.includes(2);
          case "apprentice_master":
            return this.triad.trainingCourse.skillRoles.includes(3);
          case "studea_manager":
            return this.triad.trainingCourse.skillRoles.includes(4);
        }
      }
    },

    update(skill) {
      this.$emit("update", skill);
    },

    reply(type) {
      return this.currentSessionSkillReply
        ? this.currentSessionSkillReply[type]
        : null;
    },

    setCurrentUserSkillReply(skillId, profile) {
      let result = null;

      if (
        this.currentSessionSkillReply[profile] &&
        Object.values(this.currentSessionSkillReply[profile].skillAnswers).find(
          (element) => element.skill.id === skillId,
        )
      ) {
        result = this.currentSessionSkillReply[profile].skillAnswers.find(
          (element) => element.skill.id === skillId,
        ).id;
      }
      return result;
    },

    checkUserAnswer(skillId) {
      let skillReply = null;
      switch (this.userProfile) {
        case triadManager.APPRENTICE_PROFILE:
          skillReply = this.setCurrentUserSkillReply(skillId, "apprentice");
          break;
        case triadManager.TUTOR_PROFILE:
          skillReply = this.setCurrentUserSkillReply(skillId, "tutor");
          break;
        case triadManager.APPRENTICE_MASTER_PROFILE:
          skillReply = this.setCurrentUserSkillReply(
            skillId,
            "apprenticeMaster",
          );
          break;
        case triadManager.STUDEA_MANAGER_PROFILE:
          skillReply = this.setCurrentUserSkillReply(skillId, "studeaManager");
          break;
      }
      return skillReply;
    },

    openJustificativeForm(skillId) {
      this.$emit("openJustificativeForm", this.checkUserAnswer(skillId));
    },

    showJustificativeAddButton(skill) {
      return (
        this.userProfile !== triadManager.STUDEA_MANAGER_PROFILE &&
        skill.needJustificative &&
        this.triad.lastSkillReply
      );
    },

    isJustificativeFile(skillId) {
      let isJustified = false;

      const triadMembers = [
        "apprentice",
        "tutor",
        "apprenticeMaster",
        "studeaManager",
      ];
      triadMembers.forEach((member) => {
        let currentMember = {};
        if (
          this.currentSessionSkillReply &&
          this.currentSessionSkillReply[member]
        ) {
          currentMember = this.currentSessionSkillReply[
            member
          ].skillAnswers.find((element) => element.skill.id === skillId);
        }
        if (
          currentMember &&
          ((currentMember.justificativeFile &&
            currentMember.justificativeFile.id) ||
            (currentMember.justificativeFile2 &&
              currentMember.justificativeFile2.id) ||
            (currentMember.justificativeFile3 &&
              currentMember.justificativeFile3.id) ||
            (currentMember.justificativeFile4 &&
              currentMember.justificativeFile4.id) ||
            (currentMember.justificativeFile5 &&
              currentMember.justificativeFile5.id))
        ) {
          isJustified = true;
        }
      });
      return isJustified;
    },
  },
};
</script>

<style lang="scss" scoped>
.skill {
  &.-sub {
    @for $i from 1 through 7 {
      &-#{$i} {
        .skill__name {
          padding-left: $i * 10px;
        }
      }
    }
  }

  > div {
    &:nth-child(2) {
      .skill__row {
        border: none;
      }
    }
  }

  &__row {
    border-top: 1px dashed $light-grey;
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    @include up-to-md {
      flex-direction: column;
    }

    &.-heading {
      border-top: none;

      @include up-to-md {
        display: none;
      }
    }
  }

  &__name {
    width: 40%;
    padding-right: 15px;

    @include up-to-lg {
      width: 27%;
    }

    @include up-to-md {
      width: 100%;
      padding-right: 25px;
    }
  }

  &__col {
    color: var(--skill-color);
    font-weight: bold;
    flex: 1;
    min-width: 136px;

    &.-apprentice {
      --skill-color: #6ac7a4;
    }

    &.-tutor {
      --skill-color: #00275e;
    }

    &.-apprenticeMaster {
      --skill-color: #da5164;
    }

    &.-studeaManager {
      --skill-color: #f8a007;
    }

    &.-mobile {
      display: none;

      @include up-to-md {
        display: block;
      }
    }
  }

  &__res {
    display: flex;
    justify-content: center;
    width: 55%;

    @include up-to-md {
      width: 100%;
      margin-top: 10px;
      flex-wrap: wrap;
    }
  }

  &__btn {
    width: 5%;
    text-align: right;

    @include up-to-md {
      position: absolute;
      right: 0;
      top: 12px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.historic {
  margin-bottom: 10px;
  color: var(--skill-color);

  &__container {
    border-top: 1px dashed $light-grey;
    padding-top: 10px;
  }

  &.-apprentice {
    --skill-color: #6ac7a4;
  }

  &.-tutor {
    --skill-color: #00275e;
  }

  &.-apprenticeMaster {
    --skill-color: #da5164;
  }

  &.-studeaManager {
    --skill-color: #f8a007;
  }
}

.-justificative-file {
  &:disabled {
    cursor: not-allowed;
  }

  &.green {
    color: #6ac7a4;
  }
}
</style>
