import router from "@/router";

class SortableTableManager {
  getColumns(data, tradPrefix = null) {
    let columns = [];
    Object.keys(data).forEach((el) => {
      if (el !== "id") {
        // Exclude columns for planContract list.
        if (router.currentRoute.value.path.includes("plan-contract/list")) {
          if (el === "active" || el === "activeForRecruitment") {
            return;
          }
        }
        columns.push({
          name: el,
          tradPrefix: tradPrefix,
          sortable: true,
          sortWay: "",
          filterActive: true,
        });
      }
    });
    return columns;
  }

  getEntriesColumns(columns, oldColumns = null) {
    let res = [];
    if (columns) {
      Object.entries(columns).forEach((column) => {
        if (column[1] !== "id" && column[1] !== "data") {
          res.push({
            name: column[1],
            sortable: true,
            sortWay:
              oldColumns &&
              oldColumns[column[0]] &&
              oldColumns[column[0]].sortWay
                ? oldColumns[column[0]].sortWay
                : "",
            isTranslatedName: true,
            index: column[0],
            filterActive: true,
          });
        }
      });
    }
    return res;
  }
}

export const sortableTableManager = new SortableTableManager();
