<template>
  <div :class="'note-inner-content --' + note.profile">
    <div class="note-content">
      <p class="note-title">{{ note.title }}</p>
      <p class="meta-time">
        <span :class="'--' + note.profile">{{ note.user.fullName }}</span> -
        {{ note.date ? formatDate(note.date) : "" }}
      </p>
      <div class="note-description-content" v-html="text(note)"></div>
    </div>
    <div class="note-action">
      <button class="btn-icon" @click="pin(note)">
        <FontAwesomeIcon :icon="icon(note)" />
      </button>
      <button
        v-if="note.user.id === user.id"
        class="btn-icon"
        @click="openModal(note.id)"
      >
        <FontAwesomeIcon icon="fa-light fa-pen" />
      </button>
      <button v-if="!note.pinned" class="btn-icon" @click="show(note)">
        <FontAwesomeIcon icon="fa-light fa-eye" />
      </button>
      <button
        class="btn-icon"
        v-if="note.user.id === user.id"
        @click="remove(note.id)"
      >
        <FontAwesomeIcon icon="fa-light fa-trash" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { notepadManager } from "@/services/notepad/notepad-manager";
import { useUserStore } from "@/store/user/user";

export default {
  name: "NotepadNoteComponent",
  props: {
    note: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  methods: {
    formatDate(date) {
      return dateFormatter.format(date, "long");
    },

    pin(note) {
      this.$emit("loading", true);
      notepadManager.pin(note).then(() => {
        this.initTriad();
      });
    },

    remove(id) {
      this.loading = true;
      notepadManager.remove(id).then(() => {
        this.initTriad();
      });
    },

    initTriad() {
      this.$emit("initTriad");
    },

    icon(note) {
      return note.pinned ? "fa-solid fa-star" : "fa-light fa-star";
    },

    text(note) {
      return !note.pinned && note.text.length > 115
        ? `${note.text.substring(0, 115)}…`
        : note.text;
    },

    show() {
      this.$emit("show", this.note);
    },

    openModal() {
      this.$emit("openModal", this.note.id);
    },
  },
};
</script>

<style scoped lang="scss">
span {
  &.--1 {
    color: $green;
  }

  &.--2 {
    color: $blue;
  }

  &.--3 {
    color: $red;
  }

  &.--4 {
    color: $orange;
  }
}
</style>
