class RouteManager {
  constructor() {}

  NEWS_ADMIN_ROUTES = [
    "adminNewsShow",
    "adminNewsList",
    "adminNewsLayout",
    "adminHome",
  ];
  isNewsAdminRoute(route) {
    if (this.NEWS_ADMIN_ROUTES.includes(route)) {
      return true;
    }
  }
}

export const routeManager = new RouteManager();
