<template>
  <div class="d-flex justify-content-between align-items-center mb-2 px-2 mx-1">
    <span class="fw-bold">{{
      $t("admin.studeaManagerProfile.all_access")
    }}</span>
    <SwitchTypeComponent :model="switchAllModel" @update="switchAll" />
  </div>
  <div class="role">
    <div class="role__item" v-for="(domain, label) in permissions" :key="label">
      <div class="role__label">
        {{ label }}
        <SwitchTypeComponent
          @draft="updateSwitch"
          :model="switchModels[label]"
        />
      </div>
      <div
        :class="
          'role__permissions' + (switchModels[label].vars.value ? ' -on' : '')
        "
      >
        <div
          class="role__permission"
          v-for="permission in domain"
          :key="permission.access.id"
        >
          <div class="role__name">
            {{ permission.access.name }}
          </div>
          <div class="role__select">
            <SelectTypeComponent
              :key="key"
              :model="permissionModel(permission)"
              @draft="updated"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectTypeComponent from "@/components/form/SelectTypeComponent.vue";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";

export default {
  name: "RoleTypeComponent",
  props: {
    model: {
      default: [],
      type: [],
      required: true,
    },
  },
  data() {
    return {
      itemModel: this.model,
      permissions: {},
      key: 0,
      switchModels: {},
      switchAllModel: {
        vars: {
          value: true,
          name: "switchAll",
          id: "switchAll",
          block_prefixes: ["", "switch"],
        },
      },
    };
  },
  mounted() {
    this.processPermissions();
    this.setSwitchAllModel();
  },
  methods: {
    updated(model) {
      const item = this.itemModel.vars.value.find(
        (item) => item.access.name === model.vars.name,
      );
      item.level = model.vars.value.data;
      this.$emit("updated", this.itemModel);
      this.$emit("draft", this.itemModel);
    },

    setSwitchModel(label, domain) {
      let open = false;
      if (Object.keys(domain).length) {
        Object.values(domain).forEach((permission) => {
          if (!open && permission.level) {
            open = true;
          }
        });
      }
      return {
        vars: {
          value: open,
          name: label,
          id: label,
          block_prefixes: ["", "switch"],
        },
      };
    },

    setSwitchAllModel() {
      let open = true;
      if (Object.keys(this.permissions).length && open) {
        Object.values(this.permissions).forEach((domain) => {
          if (Object.keys(domain).length && open) {
            Object.values(domain).forEach((permission) => {
              const maxLevel =
                permission.access.levelsAllowed.length === 4 ? 3 : 4;
              if (open && permission.level < maxLevel) {
                open = false;
              }
            });
          }
        });
      }
      this.switchAllModel.vars.value = open;
    },

    switchAll(value) {
      Object.values(this.switchModels).forEach((model) => {
        model.vars.value = value;
        this.updateSwitch(model, false);
      });
      this.key++;
    },

    updateSwitch(model, update = true) {
      Object.values(this.permissions[model.vars.id]).forEach((permission) => {
        const maxLevel = permission.access.levelsAllowed.length === 4 ? 3 : 4;
        permission.level = !model.vars.value ? 0 : maxLevel;
      });
      this.switchAllModel.vars.value = !model.vars.value
        ? false
        : this.switchAllModel.vars.value;
      if (update) {
        this.key++;
      }
    },

    permissionModel(permission) {
      let choices = [];
      permission.access.levelsAllowed.forEach((level) => {
        choices.push({
          label: this.$t("admin.user.role." + level),
          value: this.value(level).toString(),
          data: this.value(level),
        });
      });

      return {
        vars: {
          value: permission.level.toString(),
          block_prefixes: ["", "choice"],
          expanded: false,
          required: true,
          multiple: false,
          choices: choices,
          id: permission.access.name,
          name: permission.access.name,
          attr: {},
        },
      };
    },

    value(level) {
      let value = 0;
      switch (level) {
        case "VIEW":
          value = 1;
          break;
        case "EDIT":
          value = 2;
          break;
        case "DELETE":
          value = 3;
          break;
        case "ALL":
          value = 4;
          break;
      }
      return value;
    },

    processPermissions() {
      Object.values(this.itemModel.vars.value).forEach((permission) => {
        if (!this.permissions[permission.access.domain.name]) {
          this.permissions[permission.access.domain.name] = [];
        }
        this.permissions[permission.access.domain.name].push(permission);
        this.switchModels[permission.access.domain.name] = this.setSwitchModel(
          permission.access.domain.name,
          this.permissions[permission.access.domain.name],
        );
      });
    },
  },
  components: { SwitchTypeComponent, SelectTypeComponent },
};
</script>

<style lang="scss" scoped>
.role {
  border: 1px dashed $m-color_4;
  border-radius: 6px;

  &__label {
    font-size: 18px;
    font-weight: 700;
    padding: 5px 10px;
    border-bottom: 1px dashed $m-color_4;
    display: flex;
    justify-content: space-between;
  }

  &__permissions {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease-in-out;

    &.-on {
      max-height: 1000px;
      overflow: initial;
    }
  }

  &__permission {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed $m-color_4;
    padding: 5px 10px;
    align-items: center;
  }

  &__select {
    max-width: 250px;
    flex: 1;
  }
}
</style>
