<template>
  <div class="row">
    <div class="col-lg-8">
      <!--      <div class="widget">-->
      <!--        <div class="widget-heading">-->
      <!--          <h2 class="textStyle">{{ $t("admin.home.title3") }}</h2>-->
      <!--        </div>-->
      <!--        <div class="widget-content">-->
      <!--          <div class="d-flex">-->
      <!--            <div class="me-3 textStyle">-->
      <!--              OFAlink-->
      <!--              <span class="badge bg-success ms-2">{{-->
      <!--                $t("admin.home.active")-->
      <!--              }}</span>-->
      <!--            </div>-->
      <!--            |-->
      <!--            <div class="ms-3 textStyle">-->
      <!--              SSO ({{ $t("admin.home.unique_connection") }})-->
      <!--              <span class="badge bg-success ms-2">{{-->
      <!--                $t("admin.home.active")-->
      <!--              }}</span>-->
      <!--              <div>{{ $t("admin.home.apprentices_mentor") }}</div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="widget widget-summary-1">
        <div class="widget-heading">
          <h2 class="textStyle">{{ $t("admin.home.title4") }}</h2>
        </div>
        <div class="widget-content stats">
          <div class="stats">
            <Splide
              :key="key"
              :has-track="false"
              :options="splideOptions"
              aria-label="Carousel de statistiques"
            >
              <SplideTrack>
                <SplideSlide>
                  <NumberCardChart :stats="stats" />
                </SplideSlide>
              </SplideTrack>
              <div class="splide__arrows">
                <button class="splide__arrow splide__arrow--prev textStyle">
                  <FontAwesomeIcon icon="fa-light fa-chevron-right" />
                </button>
                <button class="splide__arrow splide__arrow--next textStyle">
                  <FontAwesomeIcon icon="fa-light fa-chevron-right" />
                </button>
              </div>
            </Splide>
          </div>
          <BlockLoader :loading="statsLoading" />
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <NewsBlockComponent :loading="loading" :news="newsFiltered" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import NewsBlockComponent from "@/components/blocks/NewsBlockComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import NumberCardChart from "@/components/charts/NumberCardChart.vue";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useThemeStore } from "@/store/theme/theme";
import { useMainStore } from "@/store/main/main";
import { useNewsStore } from "@/store/news/news";
import { usePlatformStore } from "@/store/platform/platform";
import { adminManager } from "@/services/admin/admin-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";

export default {
  name: "AdminHomeView",
  data() {
    return {
      splideOptions: {
        type: "slide",
        rewind: true,
        perPage: 1,
        width: "100%",
        arrows: true,
        pagination: false,
        autoplay: false,
        drag: false,
        start: 0,
      },
      key: 0,
      stats: [],
      newsFiltered: [],
      loading: true,
      statsLoading: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useThemeStore, {
      isDark: (store) => store.isDark_mode,
    }),
    ...mapState(useMainStore, {
      windowWidth: (store) => store.windowWidth,
      sideBarOpened: (store) => store.sideBarOpened,
    }),
    ...mapState(useNewsStore, {
      news: (store) => store.news,
    }),
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
    }),
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 992) {
        this.render();
      }
    },
    sideBarOpened() {},
  },
  methods: {
    initStats() {
      this.statsLoading = true;
      adminManager.list("statistic", false).then((res) => {
        this.statsLoading = false;
        this.stats = res;
      });
    },
    render() {
      setTimeout(() => {
        this.key++;
      }, 600);
    },

    init() {
      this.loading = true;
      this.fetchNews().then(() => {
        this.loading = false;
        this.newsFiltered = this.news.slice(0, 3);
      });
      this.initStats();
    },

    ...mapActions(useNewsStore, ["fetchNews"]),
  },
  components: {
    BlockLoader,
    NumberCardChart,
    FontAwesomeIcon,
    NewsBlockComponent,
    Splide,
    SplideSlide,
    SplideTrack,
  },
};
</script>

<style lang="scss" scoped>
.stats {
  padding: 0 30px;
}

.splide__arrow {
  background: none;
  font-size: 24px;

  &--prev {
    left: -50px;
  }

  &--next {
    right: -50px;
  }
}

.splide__splide {
  width: 100%;
}

.stats {
  min-height: 390px;
}
</style>
