<template>
  <div></div>
</template>

<script>
import { adminManager } from "@/services/admin/admin-manager";

export default {
  name: "DuplicateView",
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getDatas();
    },

    getDatas() {
      adminManager.list("training").then((data) => {
        console.log(data);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
