export default {
  global: {
    list: {
      title: "Liste des {name}",
    },
    view: {
      title: "Données du {name}",
    },
    add: {
      title: ({ plural, named }) =>
        plural([`Ajouter un ${named("name")}`, `Ajouter une ${named("name")}`]),
      success: ({ plural, named }) =>
        plural([
          `${named("name")} ajouté avec succès !`,
          `${named("name")} ajoutée avec succès !`,
        ]),
    },
    edit: {
      title: ({ plural, named }) =>
        plural([
          `Modifier la ${named("name")}`,
          `Modifier un ${named("name")}`,
          `Modifier une ${named("name")}`,
        ]),
      success: ({ plural, named }) =>
        plural([
          `${named("name")} modifié avec succès !`,
          `${named("name")} modifiée avec succès !`,
        ]),
    },
    import: {
      errors:
        "Votre fichier contient des erreurs, veuillez les corriger et relancer l'import",
      title: "Importer des {name}",
      error: "Erreur ligne {line} colonne {column} :",
      success: ({ plural, named }) =>
        plural([
          `${named("name")} importés avec succès !`,
          `${named("name")} importées avec succès !`,
        ]),
    },
    export: {
      title: "Exporter les {name}",
      success: ({ plural, named }) =>
        plural([
          `${named("name")} exportés avec succès !`,
          `${named("name")} exportées avec succès !`,
        ]),
    },
    update: {
      title: "Compléter vos {name}",
      success: "{name} complété avec succès !",
    },
    search: {
      title: "Rechercher des {name}",
    },
    sort: {
      success: "Ordre des {name} mis à jour avec succès !",
    },
    download: {
      success: "{name} téléchargé avec succès !",
      title: "Télécharger le {name}",
    },
    move: {
      success: ({ plural, named }) =>
        plural([
          `${named("name")} déplacé avec succès !`,
          `${named("name")} déplacée avec succès !`,
        ]),
    },
    copy: {
      success: ({ plural, named }) =>
        plural([
          `${named("name")} dupliqué avec succès !`,
          `${named("name")} dupliquée avec succès !`,
        ]),
      title: ({ plural, named }) =>
        plural([
          `Dupliquer ce ${named("name")}`,
          `Dupliquer cette ${named("name")}`,
        ]),
      sub_title: ({ plural, named }) =>
        plural([
          `Êtes-vous sûr de vouloir dupliquer ce ${named("name")}`,
          `Êtes-vous sûr de vouloir  dupliquer cette ${named("name")}`,
        ]),
    },
    empty: {
      title: ({ plural, named }) =>
        plural([
          `Il n'y a pas encore de ${named("name1")} dans cette ${named("name2")}, veuillez cliquer sur '+' pour en ajouter un`,
          `Il n'y a pas encore de ${named("name1")} dans cette ${named("name2")}, veuillez cliquer sur '+' pour en ajouter une`,
        ]),
      dashboard: "Il n'y a pas encore de {name}",
    },
    delete: {
      title: ({ plural, named }) =>
        plural([
          `Supprimer un ${named("name")}`,
          `Supprimer une ${named("name")}`,
          `Supprimer ${named("name")}`,
        ]),
      sub_title: ({ plural, named }) =>
        plural([
          `Êtes-vous sûr de vouloir supprimer un ${named("name")}`,
          `Êtes-vous sûr de vouloir supprimer une ${named("name")}`,
          `Êtes-vous sûr de vouloir supprimer ${named("name")}`,
        ]),
      success: ({ plural, named }) =>
        plural([
          `${named("name")} supprimé avec succès !`,
          `${named("name")} supprimée avec succès !`,
          `${named("name")} supprimés avec succès !`,
          `${named("name")} supprimées avec succès !`,
        ]),
    },

    label: {
      name: "Nom",
      date: "Date",
      triads: "Trinômes",
    },
    actions: {
      title: "Actions",
    },
    see_more: "Voir plus...",
    close: "Fermer",
    show_hide_actions: "Afficher / Cacher les actions",
  },
};
