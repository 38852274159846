<template>
  <div
    :class="
      'widget borderStyle drag' +
      (itemModel.selected ? ' selected' : '') +
      (itemModel.displayMode === 2 ? '' : ' title') +
      (this.front ? ' front' : '')
    "
    @click="selectBlock"
  >
    <div class="groupTitle blockStyle">
      {{
        itemModel.title
          ? itemModel.title
          : itemModel.displayMode === 2
            ? $t("studea_manager.questionnaire.untitled_cluster")
            : $t("studea_manager.questionnaire.title_label")
      }}
      <span
        v-if="itemModel.tipActive && itemModel.tip"
        class="form-info ms-2"
        v-tooltip="{ title: itemModel.tip }"
      >
        <FontAwesomeIcon icon="fa-light fa-circle-question" />
      </span>
      <div v-if="!front" class="grip">
        <FontAwesomeIcon icon="fa-light fa-grip-lines-vertical" />
      </div>
    </div>
    <div v-if="itemModel.selected && !front" class="widget-header">
      <div class="cluster__grid">
        <div class="cluster__title">
          <InputTypeComponent :model="title" @update="updateTitle" />
        </div>
        <div v-if="itemModel.displayMode === 2" class="cluster__type">
          <SelectTypeComponent :model="fieldType" @update="selectType" />
        </div>
        <div class="cluster__settings">
          <div class="cluster__tools">
            <button
              v-if="itemModel.displayMode === 2"
              class="btn btn-secondary ms-2 gears"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.cluster_settings'),
              }"
              type="button"
              @click="settings"
            >
              <FontAwesomeIcon icon="fa-light fa-gears" />
            </button>
            <button
              v-if="itemModel.displayMode === 2"
              class="btn btn-success btnStyle ms-2 copy"
              v-tooltip="{
                title: $t('studea_manager.questionnaire.duplicate_cluster'),
              }"
              type="button"
              @click="duplicate"
            >
              <FontAwesomeIcon icon="fa-light fa-copy" />
            </button>
            <button
              v-tooltip="{
                title: $t(
                  itemModel.displayMode === 2
                    ? 'studea_manager.questionnaire.remove_cluster'
                    : 'studea_manager.questionnaire.remove_title',
                ),
              }"
              class="btn btn-danger ms-2 remove"
              type="button"
              @click="remove"
            >
              <FontAwesomeIcon icon="fa-light fa-trash" />
            </button>
            <div class="required">
              {{ $t("studea_manager.questionnaire.tip") }}
              <SwitchTypeComponent :model="tip" @update="setTip" />
            </div>
            <button
              class="btn btn-success btnStyle ms-2 close"
              v-tooltip="{ title: $t('studea_manager.questionnaire.confirm') }"
              type="button"
              @click="close"
            >
              <FontAwesomeIcon icon="fa-light fa-check" />
            </button>
          </div>
        </div>
      </div>
      <div v-if="!front && itemModel.titleError" class="error">
        <FontAwesomeIcon icon="fa-light fa-triangle-exclamation" />
        {{ $t("studea_manager.questionnaire.title_missing") }}
      </div>
      <div v-if="itemModel.tipActive" class="mt-2">
        <InputTypeComponent :model="tipField" @update="updateTip" />
      </div>
    </div>
  </div>
  <div
    v-if="itemModel.displayMode === 2"
    :class="
      'grid bgStyle' +
      (itemModel.selected ? ' selected' : '') +
      (front ? ' front' : '')
    "
    @click="selectBlock"
  >
    <div
      :class="
        'grid__rows' + (front && this.itemModel.openedQuestions ? ' front' : '')
      "
    >
      <QuestionnaireRowHeadComponent
        :front="front"
        :model="itemModel"
        @update="update"
      />
      <button
        v-if="itemModel.displayMode === 2 && itemModel.selected"
        class="btn btn-secondary ms-2 grid__btn"
        v-tooltip="{ title: $t('studea_manager.questionnaire.add_column') }"
        type="button"
        @click="addColumn"
      >
        <FontAwesomeIcon icon="fa-light fa-plus" />
      </button>
      <VueDraggableNext
        :animation="200"
        :delay="200"
        :delay-on-touch-only="true"
        :list="itemModel.questions"
        handle=".gridRow__grip"
      >
        <div
          v-for="(row, index) in itemModel.questions"
          :key="row.index"
          class="grid__row"
        >
          <QuestionnaireRowComponent
            v-if="!front || (front && row.enabled)"
            :front="front"
            :index="index"
            :parent="itemModel"
            :row="row"
            :values="itemModel.valueList"
            @draft="saveDraft"
            @remove="removeChild(index)"
            @settings="rights"
            @skills="skills"
            @update="update"
            @duplicate="duplicateRow"
            @focused="focused"
          />
          <template v-if="front && row">
            <template v-for="answer in row.fieldAnswers" :key="answer.id">
              <QuestionnaireRowReplyComponent
                :answer="answer"
                :cluster="itemModel"
                :row="row"
              />
            </template>
          </template>
        </div>
        <div v-if="front">
          <template
            v-for="answerCollection in itemModel.answerCollections"
            :key="answerCollection.id"
          >
            <template
              v-for="answer in answerCollection.answersValues"
              :key="answer.id"
            >
              <QuestionnaireRowReplyComponent
                :cluster="itemModel"
                :answer="answer"
                :row="row"
              />
            </template>
          </template>
        </div>
      </VueDraggableNext>
      <div class="d-flex justify-content-between align-items-center">
        <button
          v-if="displayAddButton()"
          class="btn btn-secondary mt-2 addRow"
          v-tooltip="{ title: $t('studea_manager.questionnaire.add_row') }"
          type="button"
          @click="addRow"
        >
          <FontAwesomeIcon icon="fa-light fa-plus" />
        </button>
        <QuestionnaireSignBadgeComponent
          v-if="!itemModel.selected"
          :itemModel="itemModel"
          :openedQuestions="itemModel.openedQuestions"
          position="bottomRight"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import SelectTypeComponent from "@/components/form/SelectTypeComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { notificationManager } from "@/services/utilities/notification-manager";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import { mapActions, mapState } from "pinia";
import { VueDraggableNext } from "vue-draggable-next";
import QuestionnaireRowReplyComponent from "@/components/questionnaire/editor/QuestionnaireRowReplyComponent.vue";
import QuestionnaireRowComponent from "@/components/questionnaire/editor/QuestionnaireRowComponent.vue";
import QuestionnaireRowHeadComponent from "@/components/questionnaire/editor/QuestionnaireRowHeadComponent.vue";
import { i18n } from "@/i18n";
import QuestionnaireSignBadgeComponent from "@/components/questionnaire/editor/QuestionnaireSignBadgeComponent.vue";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { useStudeaManagerQuestionnaireStore } from "@/store/studea_manager/studea-manager-questionnaire";
import { useTriadStore } from "@/store/triad/triad";
import { userManager } from "@/services/security/user-manager";

export default {
  name: "QuestionnaireClusterComponent",
  components: {
    QuestionnaireSignBadgeComponent,
    QuestionnaireRowHeadComponent,
    QuestionnaireRowComponent,
    QuestionnaireRowReplyComponent,
    VueDraggableNext,
    SwitchTypeComponent,
    FontAwesomeIcon,
    SelectTypeComponent,
    InputTypeComponent,
  },
  props: {
    item: {
      type: [],
      required: true,
      default: [],
    },
    otherReplies: {
      type: [],
      required: false,
      default: [],
    },
    index: {
      type: Number,
      required: true,
      default: 0,
    },
    front: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      itemModel: this.item,
    };
  },
  computed: {
    title() {
      return {
        vars: {
          value: this.itemModel.title,
          id: "title-" + this.index,
          name: "title-" + this.itemModel.title,
          block_prefixes: ["", "text"],
          required: false,
          columns: 12,
          attr: {
            placeholder:
              this.itemModel.displayMode === 2
                ? this.$t("studea_manager.questionnaire.grid_title")
                : this.$t("studea_manager.questionnaire.title_label"),
          },
        },
      };
    },

    tip() {
      return {
        vars: {
          value: this.itemModel.tipActive,
          id: "tip-" + this.index,
          name: "tip-" + this.itemModel.title,
          block_prefixes: ["", "choice"],
        },
      };
    },

    tipField() {
      return {
        vars: {
          value: this.itemModel.tip,
          id: "tip-" + this.index,
          name: "tip-" + this.itemModel.title,
          block_prefixes: ["", "choice"],
          attr: {
            placeholder: this.$t(
              "studea_manager.questionnaire.infobulle_content",
            ),
          },
        },
      };
    },

    fieldType() {
      return {
        vars: {
          value: this.itemModel.type,
          id: "fieldType-" + this.index,
          name: "fieldType-" + this.itemModel.title,
          block_prefixes: ["", "choice"],
          expanded: false,
          multiple: false,
          required: false,
          columns: 12,
          attr: {
            placeholder: this.$t(
              "studea_manager.questionnaire.field_type_choose",
            ),
          },
          choices: [
            {
              label: this.$t("studea_manager.questionnaire.short_answer"),
              value: "0",
            },
            {
              label: this.$t("studea_manager.questionnaire.textarea"),
              value: "1",
            },
            {
              label: this.$t("studea_manager.questionnaire.number"),
              value: "8",
            },
            {
              label: this.$t("studea_manager.questionnaire.unique_choice"),
              value: "3",
            },
            {
              label: this.$t("studea_manager.questionnaire.multiple_choices"),
              value: "4",
            },
            {
              label: this.$t("studea_manager.questionnaire.file"),
              value: "10",
            },
            {
              label: this.$t("studea_manager.questionnaire.date"),
              value: "11",
            },
            {
              label: this.$t("studea_manager.questionnaire.date_time"),
              value: "12",
            },
          ],
        },
      };
    },

    required() {
      return {
        vars: {
          value: "",
          id: "required-" + this.index,
          name: "required-" + this.itemModel.title,
          block_prefixes: ["", "choice"],
        },
      };
    },

    ...mapState(useQuestionnaireStore, {
      questionnaireModel: (store) => store.model,
      questionnaire: (store) => store.questionnaire,
    }),
    ...mapState(useStudeaManagerQuestionnaireStore, {
      lastIndex: (store) => store.lastIndex,
      questionnaireEditor: (store) => store.questionnaire,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
  },

  methods: {
    selectBlock() {
      if (!this.itemModel.selected) {
        this.$emit("selectBlock", this.itemModel.index);
      }
    },

    displayAddButton() {
      let display = false;
      let reply = this.questionnaire.userReply;
      if (
        this.front &&
        this.triad &&
        this.triad.userRoles &&
        this.triad.userRoles[0] &&
        this.itemModel.openedQuestions
      ) {
        display =
          this.itemModel.openedQuestionsAccess.includes(
            this.triad.userRoles[0],
          ) &&
          (!reply || (reply && !reply.signedAt));
      }

      if (
        !this.front &&
        this.itemModel.openedQuestions &&
        !this.itemModel.selected
      ) {
        display = true;
      }

      return (
        this.itemModel.displayMode === 2 && (this.itemModel.selected || display)
      );
    },

    remove(e) {
      e.stopPropagation();
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.questionnaire.remove_cluster_warning"),
          "",
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.$emit("remove", this.index);
          }
        });
    },

    close(e) {
      e.stopPropagation();
      this.$emit("selectBlock", null);
    },

    duplicate(e) {
      e.stopPropagation();
      this.$emit("duplicate", { id: this.itemModel.index, index: this.index });
    },

    duplicateRow(index) {
      this.initLastIndex(this.lastIndex + 1);
      const item = this.itemModel.questions[index];
      const clone = Object.assign({}, item);
      clone.index = this.lastIndex;
      clone.id = null;

      clone.access = item.access;

      this.itemModel.questions.splice(index + 1, 0, clone);
      this.update();
    },

    focused(id) {
      this.$emit("focused", id);
    },

    addRow(e) {
      e.stopPropagation();

      if (this.front) {
        questionnaireManager
          .addRow(this.triad.id, this.questionnaire.id, this.itemModel.id)
          .then((answer) => {
            this.createClusterRowModel(answer.id);
            notificationManager.showNotification(
              "success",
              this.$t("row_add_success"),
            );
          });
      } else {
        this.createClusterRowModel();
      }
    },

    createClusterRowModel(id = null) {
      let oneProfile = null;
      if (this.questionnaireEditor.signedBy) {
        oneProfile = Object.values(this.questionnaireEditor.signedBy).filter(
          (right) => right.checked,
        );
        oneProfile = oneProfile.length === 1 ? oneProfile[0] : null;
      }

      let access = [
        {
          label: i18n.global.t("Apprentice"),
          value: "1",
          data: 1,
          checked:
            oneProfile && oneProfile.data === userManager.PROFILE_APPRENTICE,
        },
        {
          label: i18n.global.t("Tutor"),
          value: "2",
          data: 2,
          checked: oneProfile && oneProfile.data === userManager.PROFILE_TUTOR,
        },
        {
          label: i18n.global.t("Apprentice master"),
          value: "3",
          data: 3,
          checked:
            oneProfile &&
            oneProfile.data === userManager.PROFILE_APPRENTICE_MASTER,
        },
        {
          label: i18n.global.t("Studea manager"),
          value: "4",
          data: 4,
          checked:
            oneProfile &&
            oneProfile.data === userManager.PROFILE_STUDEA_MANAGER,
        },
      ];

      if (this.front && this.itemModel.openedQuestions) {
        access = this.itemModel.openedQuestionsAccess;
      }
      this.initLastIndex(this.lastIndex + 1);
      const item = {
        id: id,
        title: "",
        type: this.itemModel.type,
        itemType: "field",
        grid: false,
        access: access,
        tip: "",
        tipActive: false,
        required: 0,
        selected: !this.front,
        enabled: questionnaireManager.isEnabled(this.itemModel),
        index: this.lastIndex,
      };

      if (!this.itemModel.questions) {
        this.itemModel["questions"] = [];
      }

      if (this.front) {
        this.questionnaireModel[item.id] = questionnaireManager.buildField(
          item,
          null,
          this.itemModel,
        );
        const values = [];
        for (let i = 0; i < this.itemModel.valueList.length; i++) {
          values[i] = "";
        }
        this.questionnaireModel[item.id]["cluster"] = this.itemModel.id;
        this.questionnaireModel[item.id]["name"] = "";
        this.questionnaireModel[item.id].vars.value = values;
      }

      this.itemModel.questions.push(item);
      this.update();
    },

    addColumn(e) {
      e.stopPropagation();

      this.itemModel.valueList.push({ value: "", mark: "" });
      this.update();
    },

    settings(payload) {
      let data = { item: this.itemModel };
      if (payload && payload.item) {
        data = { item: payload.item, parent: this.itemModel.index };
      }
      this.$emit("settings", data);
    },

    rights(payload) {
      let data = { item: this.itemModel };
      if (payload && payload.item) {
        data = { item: payload.item, parent: this.itemModel };
      }
      this.$emit("rights", data);
    },

    skills(payload) {
      let data = { item: this.itemModel };
      if (payload && payload.item) {
        data = { item: payload.item, parent: this.itemModel.index };
      }
      this.$emit("skills", data);
    },

    removeChild(index) {
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.questionnaire.remove_row_warning"),
          "",
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            const item = this.itemModel.questions[index];
            if (item.id) {
              this.itemModel["deletedQuestions"] = this.itemModel[
                "deletedQuestions"
              ]
                ? this.itemModel["deletedQuestions"]
                : [];
              this.itemModel["deletedQuestions"].push({
                itemType: item.itemType,
                id: item.id,
              });
            }
          }
          this.itemModel.questions.splice(index, 1);
          this.update();
        });
    },

    selectType(value) {
      this.itemModel.type = parseInt(value);

      if (
        this.itemModel.questions &&
        Object.keys(this.itemModel.questions).length
      ) {
        Object.values(this.itemModel.questions).forEach(
          function (child) {
            child.type = this.itemModel.type;
          }.bind(this),
        );
      }
      this.update();
    },

    updateTitle(value) {
      this.itemModel.title = value;
      if (!this.front) {
        questionnaireManager.validQuestion(this.itemModel);
      }
      this.update();
    },

    setTip(value) {
      this.itemModel.tipActive = value;
      if (!value) {
        this.itemModel.tip = "";
      }
      this.update();
    },

    updateTip(value) {
      this.itemModel.tip = value;
      this.update();
    },

    update() {
      this.$emit("update");
    },

    saveDraft(model) {
      this.$emit("draft", model);
    },

    ...mapActions(useStudeaManagerQuestionnaireStore, ["initLastIndex"]),
  },
};
</script>

<style lang="scss" scoped>
.widget {
  padding-top: 0;
  border: 5px solid $grey;
  position: relative;
  border-bottom: none;
  border-radius: 6px 6px 0 0;

  &.front.borderStyle {
    background: var(--primary-color);
  }

  &.selected {
    border-bottom: none;

    &.title {
      border-right: none;

      .grip {
        right: 4px;
      }
    }

    .groupTitle {
      top: 0;
    }

    .grip {
      color: $white;
    }
  }

  &.title {
    border-radius: 6px;

    .groupTitle {
      top: 0;
    }

    .grip {
      right: 2px;
      top: -2px;
    }
  }
}

.drag {
  cursor: grab;
}

.grip {
  position: absolute;
  right: 0;
  width: 20px;
  height: 100%;
  top: 0;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cluster {
  &__grid {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include up-to-xl {
      flex-wrap: wrap;
    }
  }

  &__title {
    flex: 1;
    max-width: 30%;
    padding-right: 15px;
    width: 100%;

    @include up-to-xl {
      max-width: 50%;
    }

    @include up-to-md {
      max-width: 100%;
      flex: initial;
      padding-right: 0;
    }
  }

  &__type {
    flex: 1;
    max-width: 25%;
    width: 100%;
    padding-right: 15px;

    @include up-to-xl {
      max-width: 50%;
      padding-right: 0;
    }

    @include up-to-md {
      max-width: 100%;
      flex: initial;
      margin-top: 10px;
    }
  }

  &__settings {
    flex: 1;
    width: 552px;

    @include up-to-xl {
      width: 100%;
      flex: initial;
      margin-top: 10px;
    }
  }

  &__tools {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include up-to-md {
      flex-wrap: wrap;
    }

    button {
      @include up-to-md {
        order: 0;
      }
    }

    .required {
      @include up-to-md {
        order: 1;
        border: none;
        margin-left: 0;
        margin-top: 10px;
      }
    }

    @include dark-theme {
      color: $white;
    }
  }
}

.required {
  display: flex;
  align-items: center;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid $grey;
}

.groupTitle {
  height: 35px;
  padding: 0 35px 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: 100%;
  position: relative;
  top: -5px;

  .form-info {
    margin-bottom: 0;
  }
}

.grid {
  border: 5px solid $grey;
  border-top: none;
  border-radius: 0 0 6px 6px;
  padding: 20px 20px 15px 20px;
  margin-bottom: 25px;
  margin-top: -25px;

  @include dark-theme {
    border-color: $m-color_10;
  }

  &__rows {
    background: $white;
    border-radius: 6px;
    padding: 15px 50px 15px 15px;
    position: relative;

    &.front {
      padding: 15px 50px 60px 15px;
    }

    @include up-to-md {
      padding: 15px;
    }

    @include dark-theme {
      background: $m-color_10;
    }
  }

  &__row {
    &:last-child {
      border-bottom: 1px solid $grey;

      @include dark-theme {
        border-color: $m-color_10;
      }
    }
  }

  &__btn {
    position: absolute;
    right: 15px;
    top: 25px;

    @include up-to-md {
      top: 15px;
    }
  }
}

.widget.selected + .grid {
  border-color: $blue;

  .widget {
    padding-top: 10px;
  }
}

.grid:not(.selected, .front) {
  cursor: grab;
  position: relative;
  overflow: hidden;

  .grid__rows {
    padding: 15px;
  }

  &:before {
    content: "";
    position: absolute;
    background: var(--primary-color);
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: all 150ms ease-in-out;
  }

  &:hover {
    &:before {
      opacity: 0.15;
    }
  }
}

.error {
  color: $red;
  font-size: 14px;
  font-weight: 700;
}
</style>
