<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="widget">
        <div class="widget-heading -list">
          <h1 class="textStyle">
            <button
              class="btn-icon"
              v-tooltip="{ title: $t('back') }"
              @click="$router.push({ name: 'studeaManagerMessaging' })"
            >
              <FontAwesomeIcon icon="fa-light fa-arrow-left" />
            </button>
            {{
              $tc("global.list.title", 1, {
                name: this.$tc(
                  "studea_manager.moderation.title",
                  2,
                ).toLowerCase(),
              })
            }}
          </h1>
          <button
            :key="key"
            class="btn btnStyle btn-success"
            v-tooltip="tooltip()"
            @click="showCensorship"
          >
            <FontAwesomeIcon
              :icon="
                'fa-light fa-message' + (this.censor ? '' : '-exclamation')
              "
            />
          </button>
        </div>
        <div class="widget-content">
          <SortTableComponent
            :actions="listActions"
            :apiFilters="false"
            :columns="columns"
            :datas="datas"
            :loading="loading"
            :idKey="0"
            dateFormat="short_time"
            @show="show"
            @censor="censorMessage"
          />
        </div>
      </div>
    </div>
  </div>
  <ModalComponent
    :title="modalTitle"
    :content="modalContent"
    :opened="modalOpened"
    @hidden="modalClosed"
  />
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import ModalComponent from "@/components/ModalComponent.vue";
import { fileManager } from "@/services/file/file-manager";
import { pictureManager } from "@/services/utilities/picture-manager";

export default {
  name: "ModerationListView",
  components: {
    ModalComponent,
    SortTableComponent,
    FontAwesomeIcon,
  },
  data() {
    return {
      loading: false,
      listParams: [],
      datas: [],
      censor: 0,
      modalOpened: false,
      modalTitle: "",
      modalContent: "",
      listActions: [],
      columns: [],
      key: 0,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    censorMessage(row) {
      studeaManagerManager
        .censorMessage(this.$route.params.trainingCourseId, row.row[0])
        .then(() => {
          this.init();
        });
    },

    init() {
      this.loading = true;
      this.getDatas(true);
    },

    initActions() {
      this.listActions = [
        {
          translation: "studea_manager.moderation.show",
          icon: "eye",
          type: "primary",
          action: "show",
          cfaStyle: true,
        },
      ];
      if (!this.censor) {
        this.listActions.push({
          translation: "studea_manager.moderation.censor",
          icon: "message-exclamation",
          type: "warning",
          action: "censor",
        });
      }
    },

    tooltip() {
      return {
        title: this.censor
          ? this.$t("studea_manager.moderation.show_message")
          : this.$t("studea_manager.moderation.show_censor"),
      };
    },

    show(row) {
      const data = row.row[4];
      this.modalTitle =
        row.row[2] +
        " - " +
        this.$t("studea_manager.moderation.modal_title") +
        " " +
        row.row[1];
      if (data.text) {
        this.modalContent = data.text;
      }
      if (data.file) {
        const link = fileManager.getDownloadSrc(data.file);
        const fileSrc = pictureManager.setPicture(data.file);
        if (this.isPicture(data.file)) {
          this.modalContent +=
            '<a href="' +
            link +
            '"><img class="show-image" alt="' +
            data.file.originalName +
            '" src="' +
            fileSrc +
            '" /></a>';
        } else {
          this.modalContent +=
            '<a href="' +
            link +
            '">' +
            this.$t("studea_manager.moderation.file") +
            "</a>";
        }
      }
      this.modalOpened = true;
    },

    modalClosed() {
      this.modalOpened = false;
      this.modalContent = "";
      this.modalTitle = "";
    },

    getDatas() {
      studeaManagerManager
        .list(
          "message/moderation",
          this.$route.params.trainingCourseId,
          false,
          {
            censor: this.censor,
          },
        )
        .then((data) => {
          this.columns = sortableTableManager.getEntriesColumns(data.columns);
          this.datas = data.data;
          this.loading = false;
          this.initActions();
          this.key++;
        });
    },

    showCensorship() {
      this.censor = this.censor === 0 ? 1 : 0;
      this.init();
    },

    isPicture(file) {
      return new RegExp("([./])(gif|jpe?g|png|svg+xml)$").test(
        file.originalName,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-heading {
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.-list {
    cursor: initial;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &.collapsed {
    padding-bottom: 0;
  }
}

.widget {
  padding-bottom: 20px;
}

.widget-content {
  padding-bottom: 0;
}
</style>
