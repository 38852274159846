<template>
  <ModalFormComponent
    :models="models"
    :title="$t(modalTitle)"
    @submit="submitResearch"
  />
  <div class="container-fluid">
    <div class="row flex-lg-nowrap">
      <div
        :class="
          'dashboard__container ' + (isFullScreen ? 'full-screen' : 'col-lg-10')
        "
      >
        <BlockLoader :loading="loading" />

        <div class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">
              {{ $t("studea_manager.skillDashboard.list.title") }}
            </h2>
          </div>
          <div class="position-relative">
            <FilterBlock @filter="filter" @reset="resetFilter" />
            <AccordionTableComponent
              v-if="!loading"
              :categories="data.categories"
              :crews="data.crews"
              :replies="data.replies"
              type="skill"
            />
          </div>
        </div>
      </div>
      <div
        :class="
          'col-lg-2 order-lg-1 order-0 position-relative  ' +
          (!isFullScreen ? 'action__full-screen' : '')
        "
      >
        <button
          :class="'toggle_action ' + (isFullScreen ? '' : '-on')"
          @click="toggleAction"
          v-tooltip="{ title: $t('global.show_hide_actions') }"
        >
          <FontAwesomeIcon
            icon="fa-light fa-chevron-left"
            :class="'toggle_icon ' + (isFullScreen ? '' : 'rotate')"
          />
        </button>
        <ActionsBlock
          :actions="actions"
          :mobileCollapsable="true"
          :collapsable="false"
          :title="$t('global.actions.title')"
          collapseId="actions"
          @export-dashboard-to-excel="exportDashboardToExcel"
          @filter-questionnaire="filter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AccordionTableComponent from "@/components/table/accordion/AccordionTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import FilterBlock from "@/components/blocks/FilterBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import skill from "@/model/dashboard/skill";
import { useMainStore } from "@/store/main/main";
import { mapActions, mapState } from "pinia";

export default {
  name: "SkillDashboardView",
  components: {
    BlockLoader,
    ModalFormComponent,
    AccordionTableComponent,
    ActionsBlock,
    FilterBlock,
  },
  data() {
    return {
      actions: [
        {
          translation: "studea_manager.skillDashboard.export_dashboard_excel",
          icon: "file-excel",
          type: "success",
          actionType: "button",
          action: "exportDashboardToExcel",
          cfaStyle: false,
        },
      ],
      models: [],
      modalTitle: "",
      loading: true,
      isFullScreen: false,
      isMobile: window.innerWidth < 992,
      data: skill,
    };
  },
  computed: {
    ...mapState(useMainStore, {
      sideBarOpened: (store) => store.sideBarOpened,
    }),
  },
  mounted() {
    this.loading = false;
    if (!this.isMobile) {
      this.toggleSideBar();
    }
  },
  beforeUnmount() {
    if (!this.isMobile && !this.sideBarOpened) {
      this.toggleSideBar();
    }
  },
  methods: {
    filter() {
      this.models = [];
      this.modalTitle = "";
      studeaManagerManager
        .filterForm("dashboard", this.$route.params.trainingCourseId)
        .then((data) => {
          this.models = data;
          this.modalTitle = "studea_manager.dashboard.filter";
        });
    },

    resetFilter() {
      this.models = [];
      this.modalTitle = "";
    },

    exportDashboardToExcel() {
      studeaManagerManager.exportToExcel("compétences");
    },
    submitResearch(payload) {
      studeaManagerManager.submitDashboardFilter(payload);
      this.models = [];
      this.modalTitle = "";
    },

    toggleAction() {
      this.isFullScreen = !this.isFullScreen;
    },

    ...mapActions(useMainStore, ["toggleSideBar"]),
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__container {
    transition: width ease-in-out 300ms;
    padding-left: 0;
    padding-right: 30px;
  }
}

.full-screen {
  width: 100%;
}

.action__full-screen {
  transition: all ease-in-out 300ms;
}

.toggle_icon {
  transform: rotate(0deg);
  transition: all ease-in-out 300ms;

  &.rotate {
    transform: rotate(180deg);
  }
}

.toggle_action {
  position: absolute;
  display: block;
  z-index: 0;
  top: 0;
  left: -20px;
  padding: 2px 20px 2px 10px;
  border: 1px solid #e0e6ed;
  background-color: $green;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  border-radius: 6px;
  color: $white;
  height: 67px;
  transition: background-color ease-in-out 300ms;

  &.-on {
    color: #212529;
    background-color: $white;
  }

  @include up-to-lg {
    display: none;
  }
}
</style>
