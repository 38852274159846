<template>
  <div class="row">
    <BlockLoader :loading="loading" />
    <div class="col-lg-10 order-1 order-lg-0">
      <div class="widget">
        <div class="widget-heading flex-column">
          <h1 class="textStyle">
            <router-link
              :to="{ name: 'adminUsersList' }"
              class="btn-icon"
              v-tooltip="{ title: $t('back') }"
            >
              <FontAwesomeIcon icon="fa-light fa-arrow-left" />
            </router-link>
            {{ $t("admin.user.merge") }}
          </h1>
          <p class="fst-italic mt-1">
            <FontAwesomeIcon icon="fa-light fa-circle-info" class="me-1" />
            <span v-if="duplicateUsers.length">
              {{ duplicateUsers.length }}
              {{ $t("admin.user.duplicated_user_count") }}
            </span>
            <span v-else>
              {{ $t("admin.none_duplicated_user") }}
            </span>
          </p>
        </div>
        <div class="widget-content position-relative">
          <div v-if="duplicateUsers && duplicateUsers.length" class="list">
            <div class="mb-4 col-12 col-lg-3">
              <span class="d-block mb-2">{{
                $t("admin.user.search_user_by_name_or_mail")
              }}</span>
              <InputTypeComponent :model="keywordSearch" @input="search" />
            </div>
            <div class="list__heading">
              <div>{{ $t("admin.user.row") }}</div>
              <div>{{ $t("admin.user.reference_user") }}</div>
              <div>{{ $t("admin.user.duplicated_user") }}</div>
              <div>{{ $t("admin.user.options") }}</div>
            </div>
            <div class="list__content">
              <div
                class="list__row"
                v-for="(duplicateUser, index) in duplicateUsers"
                :key="duplicateUser.id"
              >
                <TriadDuplicatedUserRowComponent
                  :index="index"
                  :duplicateUser="duplicateUser"
                  @updateModels="updateModels"
                  @userNotDuplicated="userNotDuplicated"
                  @sendBackCredential="sendBackCredential"
                />
              </div>
            </div>
          </div>
          <div v-else>{{ $t("admin.user.no_double_user") }}</div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 order-lg-1 order-0">
      <ActionsBlock :actions="actions" @mergeUsers="mergeUsers" />
    </div>
  </div>
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { accessManager } from "@/services/security/access-manager";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import { apiConnection } from "@/services/api-connection";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import TriadDuplicatedUserRowComponent from "@/components/triad/TriadDuplicatedUserRowComponent.vue";
import { notificationManager } from "@/services/utilities/notification-manager";

export default {
  name: "UserMergeView",
  components: {
    TriadDuplicatedUserRowComponent,
    BlockLoader,
    InputTypeComponent,
    ActionsBlock,
  },
  data() {
    return {
      loading: false,
      isMergeButtonDisabled: true,
      keywordSearch: {
        vars: {
          name: "keyword",
          value: "",
          id: "keyword",
          required: false,
          block_prefixes: ["filter", "text"],
          disabled: false,
          attr: {
            placeholder: this.$t("filter"),
          },
          row_attr: {
            class: "h-100",
          },
        },
      },
      duplicateUsers: [],
      actions: [],
      sendBackLogin: {
        model: {
          vars: {
            label: this.$t("admin.user.send_login_back_to_user"),
            name: "sendBackLogin",
            attr: [],
            disabled: false,
            id: "user_sendBackLogin",
            required: true,
          },
        },
        choices: [
          {
            label: this.$t("admin.user.send_login_back_to_user"),
            value: "1",
            data: true,
            attr: [],
          },
        ],
      },
      notDuplicatedUser: {
        model: {
          vars: {
            label: this.$t("admin.user.send_login_back_to_user"),
            name: "notDuplicatedUser",
            attr: [],
            disabled: false,
            id: "user_notDuplicatedUser",
            required: true,
          },
        },
        choices: [
          {
            label: this.$t("admin.user.reference_user_is_not_duplicate"),
            value: "1",
            data: true,
            attr: [],
          },
        ],
      },
      models: [],
    };
  },
  watch: {
    menu() {
      if (Object.keys(this.menu).length) {
        this.getCurrentMenuEntry();
        this.init();
      }
    },

    isMergeButtonDisabled() {
      this.initAction();
    },
  },
  mounted() {
    this.init();
    this.initAction();
  },
  methods: {
    init() {
      this.loading = true;
      apiConnection.get("/app/admin/user/duplicated/list").then((data) => {
        this.duplicateUsers = data;
        this.loading = false;
      });
    },

    search() {
      console.log("ok");
    },

    updateModels(payload) {
      this.models[payload.index] = payload.item;
      if (
        !this.models[payload.index].userToKeep &&
        !this.models[payload.index].usersToRemove.length &&
        !this.models[payload.index].sendBackCredential.length &&
        !this.models[payload.index].referenceNotDuplicated
      ) {
        this.models.splice(payload.index, 1);
      }

      if (this.models && this.models.length) {
        this.isMergeButtonDisabled =
          this.models.length === 1 && this.models[0] === undefined;
      } else {
        this.isMergeButtonDisabled = false;
      }
    },

    userNotDuplicated(payload) {
      this.models[payload.index] = payload.item;
      if (this.models.length) {
        this.isMergeButtonDisabled = false;
      }
    },

    sendBackCredential(payload) {
      this.models[payload.index] = payload.item;
      if (this.models.length) {
        this.isMergeButtonDisabled = false;
      }
    },

    mergeUsers() {
      notificationManager
        .showAlert(
          "question",
          this.$t("admin.user.merge_duplicated_user_confirmation"),
          "",
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;
            apiConnection
              .post("/app/admin/user/merge", this.models)
              .then((response) => {
                if (response.success) {
                  notificationManager.showNotification(
                    "success",
                    this.$t("admin.user.merge_success"),
                  );
                  this.init();
                }
              });
          }
        });
    },

    initAction() {
      this.actions = [
        {
          translation: "admin.user.merge",
          icon: "merge",
          type: "button",
          action: "mergeUsers",
          cfaStyle: true,
          access: accessManager.NONE,
          disabled: this.isMergeButtonDisabled,
        },
        {
          translation: "admin.user.export",
          icon: "download",
          type: "button",
          action: "export",
          cfaStyle: true,
          access: accessManager.NONE,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  width: 100%;

  &__heading {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 14px;
    color: $primary;
    padding-bottom: 8px;
    border-bottom: 1px solid #adadad;

    @include up-to-md {
      display: none;
    }

    & div {
      width: 100%;

      &:first-child {
        flex-basis: 15%;
      }

      &:last-child {
        flex-basis: 50%;
      }
    }
  }

  &__row {
    display: flex;
    border-bottom: 1px dotted #adadad;
    padding: 8px 0;

    &:last-child {
      border-bottom: none;
    }

    @include up-to-lg {
      flex-direction: column;
    }

    & div {
      width: 100%;

      &:first-child {
        flex-basis: 15%;
      }

      &:last-child {
        flex-basis: 50%;
      }
    }
  }

  &__item {
    padding-right: 8px;

    @include up-to-lg {
      margin-bottom: 30px;
    }

    &:not(:last-child) {
      @include up-to-lg {
        margin-bottom: 20px;
      }
    }
  }
}

.mobile__row__title {
  display: none;
  font-weight: 700;
  font-size: 14px;
  color: $primary;
  padding-bottom: 8px;
  @include up-to-lg {
    display: block;
  }
}
</style>
