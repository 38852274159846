import { $themeConfig } from "@/theme.config";
import { themeStore } from "@/main";

export default {
  init() {
    // set default styles
    let val = localStorage.getItem("studea-dark_mode"); // light, dark, system
    if (!val) {
      val = $themeConfig.theme;
    }
    themeStore.toggleDarkMode(val);

    val = localStorage.getItem("studea-i18n_locale"); // en, da, de, el, es, fr, hu, it, ja, pl, pt, ru, sv, tr, zh
    if (!val) {
      val = $themeConfig.lang;

      const list = themeStore.countryList;
      const item = list.find((item) => item.code === val);
      if (item) {
        this.toggleLanguage(item);
      }
    }
  },

  toggleLanguage(item) {
    let lang = null;
    if (item) {
      lang = item;
    } else {
      let code = themeStore.locale;
      if (!code) {
        code = localStorage.getItem("studea-i18n_locale");
      }

      item = themeStore.countryList.find((d) => d.code === code);
      if (item) {
        lang = item;
      }
    }

    if (!lang) {
      lang = themeStore.countryList.find((d) => d.code === "fr");
    }

    themeStore.toggleLocal(lang.code);
    return lang;
  },

  toggleMode(mode) {
    if (!mode) {
      let val = localStorage.getItem("studea-dark_mode"); //light|dark|system
      mode = val;
      if (!val) {
        mode = "light";
      }
    }
    themeStore.toggleDarkMode(mode || "light");
    return mode;
  },
};
