<template>
  <div class="row">
    <div class="col-lg-9 order-1 order-lg-0">
      <div class="loader-block">
        <div class="widget">
          <div class="widget-heading flex-column flex-md-row">
            <h2 class="textStyle">
              {{
                $tc("global.list.title", 1, {
                  name: this.$tc(
                    "studea_manager.webLink.title",
                    2,
                  ).toLowerCase(),
                })
              }}
            </h2>
          </div>
          <div class="widget-content">
            <div v-if="Object.keys(webLinks).length" class="list">
              <div class="list__webLink row -heading">
                <div class="col-md-11 col-9">
                  {{ $t("global.label.name") }}
                </div>
                <div class="col-md-1 col-3"></div>
              </div>
              <VueDraggableNext
                :animation="200"
                :delay="200"
                :delay-on-touch-only="true"
                :group="{ name: 'webLink' }"
                :list="webLinks"
                :disabled="
                  !accessManager().isAvailableForUser(
                    this.$route,
                    studeaManagerManager().STUDEA_MANAGER_WEBLINKS,
                    accessManager().EDIT,
                  )
                "
                @change="sort($event)"
              >
                <div
                  v-for="webLink in webLinks"
                  :key="webLink.id"
                  class="list__webLink row grip"
                >
                  <div class="col-md-11 col-12 mb-2 mb-md-0">
                    {{ webLink.name }}
                  </div>
                  <div class="col-md-1 col-4 pe-0">
                    <div class="list__action">
                      <router-link
                        :to="{
                          name: 'studeaManagerWebLinkEdit',
                          params: { id: webLink.id, alias: 'web-link' },
                        }"
                        class="list__button"
                        v-tooltip="{
                          title: $tc('global.edit.title', 1, {
                            name: this.$tc(
                              'studea_manager.webLink.title',
                              1,
                            ).toLowerCase(),
                          }),
                        }"
                        v-access:[permissions]="accessManager().EDIT"
                      >
                        <FontAwesomeIcon icon="fa-light fa-edit" />
                      </router-link>
                      <button
                        class="list__button"
                        v-tooltip="{
                          title: $t('global.delete.title', 1, {
                            name: this.$tc('studea_manager.webLink.title', 1),
                          }),
                        }"
                        @click="remove(webLink.id, 'web-link')"
                        v-access:[permissions]="accessManager().DELETE"
                      >
                        <FontAwesomeIcon icon="fa-light fa-trash" />
                      </button>
                    </div>
                  </div>
                </div>
              </VueDraggableNext>
            </div>
            <div v-else>
              {{
                $tc("global.empty.title", 2, {
                  name1: this.$tc(
                    "studea_manager.webLink.title",
                    1,
                  ).toLowerCase(),
                  name2: this.$tc(
                    "studea_manager.trainingCourse.title",
                    1,
                  ).toLowerCase(),
                })
              }}
            </div>
          </div>
        </div>
        <BlockLoader :loading="loading" />
      </div>
    </div>
    <div class="col-lg-3 order-0 order-lg-1">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :mobileCollapsable="true"
            :actions="actions"
            :collapsable="false"
            collapseId="actions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { accessManager } from "@/services/security/access-manager";

export default {
  name: "WebLinkListView",
  components: {
    BlockLoader,
    ActionsBlock,
    VueDraggableNext,
  },
  data() {
    return {
      webLinks: [],
      loading: false,
      params: [],
      actions: [
        {
          translation: "global.add.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.webLink.title", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerWebLinkAdd",
          cfaStyle: false,
          params: { alias: "web-link" },
          access: accessManager.EDIT,
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    studeaManagerManager() {
      return studeaManagerManager;
    },
    accessManager() {
      return accessManager;
    },
    init(loading = true) {
      this.loading = loading;
      this.params = { sortByField: "position", sortOrder: "ASC" };
      studeaManagerManager
        .list(
          "web-link",
          this.$route.params.trainingCourseId,
          true,
          this.params,
        )
        .then((res) => {
          this.webLinks = res;
          this.loading = false;
        });
    },

    sort(event) {
      if (event.moved) {
        const id = event.moved.element.id;
        const position = event.moved.newIndex;
        studeaManagerManager
          .sort("web-link", this.$route.params.trainingCourseId, id, position)
          .then(() => {
            this.init(false);
          });
      }
    },

    remove(id, alias) {
      studeaManagerManager
        .remove(alias, this.$route.params.trainingCourseId, id, "web-link")
        .then(() => {
          this.init();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
}

.grip {
  cursor: grab;
}

.list {
  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__webLink {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-md {
        display: none;
      }
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }
  }

  &__column {
    width: 22%;
    padding-right: 10px;

    &.-sortable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &.-action {
      width: 15%;
    }
  }

  &__action {
    text-align: right;
    min-width: 50px;
  }
}
</style>
