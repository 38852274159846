<template>
  <div class="view -visible" ref="pdf">
    <div v-if="isHeadingVisible">
      <div class="banner">
        <img :alt="logoAlt" :src="logo" />
        <img v-if="logoCfa" :alt="logoCfaAlt" :src="logoCfa" />
      </div>
      <div class="heading">
        <span>{{ trainingCourse.displayName }}</span>
        <span>{{ triad.apprentice.fullName }}</span>
        <span class="chartTitle">{{ $t("skill_chart") }}</span>
      </div>
    </div>

    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import { mapState } from "pinia";
import { pictureManager } from "@/services/utilities/picture-manager";
import { usePlatformStore } from "@/store/platform/platform";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";

export default {
  name: "PdfExportComponent",
  props: {
    avoid: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isHeadingVisible: false,
      logoCfa: "",
      logoCfaAlt: "",
      logo: "",
      logoAlt: "",
    };
  },
  computed: {
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
      name: (store) => store.name,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
  },
  mounted() {
    this.setLogo();
    this.setLogoCfa();
  },
  watch: {
    trainingCourse() {
      this.setLogo();
      this.setLogoCfa();
    },
  },
  methods: {
    save(filename) {
      this.isHeadingVisible = true;
      const element = this.$refs.pdf;
      const opt = {
        margin: [5, 0],
        filename: filename + ".pdf",
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        pagebreak: { mode: "css", avoid: this.avoid },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .save()
        .then(() => {
          this.$emit("pdfSaved");
          this.isHeadingVisible = false;
        });
    },

    setLogo() {
      this.logo = "/assets/images/logo_studea.png";
      if (this.design && this.design.base64Logo) {
        this.logo = this.design.base64Logo;
        this.logoAlt = this.name;
      }
    },

    setLogoCfa() {
      if (
        this.design &&
        this.design.logoCfa &&
        this.design.logoCfa.pathName &&
        this.design.logoCfa.originalName
      ) {
        this.logoCfa = pictureManager.setPicture(this.design.logoCfa.pathName);
        this.logoCfaAlt = this.design.logoCfa.originalName;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.view {
  height: 100%;
  width: 100%;
  max-width: 900px;
  position: absolute;
  left: -10000px;
  top: -10000px;

  &.-visible {
    position: initial;
    left: initial;
    top: initial;
  }
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px 20px 20px;

  img {
    max-height: 80px;
    margin: 0 15px;
  }
}

.content {
  padding: 20px 25px;
  font-size: 11px;
}

.heading {
  background: var(--primary-color);
  padding: 8px 20px;
  color: $white;
  text-align: center;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
}

.chartTitle {
  font-weight: 700;
  font-size: 19px;
}
</style>
