<template>
  <input
    :type="showPassword ? 'text' : 'password'"
    v-model="passwordModel"
    :placeholder="$t('password')"
    class="form-control"
    @input="change"
  />
  <FontAwesomeIcon
    :icon="'fa-light fa-' + (showPassword ? 'eye-slash' : 'eye')"
    class="password-icon"
    @click="handleShowPassword"
  />
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "PasswordTypeComponent",
  data() {
    return {
      showPassword: false,
      passwordModel: "",
    };
  },
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.passwordModel = this.password;
  },
  methods: {
    change() {
      this.$emit("changePassword", this.passwordModel);
      this.$emit("checkInput");
    },

    handleShowPassword() {
      this.showPassword = !this.showPassword;
    },
  },
  components: { FontAwesomeIcon },
};
</script>

<style lang="scss" scoped>
.password-icon {
  cursor: pointer;
  position: absolute;
  top: 25px !important;
  left: unset !important;
  right: 10px;
}
</style>
