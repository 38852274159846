import { documentManager } from "@/services/document/document-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { triadManager } from "@/services/triad/triad-manager";
import { defineStore } from "pinia";
import { useMainStore } from "@/store/main/main";
import { useTriadStore } from "@/store/triad/triad";

export const useDocumentStore = defineStore({
  id: "Document",
  state: () => {
    return {};
  },

  actions: {
    sendDocument(context, object) {
      return documentManager.postNewDocument(object).then((success) => {
        if (!success.error) {
          notificationManager.showNotification(
            "success",
            i18n.global.t(
              object.type === 1 ? "document_success" : "note_success",
            ),
          );
        } else {
          notificationManager.showNotification(
            "success",
            i18n.global.t("message_error"),
          );
        }
        return success;
      });
    },
    removeDocument(context, payload) {
      return documentManager
        .removeDocument(payload.id, useTriadStore().triad.id)
        .then((success) => {
          if (!success.error) {
            notificationManager.showNotification(
              "success",
              i18n.global.t(
                payload.type === 1
                  ? "document_remove_success"
                  : "note_remove_success",
              ),
            );
            const oldTriad = window.localStorage.getItem("studea-oldTriad");
            triadManager
              .loadOne(oldTriad ? oldTriad : useTriadStore().triad.id)
              .then((triad) => {
                useTriadStore().setTriad(triad);
                setTimeout(function () {
                  useMainStore().setAppLoading(false);
                }, 300);
              });
          } else {
            notificationManager.showNotification(
              "danger",
              i18n.global.t("document_remove_error"),
            );
          }
        });
    },
  },
});
