<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">{{ $t("studea_manager.duplication") }}</h2>
          </div>
          <div class="widget-content position-relative">
            <div>
              <ul
                id="languageTab"
                class="nav nav-pills mb-4 mt-3 justify-content-center align-items-center flex-md-row flex-column"
                role="tablist"
              >
                <li
                  class="nav-item ms-md-2 me-md-2 me-0 ms-0 h-100"
                  role="presentation"
                  v-for="tab in tabs"
                  :key="tab.code"
                >
                  <button
                    :id="'tab-' + tab.code"
                    :ref="'ref-' + tab.code"
                    :aria-controls="'tab-' + tab.code"
                    aria-selected="true"
                    :class="
                      'nav-link mb-2 text-center bgStyle h-100' +
                      (currentTabCode && currentTabCode === tab.code
                        ? ' active'
                        : '')
                    "
                    data-bs-toggle="pill"
                    role="tab"
                    @click="toggleTab(tab.code)"
                  >
                    <FontAwesomeIcon :icon="'fa-light fa-' + tab.icon" />
                    {{ $tc(tab.translation, tab.translationParams) }}
                  </button>
                </li>
              </ul>
            </div>
            <div
              class="widget-form position-relative"
              v-if="models && Object.keys(models).length"
            >
              <FormComponent
                :models="models"
                type="trainingCourse_duplication"
                @formCancel="cancel"
                @formSubmit="submit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useMainStore } from "@/store/main/main";
import FormComponent from "@/components/FormComponent.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { formManager } from "@/services/form/form-manager";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";

export default {
  name: "DuplicateView",
  components: { BlockLoader, FormComponent },
  computed: {},
  data() {
    return {
      loading: false,
      currentTabCode: "questionnaire",
      models: [],
      tabs: [
        {
          translation: "studea_manager.questionnaire.title",
          translationParams: 2,
          icon: "file-signature",
          code: "questionnaire",
        },
        {
          translation: "studea_manager.skill.title",
          translationParams: 2,
          icon: "medal",
          code: "skill",
        },
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.fetchForm("questionnaire");
    this.setAppLoading(false);
  },
  methods: {
    cancel() {
      this.$router.push({ name: "TrainingCourseList" });
    },

    toggleTab(code) {
      if (this.currentTabCode !== code) {
        this.models = [];
        this.loading = true;
        this.fetchForm(code);
        this.currentTabCode = code;
      }
    },

    fetchForm(code) {
      studeaManagerManager.getDuplicationForm(code).then((data) => {
        this.models = data;
        this.loading = false;
      });
    },

    submit(payload) {
      this.loading = true;
      const data = formManager.processForm(payload);
      studeaManagerManager
        .postDuplicationForm(this.currentTabCode, data)
        .then(() => {
          this.loading = false;
        });
    },

    ...mapActions(useMainStore, ["setAppLoading"]),
  },
};
</script>

<style lang="scss" scoped>
.form-widget {
  min-height: 300px;
}

.widget .nav-link {
  min-height: 100px;
  width: 130px !important;
  @include up-to-md {
    min-height: fit-content;
    width: 100% !important;
  }
}

.nav {
  &-item {
    @include up-to-md {
      width: 100% !important;
    }
  }
}
</style>
