<template>
  <div class="list">
    <div class="list__skill row -heading">
      <div class="col-md-8 col-6">{{ $t("global.label.name") }}</div>
      <div class="col-3">{{ $t("studea_manager.skill.level") }}</div>
      <div class="col-md-1 col-3"></div>
    </div>
    <VueDraggableNext
      :animation="200"
      :delay="200"
      :delay-on-touch-only="true"
      :group="{ name: 'skills' }"
      :list="skills"
      :disabled="
        !accessManager().isAvailableForUser(
          this.$route,
          studeaManagerManager().STUDEA_MANAGER_SKILLS,
          accessManager().EDIT,
        )
      "
      @change="sortSkill($event, category.id)"
    >
      <div v-for="skill in skills" :key="skill" class="list__skill row">
        <div class="col-md-8 col-12 mb-2 mb-md-0">
          {{ skill.name }}
        </div>
        <div class="col-md-3 col-8">
          <SkillLevelComponent
            :admin="true"
            :maxSkillLevel="trainingCourse.maxSkillLevel"
            :reply="null"
            :skill="skill"
          />
        </div>
        <div class="col-md-1 col-sm-4 col-12 pe-0">
          <div class="list__action">
            <router-link
              :to="{
                name: 'studeaManagerSkillEdit',
                params: { id: skill.id, alias: 'skill' },
              }"
              class="list__button -skill"
              v-tooltip="{
                title: $tc('global.edit.title', 2, {
                  name: this.$tc('studea_manager.skill.title', 1).toLowerCase(),
                }),
              }"
              v-access:[permissions]="accessManager().EDIT"
            >
              <FontAwesomeIcon icon="fa-light fa-edit" />
            </router-link>
            <button
              class="list__button -skill"
              v-tooltip="{
                title: $tc('global.copy.title', 2, {
                  name: this.$tc('studea_manager.skill.title', 1).toLowerCase(),
                }),
              }"
              v-access:[permissions]="accessManager().EDIT"
              @click="copy(skill.id)"
            >
              <FontAwesomeIcon icon="fa-light fa-copy" />
            </button>
            <button
              v-tooltip="{
                title: $tc('global.delete.title', 2, {
                  name: this.$tc('studea_manager.skill.title', 1).toLowerCase(),
                }),
              }"
              v-access:[permissions]="accessManager().DELETE"
              class="list__button -skill"
              @click="deleteSkill(skill.id)"
            >
              <FontAwesomeIcon icon="fa-light fa-trash" />
            </button>
          </div>
        </div>
      </div>
    </VueDraggableNext>
  </div>
</template>

<script>
import { mapState } from "pinia";
import SkillLevelComponent from "@/components/skills/SkillLevelComponent.vue";
import { VueDraggableNext } from "vue-draggable-next";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { accessManager } from "@/services/security/access-manager";
import { useUserStore } from "@/store/user/user";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";

export default {
  name: "SkillListComponent",
  components: { VueDraggableNext, SkillLevelComponent },
  props: {
    skills: {
      type: [],
      required: true,
      default: [],
    },
    category: {
      type: [],
      required: true,
      default: [],
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
  },
  methods: {
    studeaManagerManager() {
      return studeaManagerManager;
    },
    accessManager() {
      return accessManager;
    },
    sortSkill(event, categoryId) {
      if (event.moved) {
        const id = event.moved.element.id;
        const position = event.moved.newIndex;
        studeaManagerManager.sort(
          "skill",
          this.$route.params.trainingCourseId,
          id,
          position,
        );
      } else if (event.added) {
        const id = event.added.element.id;
        studeaManagerManager.move(
          "skill",
          this.$route.params.trainingCourseId,
          id,
          categoryId,
          2,
        );
      }
    },

    copy(id) {
      studeaManagerManager
        .copy("skill", this.$route.params.trainingCourseId, id, 2)
        .then(() => {
          this.$emit("init");
        });
    },

    deleteSkill(id) {
      studeaManagerManager
        .remove("skill", this.$route.params.trainingCourseId, id, 2, true)
        .then(() => {
          this.$emit("init");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__category {
    background: var(--primary-color);
    color: $white;
    padding: 6px 20px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: grab;
  }

  &__button {
    background: none;
    border: none;
    color: $white;
    font-size: 16px;
    position: relative;

    &.-skill {
      color: var(--primary-color);
    }
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__skill {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    cursor: grab;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-md {
        display: none;
      }
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }
  }

  &__action {
    text-align: right;
    min-width: 50px;
  }
}
</style>
