class StorageManager {
  url = null;

  initListener() {
    const app = document.getElementById("app");
    app.addEventListener("contextmenu", (event) => {
      const closestLink = event.target.closest("a");
      if (closestLink && closestLink.href) {
        localStorage.setItem("studea-currentRoute", closestLink.href);
        localStorage.setItem("studea-newTab", "1");
        localStorage.setItem(
          "studea-sessionStorage",
          JSON.stringify(sessionStorage),
        );
      }
      window.removeEventListener("click", this.newClick);
      window.addEventListener("click", this.newClick);
    });

    this.setFromLocalStorage();
  }

  setFromLocalStorage() {
    let sStorage = null;
    let newTab = null;
    if (localStorage.getItem("studea-sessionStorage")) {
      sStorage = JSON.parse(localStorage.getItem("studea-sessionStorage"));
      newTab =
        localStorage.getItem("studea-newTab") &&
        localStorage.getItem("studea-newTab") === "1";
    }

    if ((!sessionStorage.length || newTab) && sStorage) {
      if (localStorage.getItem("studea-sessionStorage")) {
        for (var key in sStorage) {
          if (key !== "studea-sessionTS") {
            sessionStorage.setItem(key, sStorage[key]);
          }
        }
        localStorage.removeItem("studea-sessionStorage");
        localStorage.removeItem("studea-newTab");

        if (!window.sessionStorage.getItem("studea-sessionTS")) {
          window.location.href = window.localStorage.getItem(
            "studea-currentRoute",
          );
        }
      }
    }
  }

  newClick() {
    localStorage.removeItem("studea-newTab");
  }

  clear() {
    //Session
    window.sessionStorage.removeItem("studea-token");
    window.sessionStorage.removeItem("studea-refreshToken");
    window.sessionStorage.removeItem("studea-sessionTS");
    window.sessionStorage.removeItem("studea-initial-refreshToken");
    window.sessionStorage.removeItem("studea-initial-token");
    window.sessionStorage.removeItem("studea-switched");

    //Local
    window.localStorage.removeItem("studea-token");
    window.localStorage.removeItem("studea-username");
    window.localStorage.removeItem("studea-refreshToken");
    window.localStorage.removeItem("studea-questionnaireEditor");
    window.localStorage.removeItem("studea-oldTriad");
    window.localStorage.removeItem("studea-oldTrainingCourse");
    window.localStorage.removeItem("studea-initial-refreshToken");
    window.localStorage.removeItem("studea-initial-token");
    window.localStorage.removeItem("studea-stayConnected");
    window.localStorage.removeItem("studea-switched");
  }
}

export const storageManager = new StorageManager();
