<template>
  <div v-if="note">{{ note.note }}</div>
  <div v-else>--</div>
</template>

<script>
import note from "@/model/dashboard/note";

export default {
  name: "AccordionNoteReplyComponent",
  props: {
    triad: {
      type: [],
      required: true,
    },
    crew: {
      type: [],
      required: true,
    },
    questionnaire: {
      type: [],
      required: true,
    },
  },
  data() {
    return {
      note: {},
      data: note,
    };
  },
  mounted() {
    this.note = this.findNote(this.questionnaire.id, this.triad.id);
  },
  methods: {
    findNote(questionnaireId, triadId) {
      return this.data.replies.find(
        (reply) =>
          reply.questionnaireId === questionnaireId &&
          reply.triadId === triadId,
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
