import { apiConnection } from "@/services/api-connection";
import { fileManager } from "@/services/file/file-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { mainStore, triadStore } from "@/main";

const URL_PDF = "/api/gradebook/get-pdf";

class GradebookManager {
  getPdf = (year, period) => {
    mainStore.fileDownloadRequest();
    return apiConnection
      .get(
        URL_PDF,
        {
          triad: triadStore.triad.id,
          year: year,
          period: period,
        },
        true,
      )
      .then((success) => {
        if (success.data) {
          fileManager.saveFile(
            success.fileName,
            success.data,
            "application/pdf",
          );
        } else {
          notificationManager.showAlert("error", i18n.global.t("error_file"));
        }
        mainStore.fileDownloadSuccess();
      });
  };
}

export const gradebookManager = new GradebookManager();
