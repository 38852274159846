import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { defineStore } from "pinia";

export const useStudeaManagerStore = defineStore({
  id: "StudeaManager",
  state: () => {
    return {
      menu: [],
      configMenu: [],
      moduleMenu: [],
      pilotageMenu: [],
    };
  },

  actions: {
    fetchStudeaManagerMenu(payload) {
      if (payload.refresh) {
        this.setMenu([]);
      }
      return studeaManagerManager
        .getMenu(payload.trainingCourseId)
        .then((menu) => {
          if (!menu.hasError) {
            this.setMenu(menu.items);
          }
        });
    },

    setMenu(menu) {
      this.menu = menu;
      this.configMenu = [];
      this.moduleMenu = [];
      this.pilotageMenu = [];
      if (menu && typeof menu === "object") {
        Object.values(menu).forEach((subMenu) => {
          if (subMenu.id === "parametrage") {
            Object.values(subMenu.children.items).forEach((item) => {
              this.configMenu.push(item);
            });
          } else if (subMenu.id === "modules") {
            Object.values(subMenu.children.items).forEach((item) => {
              this.moduleMenu.push(item);
            });
          } else if (subMenu.id === "pilotage") {
            Object.values(subMenu.children.items).forEach((item) => {
              this.pilotageMenu.push(item);
            });
          }
        });
      }
    },

    setModuleVisibility(payload) {
      const module = this.moduleMenu.find((item) => item.id === payload.id);
      module.disabled = payload.visible;
    },

    init() {
      this.menu = [];
      this.configMenu = [];
      this.moduleMenu = [];
      this.pilotageMenu = [];
    },
  },
});
