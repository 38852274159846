import { adminManager } from "@/services/admin/admin-manager";
import { defineStore } from "pinia";
import { useMainStore } from "@/store/main/main";

export const useAdminStore = defineStore({
  id: "Admin",
  state: () => {
    return {
      menu: [],
      modules: [],
    };
  },
  actions: {
    fetchMenu(init) {
      if (init) {
        this.menu = [];
      }
      return adminManager.getMenu().then((menu) => {
        if (menu && typeof menu === "object") {
          this.menu = menu.items;
        }
        useMainStore().setAppLoading(false);
      });
    },

    fetchModules() {
      return adminManager.list("module", false).then((modules) => {
        if (modules && typeof modules === "object") {
          this.modules = modules;
        }
      });
    },

    init() {
      this.menu = [];
      this.modules = [];
    },
  },
});
