<template>
  <div class="widget skill-widget">
    <div class="widget-heading">
      <h2>{{ chart.name }}</h2>
    </div>
    <div class="widget-content">
      <ApexChart
        v-if="chart.series && Object.keys(chart.series).length"
        ref="chart"
        :key="key"
        :options="chart.options"
        :series="chart.series"
        type="radar"
      />
      <div v-else>{{ $t("stats.no_data") }}</div>
      <div v-if="isMobile">
        <div
          v-for="(category, index) in chart.options.xaxis.categories"
          :key="category"
        >
          <span class="fw-bold">{{ index + 1 }}</span> : {{ category }}
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import ApexChart from "vue3-apexcharts";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { mapState } from "pinia";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useMainStore } from "@/store/main/main";

export default {
  name: "SkillChartComponent",
  components: { BlockLoader, ApexChart },
  data() {
    return {
      loading: false,
      key: 0,
    };
  },
  props: {
    chart: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    windowWidth() {
      this.key++;
    },
    isMobile() {
      this.key++;
    },
    chart() {
      this.key++;
    },
  },
  computed: {
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useMainStore, {
      windowWidth: (store) => store.windowWidth,
    }),
  },
};
</script>

<style lang="scss" scoped>
.widget {
  page-break-inside: avoid;
  break-inside: avoid;
}
</style>
