<template>
  <div class="">
    <div class="error404 text-center">
      <div class="container-fluid error-content">
        <div class="">
          <h1 class="error-number">{{ errorCode }}</h1>
          <p class="mini-text">
            {{
              errorCode === 403
                ? $t("error.message.not_authorized_title")
                : "Ooops!"
            }}
          </p>
          <p
            v-if="errorMessage && errorCode !== 403"
            class="error-text mb-4 mt-1"
          >
            {{ errorMessage }}
          </p>
          <p v-else-if="errorCode === 401" class="error-text mb-4 mt-1">
            {{ $t("error.message.unauthenticated") }}
          </p>
          <p v-else-if="errorCode === 403" class="error-text mb-4 mt-1">
            {{ $t("error.message.not_authorized") }}
          </p>
          <p v-else-if="errorCode === 404" class="error-text mb-4 mt-1">
            {{ $t("error.message.not_found") }}
          </p>
          <p v-else-if="errorCode === 503" class="error-text mb-4 mt-1">
            {{ $t("error.message.unavailable") }}
          </p>

          <RouterLink
            v-if="this.$route.path === '/error'"
            class="btn blockStyle btn-success mt-lg-5 mt-0"
            to="/login"
            >{{ $t("error.go_back_to_home") }}
          </RouterLink>
          <button
            v-else-if="errorCode === 403"
            class="btn blockStyle btn-success mt-lg-45 mt-0"
            @click="back"
          >
            <FontAwesomeIcon icon="fa-light fa-arrow-left" />
            <span class="ms-2">{{ $t("error.back") }}</span>
          </button>
          <button
            v-else
            class="btn blockStyle btn-success mt-lg-45 mt-0"
            @click="reload"
          >
            <FontAwesomeIcon icon="fa-light fa-rotate-right" />
            <span class="ms-2">{{ $t("error.reload_page") }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { notificationManager } from "@/services/utilities/notification-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useErrorStore } from "@/store/error/error";

export default {
  name: "ErrorView",
  components: { FontAwesomeIcon },
  data() {
    return {};
  },
  mounted() {
    this.initError();
  },
  computed: {
    ...mapState(useErrorStore, {
      errorStatus: (store) => store.status,
      errorCode: (store) => store.code,
      errorMessage: (store) => store.message,
    }),
  },
  methods: {
    initError() {
      if (!this.errorStatus) {
        this.setErrorCode(404);
      }
      notificationManager.showNotification(
        "error",
        this.$t("error.error_occured"),
      );
    },

    reload() {
      this.$router.go();
    },

    back() {
      this.$router.back();
    },

    ...mapActions(useErrorStore, ["setErrorCode"]),
  },
};
</script>
<style lang="scss" scoped>
.error404 {
  margin-top: -15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23e6e6e6'/%3E%3Cstop offset='1' stop-color='%23eaf1ff'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%234361ee' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%234361ee' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.5'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-color: #fff;
  background-position: center;
  @include mobile {
    padding: 0 15px;
  }

  > .error-content {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .mini-text {
    font-size: 33px;
    font-weight: 700;
    margin-bottom: 0;
    color: $primary;
    @include mobile {
      font-size: 20px;
    }
  }

  .error-number {
    font-size: 170px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 15px;
    text-shadow: 0 5px 4px rgba(31, 45, 61, 0.1019607843);

    @include mobile {
      font-size: 100px;
    }
  }

  .error-text {
    font-size: 18px;
    color: $dark;
    font-weight: 600;

    @include mobile {
      font-size: 12px;
    }
  }
}
</style>
