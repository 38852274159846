<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-7 col-xl-9">
        <TrainingCourseListBlockComponent :adminButton="true" />
      </div>
      <div class="col-lg-5 col-xl-3 order-1 order-lg-0">
        <div
          class="widget"
          v-access:[permissions]="{
            access: accessManager().DELETE,
            code: studeaManagerManager().STUDEA_MANAGER_DOCUMENTS_UPLOAD,
          }"
        >
          <div class="widget-heading border-none flex-column">
            <router-link
              v-tooltip="{
                title: $t('studea_manager.document.shared_document_list'),
              }"
              :to="{ name: 'studeaManagerSharedDocumentList' }"
              class="btn btn-success btnStyle w-100"
              >{{ $t("studea_manager.document.shared_document_list") }}
            </router-link>
            <router-link
              :to="{ name: 'trainingCourseDuplicateView' }"
              class="btn btn-success btnStyle w-100 mt-2"
              >{{ $t("studea_manager.duplication") }}</router-link
            >
          </div>
        </div>
        <QuestionnaireToDoBlockComponent v-if="!isStudeaManager" />
        <IndicatorBlock />
      </div>
    </div>
  </div>
</template>

<script>
import TrainingCourseListBlockComponent from "@/components/blocks/TrainingCourseListBlockComponent.vue";
import IndicatorBlock from "@/components/blocks/IndicatorBlock.vue";
import { mapActions, mapState } from "pinia";
import QuestionnaireToDoBlockComponent from "@/components/questionnaire/QuestionnaireToDoBlockComponent.vue";
import { colorManager } from "@/services/platform/color-manager";
import { accessManager } from "@/services/security/access-manager";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { useUserStore } from "@/store/user/user";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useMainStore } from "@/store/main/main";

export default {
  name: "TrainingCourseListView",
  components: {
    QuestionnaireToDoBlockComponent,
    IndicatorBlock,
    TrainingCourseListBlockComponent,
  },
  data() {
    return {
      isStudeaManager: false,
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
      user: (store) => store.user,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourses: (store) => store.trainingCourseList,
    }),
  },
  watch: {
    trainingCourses() {
      this.setIsStudeaManager();
    },
  },
  created() {
    this.getTrainingCourses();
  },
  mounted() {
    colorManager.setPlatformStyle();
  },
  methods: {
    studeaManagerManager() {
      return studeaManagerManager;
    },
    accessManager() {
      return accessManager;
    },
    getTrainingCourses() {
      this.fetchTrainingCourseList({}).then(() => {
        this.setAppLoading(false);
      });
    },

    setIsStudeaManager() {
      if (this.trainingCourses && Object.values(this.trainingCourses).length) {
        this.isStudeaManager =
          this.trainingCourses.filter((item) => item.isStudeaManager).length >
          1;
      }
    },

    ...mapActions(useTrainingCourseStore, ["fetchTrainingCourseList"]),
    ...mapActions(useMainStore, ["setAppLoading"]),
  },
};
</script>

<style lang="scss" scoped></style>
