<template>
  <div
    v-if="triad && triad.trainingCourse && triad.trainingCourse.displayDocument"
  >
    <div
      class="widget"
      v-if="
        generalDocuments &&
        Object.values(generalDocuments).filter((item) => item.visibleHomeLea)
          .length
      "
    >
      <div class="widget-heading">
        <h2 class="textStyle">
          {{
            triad &&
            triad.trainingCourse &&
            triad.trainingCourse.blocNameDocument
              ? triad.trainingCourse.blocNameDocument
              : $t("generalDocuments")
          }}
        </h2>
      </div>
      <div class="widget-content">
        <SimpleListComponent
          :actions="actions"
          :datas="
            Object.values(generalDocuments).filter(
              (item) => item.visibleHomeLea,
            )
          "
          icon="file"
        />
      </div>
    </div>
    <div
      class="widget"
      v-if="
        pedagogicDocuments &&
        Object.values(pedagogicDocuments).filter((item) => item.visibleHomeLea)
          .length
      "
    >
      <div class="widget-heading">
        <h2 class="textStyle">{{ $t("pedagogicDocuments") }}</h2>
      </div>
      <div class="widget-content">
        <SimpleListComponent
          :actions="actions"
          :datas="
            Object.values(pedagogicDocuments).filter(
              (item) => item.visibleHomeLea,
            )
          "
          icon="file"
        />
      </div>
    </div>
    <div
      class="widget"
      v-if="documentCategories && Object.keys(documentCategories).length"
    >
      <div class="widget-heading">
        <h2 class="textStyle">{{ $t("otherDocuments") }}</h2>
      </div>
      <div class="widget-content">
        <router-link
          :to="{
            name: 'MODULE_STUDEA_DOCUMENT',
            query: { category: category.code },
          }"
          v-for="category in documentCategories"
          :key="category.id"
          class="d-flex justify-content-between align-items-center"
        >
          {{ category.title }}
          <span class="icon">
            <FontAwesomeIcon icon="fa-light fa-arrow-right" />
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import SimpleListComponent from "@/components/table/SimpleListComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useTriadStore } from "@/store/triad/triad";

export default {
  name: "DocumentsBlock",
  components: { FontAwesomeIcon, SimpleListComponent },
  data() {
    return {
      actions: [
        {
          translation: "download_file",
          icon: "download",
          type: "primary",
          actionType: "externalLink",
          downloadKey: "documentFile",
          cfaStyle: true,
        },
      ],
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      generalDocuments: (store) => store.generalDocuments,
      pedagogicDocuments: (store) => store.pedagogicDocuments,
      documentCategories: (store) => store.documentCategories,
      triad: (store) => store.triad,
    }),
  },
};
</script>

<style lang="scss" scoped>
.icon {
  svg {
    width: 18px;
    height: 18px;
  }
}
</style>
