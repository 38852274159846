import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { messageManager } from "@/services/message/message-manager";
import { defineStore } from "pinia";

export const useMessageStore = defineStore({
  id: "Message",
  state: () => {
    return {
      messages: {},
    };
  },

  actions: {
    sendMessage(message) {
      return messageManager.postNewMessage(message).then((success) => {
        if (!success.error) {
          notificationManager.showNotification(
            "success",
            i18n.global.t("chat.message_success"),
          );
        } else {
          notificationManager.showNotification(
            "error",
            i18n.global.t("chat.message_error"),
          );
        }
        return success;
      });
    },
    getMessages(archived = 0) {
      return messageManager.getMessages(archived).then((messages) => {
        this.messages = messages;
      });
    },
    setMessageOpen(id) {
      return messageManager.openMessage(id);
    },
    reportMessage(message) {
      return messageManager.reportMessage(message).then(() => {
        // dispatch("refreshMessages", false);
      });
    },
  },
});
