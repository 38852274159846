<template>
  <div ref="widgetTop" class="widget">
    <div class="widget-content">
      <form-wizard
        id="tabWizard"
        :key="componentKey"
        ref="wizard"
        :backButtonText="$t('back')"
        :color="design.principalColor"
        :finishButtonText="$t('admin.user.send')"
        :nextButtonText="$t('next')"
        class="circle"
        errorColor="#da5164"
        subtitle=""
        title=""
        @onChange="switchTab"
        @onComplete="submit"
      >
        <div v-for="tab in tabs" :key="tab">
          <tab-content
            v-if="!tab.hidden"
            :beforeChange="validTab"
            :icon="'fa-light fa-' + tab.icon"
            :lazy="tab.lazy === true"
            :title="tab.title"
          >
            <h2 class="textStyle mb-4 d-md-none d-block">{{ tab.title }}</h2>
            <FormComponent
              v-if="tab.models"
              :hideSubmit="true"
              :models="tab.models"
              @toggle="toggle"
              @update="update"
              @uploaded="uploaded"
            />
          </tab-content>
        </div>
      </form-wizard>
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue3-form-wizard";
import FormComponent from "@/components/FormComponent.vue";
import { mapState } from "pinia";
import { formValidator } from "@/services/form/form-validator";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { formManager } from "@/services/form/form-manager";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "FormWizardBlockComponent",
  props: {
    tabs: {
      required: true,
      type: [],
      default: [],
    },
    loading: {
      raquired: true,
      type: Boolean,
      default: false,
    },
    invalidTab: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
    }),
  },
  data() {
    return {
      currentStep: 0,
      wizardTabs: this.tabs,
      componentKey: 0,
    };
  },
  watch: {
    invalidTab() {
      if (typeof this.invalidTab === "string") {
        this.currentStep = parseInt(this.invalidTab);
        this.$refs.wizard.changeTab(0, this.currentStep);
      }
    },
  },
  methods: {
    validTab() {
      let errors = [];
      if (this.invalidTab && parseInt(this.invalidTab) === this.currentStep) {
        const isError = Object.values(this.tabs[this.currentStep].models).find(
          (item) => item.vars.error,
        );
        errors = isError && isError.vars.id ? [isError.vars.id] : [];

        if (!errors.length) {
          this.$emit("validTab");
        }
      } else {
        errors = this.tabs[this.currentStep].models
          ? formValidator.validForm(this.tabs[this.currentStep].models)
          : [];
      }
      return errors.length === 0;
    },

    uploaded(data) {
      this.$emit("uploaded", data);
    },

    switchTab(prev, next) {
      this.currentStep = next;
      this.scrollToTableTop();
    },

    scrollToTableTop(scroll = true) {
      if (scroll) {
        const $this = this;
        setTimeout(function () {
          window.scrollTo({
            behavior: "smooth",
            top:
              $this.$refs.widgetTop.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              130,
          });
        }, 50);
      }
    },

    toggle(model) {
      this.$emit("toggle", model);
      if (Object.keys(this.tabs).length) {
        Object.values(this.tabs).forEach((tab) => {
          if (
            tab.toggleClass &&
            tab.toggleTab &&
            this.currentStep === parseInt(tab.toggleTab)
          ) {
            this.checkTabConditionalDisplay(tab);
          }
        });
      }
    },

    update() {
      this.$emit("update");
    },

    checkTabConditionalDisplay(tab) {
      const splitConditionalTab = tab.toggleClass.split("-");
      const targetTab = tab.toggleTab;
      const targetValue = splitConditionalTab[1];
      const targetName = splitConditionalTab[0];
      const input = Object.keys(this.tabs[targetTab].models).length
        ? Object.values(this.tabs[targetTab].models).find(
            (item) => item.vars.toggler_target === targetName,
          )
        : null;
      if (input) {
        let content = input.vars.value.toString();
        let hidden;
        if (formManager.isChoiceType(input) && formManager.isMultiple(input)) {
          hidden = !Object.values(content).find(
            (item) =>
              (!input.vars.expanded || (input.vars.expanded && item.checked)) &&
              item.value === targetValue,
          );
        } else if (
          formManager.isChoiceType(input) &&
          !formManager.isMultiple(input) &&
          !input.vars.expanded
        ) {
          hidden = content.value !== targetValue;
        } else {
          hidden = content !== targetValue;
        }
        tab.hidden = hidden;
        this.componentKey++;
        setTimeout(() => {
          this.$refs.wizard.changeTab(0, this.currentStep);
        }, 1);
      }
    },
    submit() {
      let errors = this.tabs[this.currentStep].models
        ? formValidator.validForm(this.tabs[this.currentStep].models)
        : [];
      if (this.invalidTab) {
        this.currentStep = parseInt(this.invalidTab);
        this.$refs.wizard.changeTab(0, this.currentStep);
      }
      if (!errors.length && !this.invalidTab) {
        this.$emit("submit");
      }
    },
  },
  components: {
    BlockLoader,
    FormComponent,
    TabContent,
    FormWizard,
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  padding: 20px;
}
</style>
