<template>
  <div class="widget">
    <div class="widget-heading">
      <h2 class="textStyle">{{ label }}</h2>
    </div>
    <div class="widget-content">
      <div class="elem">
        <div class="textStyle">{{ $t("your_progression") }}</div>
        <div class="progress br-30">
          <span
            :class="'progress__text' + (getUserValue() > 50 ? ' -fifty' : '')"
            >{{ getUserValue() }}%</span
          >
          <div
            :aria-valuenow="getUserValue()"
            :style="'width:' + getUserValue() + '%'"
            aria-valuemax="100"
            aria-valuemin="0"
            class="progress-bar bg-gradient-success progressBarStyle"
            role="progressbar"
          ></div>
        </div>
      </div>
      <div class="elem">
        <div class="textStyle">{{ $t("triad_progression") }}</div>
        <div class="progress br-30">
          <span
            :class="'progress__text' + (getTriadValue() > 50 ? ' -fifty' : '')"
            >{{ getTriadValue() }}%</span
          >
          <div
            :aria-valuenow="getTriadValue()"
            :style="'width:' + getTriadValue() + '%'"
            aria-valuemax="100"
            aria-valuemin="0"
            class="progress-bar bg-gradient-success progressBarStyle"
            role="progressbar"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";

export default {
  name: "ProgressBarBlock",
  props: {
    label: {
      type: String,
      required: true,
      default: "",
    },
    type: {
      type: String,
      required: true,
      default: "purcent",
    },
  },
  computed: {
    ...mapState(useQuestionnaireStore, {
      userProgression: (store) => store.userProgression,
      total: (store) => store.total,
      totalTriad: (store) => store.totalTriad,
      triadProgression: (store) => store.triadProgression,
    }),
  },

  methods: {
    getUserValue() {
      if (this.total && this.userProgression) {
        return ((this.userProgression / this.total) * 100).toFixed(1);
      }
      return 0;
    },

    getTriadValue() {
      if (this.triadProgression && this.totalTriad) {
        return ((this.triadProgression / this.totalTriad) * 100).toFixed(1);
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  position: relative;
  margin-bottom: 0;

  &__text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;

    &.-fifty {
      color: $white;
    }
  }
}

.elem + .elem {
  margin-top: 15px;
}
</style>
