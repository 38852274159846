<template>
  <div class="widget" v-access:[permissions]="accessManager().EDIT">
    <div
      class="widget-heading flex-column flex-md-row border-0 justify-content-center"
    >
      <button
        v-tooltip="{
          title: $tc('global.add.title', 1, {
            name: this.$tc(
              'studea_manager.questionnaire.title',
              1,
            ).toLowerCase(),
          }),
        }"
        v-access:[permissions]="accessManager().EDIT"
        class="btn btnStyle btn-success"
        @click="create()"
      >
        <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
        {{
          $tc("global.add.title", 1, {
            name: this.$tc(
              "studea_manager.questionnaire.title",
              1,
            ).toLowerCase(),
          })
        }}
      </button>
      <button
        class="btn btnStyle btn-success ms-md-2 mt-2 mt-md-0"
        v-tooltip="{
          title: $tc('global.add.title', 2, {
            name: this.$tc('studea_manager.category.title', 1).toLowerCase(),
          }),
        }"
        v-access:[permissions]="accessManager().EDIT"
        @click="addEditCategory()"
      >
        <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
        {{
          $tc("global.add.title", 2, {
            name: this.$tc("studea_manager.category.title", 1).toLowerCase(),
          })
        }}
      </button>
    </div>
  </div>
  <div class="row minHeight">
    <div v-for="year in questionnaires" :key="year.id" class="col-lg-6">
      <div class="widget">
        <div class="widget-heading">
          <h2>Année {{ year.year }}</h2>
        </div>
        <div class="widget-content">
          <div class="list">
            <VueDraggableNext
              :animation="200"
              :delay="200"
              :delay-on-touch-only="true"
              :group="{ name: 'categories' }"
              :list="year.categories"
              :disabled="
                !accessManager().isAvailableForUser(
                  this.$route,
                  studeaManagerManager().STUDEA_MANAGER_QUESTIONNAIRES,
                  accessManager().EDIT,
                )
              "
              @change="sortCategory($event, year.year)"
            >
              <div
                v-for="category in year.categories"
                :key="category.id"
                class="mb-2"
              >
                <div class="list__category">
                  <div class="list__title">{{ category.title }}</div>
                  <div class="list__action">
                    <button
                      v-tooltip="{
                        title: $tc('global.edit.title', 2, {
                          name: this.$tc(
                            'studea_manager.category.title',
                            1,
                          ).toLowerCase(),
                        }),
                      }"
                      v-access:[permissions]="accessManager().EDIT"
                      class="list__button"
                      @click="addEditCategory(category.id)"
                    >
                      <FontAwesomeIcon icon="fa-light fa-edit" />
                    </button>
                    <button
                      v-tooltip="{
                        title: $tc('global.copy.title', 2, {
                          name: this.$tc(
                            'studea_manager.category.title',
                            1,
                          ).toLowerCase(),
                        }),
                      }"
                      v-access:[permissions]="accessManager().EDIT"
                      class="list__button"
                      @click="duplicateCategory(category.id)"
                    >
                      <FontAwesomeIcon icon="fa-light fa-copy" />
                    </button>
                    <button
                      v-tooltip="{
                        title: $tc('global.add.title', 1, {
                          name: this.$tc(
                            'studea_manager.questionnaire.title',
                            1,
                          ).toLowerCase(),
                        }),
                      }"
                      v-access:[permissions]="accessManager().EDIT"
                      class="list__button"
                      @click="
                        create({
                          value: category.id,
                          label: category.title,
                        })
                      "
                    >
                      <FontAwesomeIcon icon="fa-light fa-circle-plus" />
                    </button>
                    <button
                      v-tooltip="{
                        title: $tc('global.delete.title', 2, {
                          name: this.$tc(
                            'studea_manager.category.title',
                            1,
                          ).toLowerCase(),
                        }),
                      }"
                      v-access:[permissions]="accessManager().DELETE"
                      class="list__button"
                      @click="deleteCategory(category.id)"
                    >
                      <FontAwesomeIcon icon="fa-light fa-trash" />
                    </button>
                  </div>
                </div>
                <VueDraggableNext
                  :animation="200"
                  :delay="200"
                  :delay-on-touch-only="true"
                  :group="{ name: 'questionnaires' }"
                  :list="category.originalQuestionnaires"
                  :disabled="
                    !accessManager().isAvailableForUser(
                      this.$route,
                      studeaManagerManager().STUDEA_MANAGER_QUESTIONNAIRES,
                      accessManager().EDIT,
                    )
                  "
                  @change="sortQuestionnaire($event, category.id)"
                >
                  <div
                    v-for="questionnaire in category.originalQuestionnaires"
                    :key="questionnaire.id"
                    class="list__questionnaire"
                  >
                    <div class="pe-2 d-flex align-items-center">
                      <span
                        :class="
                          'dot bg-' +
                          (questionnaire.active ? 'success' : 'danger')
                        "
                      ></span
                      >{{ questionnaire.title }}
                    </div>
                    <div class="list__action -questionnaire">
                      <button
                        v-tooltip="{
                          title: $tc('global.copy.title', 1, {
                            name: this.$tc(
                              'studea_manager.questionnaire.title',
                              1,
                            ).toLowerCase(),
                          }),
                        }"
                        v-access:[permissions]="accessManager().EDIT"
                        class="list__button -questionnaire"
                        @click="duplicateQuestionnaire(questionnaire.id)"
                      >
                        <FontAwesomeIcon icon="fa-light fa-copy" />
                      </button>
                      <router-link
                        :to="{
                          name: 'studeaManagerQuestionnaireEdit',
                          params: { id: questionnaire.id },
                        }"
                        class="list__button -questionnaire"
                        v-tooltip="{
                          title: $tc('global.edit.title', 1, {
                            name: this.$tc(
                              'studea_manager.questionnaire.title',
                              1,
                            ).toLowerCase(),
                          }),
                        }"
                        v-access:[permissions]="accessManager().EDIT"
                      >
                        <FontAwesomeIcon icon="fa-light fa-edit" />
                      </router-link>
                      <button
                        v-tooltip="{
                          title: this.deleteTranslation(questionnaire),
                        }"
                        :class="
                          'list__button -questionnaire' +
                          (questionnaire.isAlreadySigned ? ' text-danger' : '')
                        "
                        v-access:[permissions]="accessManager().DELETE"
                        @click="deleteQuestionnaire(questionnaire.id)"
                      >
                        <FontAwesomeIcon
                          :icon="
                            questionnaire.isAlreadySigned
                              ? 'fa-light fa-solid fa-trash'
                              : 'fa-light fa-trash'
                          "
                        />
                      </button>
                    </div>
                  </div>
                </VueDraggableNext>
              </div>
            </VueDraggableNext>
          </div>
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
  <ModalFormComponent
    :models="models"
    :keepHideValue="true"
    :title="
      modalType === 'questionnaire'
        ? $tc('global.add.title', 1, {
            name: this.$tc(
              'studea_manager.questionnaire.title',
              1,
            ).toLowerCase(),
          })
        : $tc('global.add.title', 1, {
            name: this.$tc('studea_manager.category.title', 1).toLowerCase(),
          })
    "
    @hidden="close"
    @submit="valid"
    @update="update"
  />
</template>

<script>
import { mapActions, mapState } from "pinia";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { VueDraggableNext } from "vue-draggable-next";
import { apiConnection } from "@/services/api-connection";
import { formManager } from "@/services/form/form-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { accessManager } from "@/services/security/access-manager";
import { useUserStore } from "@/store/user/user";
import { useStudeaManagerStore } from "@/store/studea_manager/studea-manager";
import { useTriadStore } from "@/store/triad/triad";
import { useStudeaManagerQuestionnaireStore } from "@/store/studea_manager/studea-manager-questionnaire";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "QuestionnaireListView",
  components: {
    BlockLoader,
    VueDraggableNext,
    FontAwesomeIcon,
    ModalFormComponent,
  },
  data() {
    return {
      modalType: "questionnaire",
      questionnaireModel: this.questionnaire,
      showModal: false,
      models: [],
      list: {},
      categories: [],
      editingCatergoryId: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
    ...mapState(useStudeaManagerStore, {
      menu: (store) => store.menu,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useStudeaManagerQuestionnaireStore, {
      questionnaires: (store) => store.questionnaires,
      questionnaire: (store) => store.questionnaire,
    }),
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
      studeaManagerProfileLabel: (store) => store.studeaManagerProfileLabel,
    }),
  },
  beforeRouteUpdate() {
    this.init();
  },
  created() {
    this.init();
  },
  methods: {
    studeaManagerManager() {
      return studeaManagerManager;
    },
    accessManager() {
      return accessManager;
    },
    init() {
      this.loading = true;
      this.fetchQuestionnaireList(this.$route.params.trainingCourseId).then(
        () => {
          this.initCategories();
          this.loading = false;
        },
      );
    },

    deleteTranslation(questionnaire) {
      let translation =
        this.$tc("global.delete.title", 1, {
          name: this.$tc("studea_manager.questionnaire.title", 1).toLowerCase(),
        }) + ".";
      if (questionnaire.isAlreadySigned) {
        translation = translation.concat("\n");
        translation = translation.concat(
          this.$t("studea_manager.questionnaire.questionnaire_already_signed"),
        );
      }

      return translation;
    },

    deleteQuestionnaire(id) {
      this.delete("questionnaire", id, 1);
    },

    duplicateQuestionnaire(id) {
      this.copy(id, "questionnaire", 1);
    },

    duplicateCategory(id) {
      this.copy(id, "category", 2);
    },

    deleteCategory(id) {
      this.delete("category", id, 2);
    },

    update() {
      if (this.modalType === "questionnaire") {
        questionnaireManager.updateFields(this.models, true);
      }
    },

    delete(alias, id, gender) {
      studeaManagerManager
        .remove(alias, this.$route.params.trainingCourseId, id, gender)
        .then(() => {
          this.init();
        });
    },

    sortCategory(event, year) {
      if (event.moved) {
        const id = event.moved.element.id;
        const position = event.moved.newIndex;
        this.sort("category", id, position);
      } else if (event.added) {
        const id = event.added.element.id;
        this.move(id, year, "category", 2);
      }
    },

    sortQuestionnaire(event, categoryId) {
      if (event.moved) {
        const id = event.moved.element.id;
        const position = event.moved.newIndex;
        this.sort("questionnaire", id, position);
      } else if (event.added) {
        const id = event.added.element.id;
        this.move(id, categoryId, "questionnaire", 1);
      }
    },

    sort(alias, id, position) {
      studeaManagerManager
        .sort(alias, this.$route.params.trainingCourseId, id, position)
        .then(() => {
          this.init();
        });
    },

    copy(id, alias, gender) {
      studeaManagerManager
        .copy(alias, this.$route.params.trainingCourseId, id, gender)
        .then(() => {
          this.init();
        });
    },

    move(id, dest, alias, gender) {
      studeaManagerManager
        .move(alias, this.$route.params.trainingCourseId, id, dest, gender)
        .then(() => {
          this.init();
        });
    },

    valid() {
      this.modalType === "questionnaire"
        ? this.validCreation()
        : this.validCategory();
    },

    validCategory() {
      const response = formManager.processForm(
        this.models,
        false,
        this.editingId,
      );

      const baseUrl =
        "/app/studea-manager/" +
        this.$route.params.trainingCourseId +
        "/questionnaire/category";

      (this.editingCatergoryId
        ? apiConnection.put(baseUrl + "/" + this.editingCatergoryId, response)
        : apiConnection.post(baseUrl, response)
      ).then((data) => {
        formManager.processResponse(
          data,
          { name: "studeaManagerQuestionnaireList" },
          this.$tc(
            "global." + (this.editingCatergoryId ? "edit" : "add") + ".success",
            2,
            {
              name: this.$tc("studea_manager.category.title", 1),
            },
          ),
          this.models,
        );
        this.init();
        this.loading = false;
      });
    },

    validCreation() {
      const startDates = [
        "defaultApprenticeAccessStartDate",
        "defaultTutorAccessStartDate",
        "defaultApprenticeMasterAccessStartDate",
        "defaultStudeaManagerAccessStartDate",
      ];

      const endDates = [
        "defaultApprenticeAccessEndDate",
        "defaultTutorAccessEndDate",
        "defaultApprenticeMasterAccessEndDate",
        "defaultStudeaManagerAccessEndDate",
      ];

      Object.values(this.models).forEach((model) => {
        if (
          model.vars.id === "defaultAllAccessDate" &&
          model.vars.value === true
        ) {
          const defaultStartDate = this.models.find(
            (item) => item.vars.id === "defaultAllAccessStartDate",
          );
          const defaultEndDate = this.models.find(
            (item) => item.vars.id === "defaultAllAccessEndDate",
          );

          startDates.forEach((date) => {
            this.questionnaireModel[date] = defaultStartDate.vars.value;
          });

          endDates.forEach((date) => {
            this.questionnaireModel[date] = defaultEndDate.vars.value;
          });
        }

        if (
          !this.questionnaireModel["defaultAllAccessDate"] ||
          (this.questionnaireModel["defaultAllAccessDate"] &&
            !startDates.includes(model.vars.id) &&
            !endDates.includes(model.vars.id))
        ) {
          this.questionnaireModel[model.vars.id] = model.vars.value;
        }

        if (model.vars.id === "category") {
          this.questionnaireModel["category"] = { id: model.vars.value.value };
        }
        this.questionnaireModel["trainingCourse"] = {
          id: this.$route.params.trainingCourseId,
        };
      });
      this.questionnaireUpdate({
        questionnaire: this.questionnaireModel,
        save: true,
      });
      this.questionnaireSetLastSavedVersion(this.questionnaire);
      this.$router.push({
        name: "studeaManagerQuestionnaireAdd",
        params: { trainingCourseId: this.$route.params.trainingCourseId },
      });
    },

    initCategories() {
      this.categories = [];
      const years = Object.keys(this.questionnaires).length;
      if (years) {
        Object.values(this.questionnaires).forEach((year) => {
          Object.values(year.categories).forEach((category) => {
            this.categories.push({
              label: (years > 1 ? year.year + " - " : "") + category.title,
              value: category.id,
            });
          });
        });
      }
    },

    close() {
      this.models = [];
    },

    addEditCategory(id = null) {
      this.models = [];
      this.editingCatergoryId = id;
      this.modalType = "category";
      apiConnection
        .get(
          "/app/studea-manager/" +
            this.$route.params.trainingCourseId +
            "/questionnaire/category/form" +
            (id ? "/" + id : ""),
        )
        .then((data) => {
          this.models = data;
        });
    },

    create(category = null) {
      this.modalType = "questionnaire";
      this.questionnaireNew();
      this.questionnaireModel = this.questionnaire;
      this.questionnaireModel.category = category;
      this.models = [
        {
          vars: {
            block_prefixes: ["", "text"],
            label: "Titre",
            name: "title",
            id: "title",
            required: true,
            value: this.questionnaireModel.title,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            block_prefixes: ["", "choice"],
            expanded: false,
            multiple: false,
            label: "Catégorie",
            name: "category",
            id: "category",
            required: true,
            value: this.questionnaireModel.category,
            choices: this.categories,
            attr: {
              placeholder: "-- Choisissez une catégorie --",
            },
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            block_prefixes: ["", "choice"],
            expanded: true,
            multiple: true,
            label: "Visible par",
            name: "visibleBy",
            id: "visibleBy",
            required: true,
            value: this.questionnaireModel.visibleBy,
            choices: [
              {
                label: this.apprenticeProfileLabel.title,
                value: "1",
                data: 1,
                checked: false,
              },
              {
                label: this.tutorProfileLabel.title,
                value: "2",
                data: 2,
                checked: false,
              },
              {
                label: this.apprenticeMasterProfileLabel.title,
                value: "3",
                data: 3,
                checked: false,
              },
            ],
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            block_prefixes: ["", "choice"],
            expanded: true,
            multiple: true,
            label: "Signé par",
            name: "signedBy",
            id: "signedBy",
            required: true,
            value: this.questionnaireModel.signedBy,
            choices: [
              {
                label: this.apprenticeProfileLabel.title,
                value: 1,
                data: 1,
                checked: false,
                disabled: true,
              },
              {
                label: this.tutorProfileLabel.title,
                value: 2,
                data: 2,
                checked: false,
                disabled: true,
              },
              {
                label: this.apprenticeMasterProfileLabel.title,
                value: 3,
                data: 3,
                checked: false,
                disabled: true,
              },
              {
                label: this.studeaManagerProfileLabel.title,
                value: 4,
                data: 4,
                checked: false,
              },
            ],
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            block_prefixes: ["", "switch"],
            label: "Utiliser les dates des groupes",
            name: "useCrewDates",
            id: "useCrewDates",
            toggler_target: "useCrewDates",
            value: this.questionnaireModel.useCrewDates,
          },
        },
        {
          vars: {
            block_prefixes: ["", "switch"],
            label: "Date d'accès similaire pour tous les profils",
            name: "defaultAllAccessDate",
            id: "defaultAllAccessDate",
            value: this.questionnaireModel.defaultAllAccessDate,
            toggler_target: "defaultAllAccessDate",
            toggle_class: "useCrewDates-false",
          },
        },
        {
          vars: {
            label: "Dates d'accès pour tous les profils",
            name: "defaultAllAccessStartDate",
            id: "defaultAllAccessStartDate",
            value: this.questionnaireModel.defaultAllAccessStartDate,
            toggle_class: "defaultAllAccessDate-true useCrewDates-false",
            block_prefixes: ["", "date"],
            required: true,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            label: "au",
            name: "defaultAllAccessEndDate",
            id: "defaultAllAccessEndDate",
            value: this.questionnaireModel.defaultAllAccessEndDate,
            toggle_class: "defaultAllAccessDate-true useCrewDates-false",
            block_prefixes: ["", "date"],
            required: true,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            value: this.questionnaireModel.defaultApprenticeAccessStartDate,
            label: this.$tc("studea_manager.questionnaire.start_date", 1, {
              profile: this.apprenticeProfileLabel.title.toLowerCase(),
            }),
            name: "defaultApprenticeAccessStartDate",
            id: "defaultApprenticeAccessStartDate",
            toggle_class: "defaultAllAccessDate-false",
            block_prefixes: ["", "date"],
            required: false,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            value: this.questionnaireModel.defaultApprenticeAccessEndDate,
            label: "au",
            name: "defaultApprenticeAccessEndDate",
            id: "defaultApprenticeAccessEndDate",
            toggle_class: "defaultAllAccessDate-false",
            block_prefixes: ["", "date"],
            required: false,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            value: this.questionnaireModel.defaultTutorAccessStartDate,
            label: this.$tc("studea_manager.questionnaire.start_date", 1, {
              profile: this.tutorProfileLabel.title.toLowerCase(),
            }),
            name: "defaultTutorAccessStartDate",
            id: "defaultTutorAccessStartDate",
            toggle_class: "defaultAllAccessDate-false",
            block_prefixes: ["", "date"],
            required: false,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            value: this.questionnaireModel.defaultTutorAccessEndDate,
            label: "au",
            name: "defaultTutorAccessEndDate",
            id: "defaultTutorAccessEndDate",
            toggle_class: "defaultAllAccessDate-false",
            block_prefixes: ["", "date"],
            required: false,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            value:
              this.questionnaireModel.defaultApprenticeMasterAccessStartDate,
            label: this.$tc("studea_manager.questionnaire.start_date", 1, {
              profile: this.apprenticeMasterProfileLabel.title.toLowerCase(),
            }),
            name: "defaultApprenticeMasterAccessStartDate",
            id: "defaultApprenticeMasterAccessStartDate",
            toggle_class: "defaultAllAccessDate-false",
            block_prefixes: ["", "date"],
            required: false,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            value: this.questionnaireModel.defaultApprenticeMasterAccessEndDate,
            label: "au",
            name: "defaultApprenticeMasterAccessEndDate",
            id: "defaultApprenticeMasterAccessEndDate",
            toggle_class: "defaultAllAccessDate-false",
            block_prefixes: ["", "date"],
            required: false,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            value: this.questionnaireModel.defaultStudeaManagerAccessStartDate,
            label: this.$tc("studea_manager.questionnaire.start_date", 1, {
              profile: this.studeaManagerProfileLabel.title.toLowerCase(),
            }),
            name: "defaultStudeaManagerAccessStartDate",
            id: "defaultStudeaManagerAccessStartDate",
            toggle_class: "defaultAllAccessDate-false",
            block_prefixes: ["", "date"],
            required: false,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            value: this.questionnaireModel.defaultStudeaManagerAccessEndDate,
            label: "au",
            name: "defaultStudeaManagerAccessEndDate",
            id: "defaultStudeaManagerAccessEndDate",
            toggle_class: "defaultAllAccessDate-false",
            block_prefixes: ["", "date"],
            required: false,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            value: this.questionnaireModel.firstReminder,
            label: "Première relance",
            name: "firstReminder",
            id: "firstReminder",
            block_prefixes: ["", "number"],
            required: false,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
        {
          vars: {
            value: this.questionnaireModel.secondReminder,
            label: "Deuxième relance",
            name: "secondReminder",
            id: "secondReminder",
            block_prefixes: ["", "number"],
            required: false,
            row_attr: {
              class: "col-md-6",
            },
          },
        },
      ];
    },

    ...mapActions(useStudeaManagerQuestionnaireStore, [
      "fetchQuestionnaireList",
      "questionnaireUpdate",
      "questionnaireSetLastSavedVersion",
      "questionnaireNew",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__category {
    background: var(--primary-color);
    color: $white;
    padding: 6px 20px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: grab;

    @include up-to-md {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
    }
  }

  &__title {
    @include up-to-md {
      width: 100%;
      text-align: left;
    }
  }

  &__button {
    background: none;
    border: none;
    color: $white;
    font-size: 16px;
    position: relative;

    &.-questionnaire {
      color: var(--primary-color);
    }
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__questionnaire {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    cursor: grab;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    @include up-to-md {
      display: block;
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }

    .red {
      color: $red;
    }
  }

  &__action {
    min-width: 55px;
    text-align: right;

    @include up-to-md {
      margin-top: 10px;
    }

    &.-questionnaire {
      @include up-to-md {
        margin-left: 20px;
      }
    }
  }
}

.minHeight {
  min-height: 300px;
  position: relative;
}
</style>
