import { i18n } from "@/i18n";
import TrainingCourseDuplicateView from "@/views/TrainingCourse/DuplicateView.vue";
import GlobalLayoutView from "@/views/Global/GlobalLayoutView.vue";

export default {
  path: "/studea-manager/duplication",
  name: "trainingCourseDuplicateView",
  component: GlobalLayoutView,
  meta: {
    authRequired: true,
    title: i18n.global.t("studea_manager.duplication"),
    breadcrumb: true,
  },
  children: [
    {
      path: "edit",
      name: "trainingCourseDuplicateView",
      component: TrainingCourseDuplicateView,
      meta: {
        authRequired: true,
        title: i18n.global.t("studea_manager.duplication"),
        breadcrumb: true,
      },
    },
  ],
};
