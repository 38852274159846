<template>
  <div
    :class="{
      backdrop: true,
      '-on': appLoading,
      '-on -file': fileDownloading,
    }"
  >
    <div :class="'loader-app ' + size">
      <div class="purple1 -fade--1"></div>
      <div class="purple2 -fade--2"></div>
      <div class="green -fade--3"></div>
      <div class="red -fade--4"></div>
      <div class="text textStyle" v-if="fileDownloading">
        {{ $t("file_downloading") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main/main";

export default {
  name: "LoaderComponent",
  props: {
    size: {
      type: String,
      required: false,
      default: "small",
    },
  },
  computed: {
    ...mapState(useMainStore, {
      appLoading: (store) => store.appLoading,
      fileDownloading: (store) => store.fileDownloading,
    }),
  },
};
</script>

<style lang="scss" scoped>
.backdrop {
  opacity: 0;
  visibility: hidden;
  @include pseudo-pos;
  @include transition-all;
  z-index: 10000;
  height: 100vh;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    opacity: 0;
  }

  @include dark-theme {
    background: $m-color_10;
  }

  &.-on {
    visibility: visible;
    opacity: 1;

    &:after {
      opacity: 1;
    }

    .loader-app {
      visibility: visible;
      opacity: 1;
    }
  }

  &.-file {
    opacity: 1;

    &:after {
      opacity: 0.5;
    }

    .loader-app {
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -36%);
        width: 250px;
        height: 150px;
        background: $white;
        border-radius: 6px;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }
}

img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -47%);
}
</style>
