<template>
  <div class="widget">
    <div class="session">
      <div class="session__list" v-if="sessions && sessions.length">
        <div
          :class="
            'session__item ' +
            (currentSession && currentSession.id === session.id
              ? '-active'
              : '')
          "
          v-for="session in sessions"
          :key="session.id"
        >
          <button
            :class="
              'session__button ' +
              (!isStudeaManagerRoute ? '-studea-manager' : '')
            "
            @click="changeSession(session.id)"
          >
            <span
              v-tooltip="{
                title:
                  $t('studea_manager.session.from') +
                  ' ' +
                  this.formatDate(session.startDate) +
                  ' ' +
                  $t('studea_manager.session.to') +
                  ' ' +
                  this.formatDate(session.endDate),
              }"
              class="me-2 session__info"
              ><FontAwesomeIcon icon="fa-light fa-circle-info" />
            </span>
            {{ session.name }}
            <FontAwesomeIcon
              v-if="!isStudeaManagerRoute"
              class="ms-2"
              :icon="'fa-light fa-' + (session.open ? 'hourglass' : 'lock')"
            />
          </button>
          <div class="session__action">
            <router-link
              :to="{
                name: 'studeaManagerSkillSessionEdit',
                params: { alias: 'skill-session', id: session.id },
              }"
              v-access:[permissions]="accessManager().EDIT"
              v-if="isStudeaManagerRoute"
              class="ms-3 ms-md-2 btn-icon"
              v-tooltip="{
                title: $tc('global.edit.title', 2, {
                  name: this.$tc(
                    'studea_manager.skillSession.title',
                    1,
                  ).toLowerCase(),
                }),
              }"
            >
              <FontAwesomeIcon icon="fa-light fa-pen" />
            </router-link>
            <button
              v-if="isStudeaManagerRoute"
              class="btn-icon ms-md-0 ms-2"
              v-tooltip="{
                title: $tc('global.delete.title', 2, {
                  name: this.$tc(
                    'studea_manager.skillSession.title',
                    1,
                  ).toLowerCase(),
                }),
              }"
              v-access:[permissions]="accessManager().DELETE"
              @click="deleteSession(session.id)"
            >
              <FontAwesomeIcon icon="fa-light fa-trash" />
            </button>
          </div>
        </div>
      </div>
      <div v-else class="text-center w-100">
        {{ $t("dashboard.no_session") }}
      </div>
      <div v-if="isStudeaManagerRoute">
        <router-link
          class="list__button btn-icon"
          :to="{
            name: 'studeaManagerSkillSessionAdd',
            params: {
              alias: 'skill-session',
              params: { trainingCourseId: $route.params.trainingCourseId },
            },
          }"
          v-tooltip="{
            title: $tc('global.add.title', 2, {
              name: $tc('studea_manager.skillSession.title', 1).toLowerCase(),
            }),
          }"
          v-access:[permissions]="accessManager().EDIT"
        >
          <FontAwesomeIcon icon="fa-light fa-plus" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { accessManager } from "@/services/security/access-manager";
import { mapState } from "pinia";
import { useUserStore } from "@/store/user/user";

export default {
  name: "SessionTabsBlock",
  components: { FontAwesomeIcon },
  data() {
    return {
      isStudeaManagerRoute: false,
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  props: {
    sessions: {
      type: [],
      required: true,
    },
    currentSession: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.isStudeaManagerRoute = this.$route.path
      .split("/")
      .includes("studea-manager");
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    changeSession(id) {
      this.$emit("changeSession", id);
    },

    deleteSession(id) {
      this.$emit("deleteSession", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  padding: 20px;
}

.tab__btn {
  position: relative;
  border: none;
  border-radius: 5px;
  padding: 8px;
  background-color: #f1f2f3;
  color: var(--primary-color);
  font-size: 1rem;
  margin-right: 10px;
  cursor: default;

  @include up-to-md {
    margin-bottom: 10px;
  }

  .pointer,
  .btn-icon {
    color: var(--primary-color);
  }

  &.active {
    background-color: var(--primary-color);
    color: $white;

    .pointer,
    .btn-icon {
      color: $white;
    }
  }
}

.pointer {
  background: none;
  border: none;
  padding: 0;
}

.list__button.btn-icon {
  svg {
    width: 22px;
    height: 22px;
  }
}

.-info {
  @include up-to-md {
    display: none;
  }
}

.session {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include up-to-md {
    flex-direction: column;
  }

  &__list {
    display: flex;
    width: 100%;

    @include up-to-md {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    background-color: #f1f2f3;
    color: var(--primary-color);
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @include up-to-md {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
    }

    &.-active {
      color: white;
      background-color: var(--primary-color);

      .btn-icon {
        color: white;
      }
    }
  }

  &__info {
    @include up-to-md {
      display: none;
    }
  }

  &__button {
    width: 85%;
    text-align: left;
    border: none;
    background-color: inherit;
    color: inherit;

    @include mobile {
      width: 70%;
    }

    &.-studea-manager {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }

  &__action {
    display: flex;
    color: var(--primary-color);
  }
}
</style>
