<template>
  <div class="row">
    <div class="col-lg-9 order-1 order-lg-0">
      <div class="loader-block">
        <div v-for="crew in crews" :key="crew.id" class="widget">
          <div class="widget-heading flex-column">
            <h6 class="textStyle">
              <router-link
                v-tooltip="{ title: $t('back') }"
                :to="{ name: 'studeaManagerTriadList' }"
                class="btn-icon"
              >
                <FontAwesomeIcon icon="fa-light fa-arrow-left" />
              </router-link>
              <span class="ms-2">{{ crew.title }} (Réf : {{ crew.id }})</span>
            </h6>
            <p class="textStyle">
              {{ $t("studea_manager.triad.send_user_infos_message") }}
            </p>
          </div>
          <div class="widget-content">
            <div class="list">
              <div class="list__triad -heading">
                <div
                  class="list__column textStyle -sortable"
                  @click="sort('apprentice.lastName', crew)"
                >
                  <div>
                    {{ $t("studea_manager.triad.list.name") }} :
                    {{ apprenticeProfileLabel.title }}<br />
                    <div>
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="checkAll($event, 'apprentice', crew)"
                      >
                        {{ $t("All") }}
                      </button>
                      /
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="checkAll($event, 'apprentice', crew, false)"
                      >
                        {{ $t("None") }}
                      </button>
                    </div>
                  </div>
                  <FontAwesomeIcon
                    :icon="
                      'fa-light fa-arrow-' +
                      (crew.sortWay && crew.sortWay === 'DESC' ? 'down' : 'up')
                    "
                  />
                </div>
                <div
                  class="list__column textStyle -sortable"
                  @click="sort('tutor.lastName', crew, 'tutor')"
                >
                  <div>
                    {{ $t("studea_manager.triad.list.name") }} :
                    {{ tutorProfileLabel.title }}<br />
                    <div>
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="checkAll($event, 'tutor', crew)"
                      >
                        {{ $t("All") }}
                      </button>
                      /
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="checkAll($event, 'tutor', crew, false)"
                      >
                        {{ $t("None") }}
                      </button>
                    </div>
                  </div>
                  <FontAwesomeIcon
                    :icon="
                      'fa-light fa-arrow-' +
                      (crew.sortWay && crew.sortWay === 'DESC' ? 'down' : 'up')
                    "
                  />
                </div>
                <div
                  class="list__column textStyle -sortable"
                  @click="sort('apprenticeMaster.lastName', crew)"
                >
                  <div>
                    {{ $t("studea_manager.triad.list.name") }} :
                    {{ apprenticeMasterProfileLabel.title }}<br />
                    <div>
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="checkAll($event, 'apprenticeMaster', crew)"
                      >
                        {{ $t("All") }}
                      </button>
                      /
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="
                          checkAll($event, 'apprenticeMaster', crew, false)
                        "
                      >
                        {{ $t("None") }}
                      </button>
                    </div>
                  </div>
                  <FontAwesomeIcon
                    :icon="
                      'fa-light fa-arrow-' +
                      (crew.sortWay && crew.sortWay === 'DESC' ? 'down' : 'up')
                    "
                  />
                </div>
                <div
                  class="list__column textStyle -sortable"
                  @click="sort('gestionnary.lastName', crew)"
                >
                  <div>
                    {{ $t("studea_manager.triad.list.name") }} :
                    {{ companyManagerProfileLabel.title }}<br />
                    <div>
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="checkAll($event, 'gestionnary', crew)"
                      >
                        {{ $t("All") }}
                      </button>
                      /
                      <button
                        class="list__checkAll textStyle"
                        type="button"
                        @click="checkAll($event, 'gestionnary', crew, false)"
                      >
                        {{ $t("None") }}
                      </button>
                    </div>
                  </div>
                  <FontAwesomeIcon
                    :icon="
                      'fa-light fa-arrow-' +
                      (crew.sortWay && crew.sortWay === 'DESC' ? 'down' : 'up')
                    "
                  />
                </div>
              </div>
              <div class="mobile__heading">
                <div
                  v-if="apprenticeProfileLabel && apprenticeProfileLabel.title"
                  class="d-flex justify-content-between mb-2"
                >
                  <span class="-name col-6">{{
                    apprenticeProfileLabel.title
                  }}</span>
                  <div class="col-6 text-end">
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'apprentice', crew)"
                    >
                      {{ $t("All") }}
                    </button>
                    /
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'apprentice', crew, false)"
                    >
                      {{ $t("None") }}
                    </button>
                  </div>
                </div>
                <div
                  v-if="tutorProfileLabel && tutorProfileLabel.title"
                  class="d-flex justify-content-between mb-2"
                >
                  <span class="-name col-6">{{ tutorProfileLabel.title }}</span>
                  <div class="col-6 text-end">
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'tutor', crew)"
                    >
                      {{ $t("All") }}
                    </button>
                    /
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'tutor', crew, false)"
                    >
                      {{ $t("None") }}
                    </button>
                  </div>
                </div>
                <div
                  v-if="
                    apprenticeMasterProfileLabel &&
                    apprenticeMasterProfileLabel.title
                  "
                  class="d-flex justify-content-between mb-2"
                >
                  <span class="-name col-6">{{
                    apprenticeMasterProfileLabel.title
                  }}</span>
                  <div class="col-6 text-end">
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'apprenticeMaster', crew)"
                    >
                      {{ $t("All") }}
                    </button>
                    /
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'apprenticeMaster', crew, false)"
                    >
                      {{ $t("None") }}
                    </button>
                  </div>
                </div>
                <div
                  v-if="
                    companyManagerProfileLabel &&
                    companyManagerProfileLabel.title
                  "
                  class="d-flex justify-content-between"
                >
                  <span class="-name col-6">{{
                    companyManagerProfileLabel.title
                  }}</span>
                  <div class="col-6 text-end">
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'gestionnary', crew)"
                    >
                      {{ $t("All") }}
                    </button>
                    /
                    <button
                      class="list__checkAll textStyle"
                      type="button"
                      @click="checkAll($event, 'gestionnary', crew, false)"
                    >
                      {{ $t("None") }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-for="triad in crew.triads"
                :key="triad.id"
                class="list__triad"
              >
                <div class="list__column">
                  <div class="list__title__column col-12 col-sm-3 d-lg-none">
                    {{ $t("studea_manager.triad.list.name") }} :
                    {{ apprenticeProfileLabel.title }}<br />
                  </div>
                  <div
                    v-if="triad.apprentice"
                    class="d-flex align-items-center col-12 col-sm-9 col-lg-12"
                  >
                    <ListCheckBoxInputComponent
                      v-if="triad.apprentice"
                      :id="triad.apprentice.id"
                      :checkedList="listCheck"
                      :key="key"
                      @checked="check"
                    />
                    <div
                      :class="'w-100 ' + getReportStatusColor(triad.apprentice)"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center w-100"
                      >
                        <span>{{ triad.apprentice.fullName }}</span>
                        <div>
                          <div
                            v-if="!triad.apprentice.alreadyConnectedOnce"
                            class="btn btn-icon no-clickable"
                            v-tooltip="{
                              title: $t('studea_manager.user_never_connected'),
                            }"
                          >
                            <FontAwesomeIcon icon="fa-light fa-user-xmark" />
                          </div>
                          <button
                            v-if="
                              triad.apprentice.welcomeNumber ||
                              triad.apprentice.resetNumber
                            "
                            class="btn btn-icon ms-1"
                            v-tooltip="{
                              title: $t('studea_manager.see_last_mail_content'),
                            }"
                            v-access:[permissions]="{
                              access: accessManager().EDIT,
                              code: studeaManagerManager()
                                .STUDEA_MANAGER_TRIAD_LAST_MAIL,
                            }"
                            @click="showLastMail(triad.apprentice.id)"
                          >
                            <FontAwesomeIcon icon="fa-light fa-envelope" />
                          </button>
                          <button
                            v-if="triad.apprentice.resetNumber"
                            class="btn btn-icon ms-1 no-clickable"
                            v-tooltip="{
                              title: $t(
                                'studea_manager.reset_password_mail_send',
                                {
                                  number: triad.apprentice.resetNumber,
                                },
                              ),
                            }"
                          >
                            <FontAwesomeIcon icon="fa-light fa-key" />
                          </button>
                          <button
                            v-if="triad.apprentice.welcomeNumber"
                            class="btn btn-icon ms-1 no-clickable"
                            v-tooltip="{
                              title: $t('studea_manager.welcome_mail_send', {
                                number: triad.apprentice.welcomeNumber,
                              }),
                            }"
                          >
                            <FontAwesomeIcon icon="fa-light fa-house" />
                          </button>
                        </div>
                      </div>
                      <div>{{ triad.apprentice.email }}</div>
                    </div>
                  </div>
                  <div v-else>--</div>
                </div>
                <div class="list__column">
                  <div class="list__title__column col-12 col-sm-3 d-lg-none">
                    {{ $t("studea_manager.triad.list.name") }} :
                    {{ tutorProfileLabel.title }}<br />
                  </div>
                  <div
                    v-if="triad.tutor"
                    class="d-flex justify-content-between col-12 col-sm-9 col-lg-12"
                  >
                    <div class="d-flex align-items-center w-100">
                      <ListCheckBoxInputComponent
                        v-if="triad.tutor"
                        :id="triad.tutor.id"
                        :checkedList="listCheck"
                        :key="key"
                        @checked="check"
                      />
                      <div
                        :class="'w-100 ' + getReportStatusColor(triad.tutor)"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center w-100"
                        >
                          <span>{{ triad.tutor.fullName }}</span>
                          <div>
                            <div
                              v-if="!triad.tutor.alreadyConnectedOnce"
                              class="btn btn-icon no-clickable"
                              v-tooltip="{
                                title: $t(
                                  'studea_manager.user_never_connected',
                                ),
                              }"
                            >
                              <FontAwesomeIcon icon="fa-light fa-user-xmark" />
                            </div>
                            <button
                              v-if="
                                triad.tutor.welcomeNumber ||
                                triad.tutor.resetNumber
                              "
                              class="btn btn-icon ms-1"
                              v-tooltip="{
                                title: $t(
                                  'studea_manager.see_last_mail_content',
                                ),
                              }"
                              v-access:[permissions]="{
                                access: accessManager().EDIT,
                                code: studeaManagerManager()
                                  .STUDEA_MANAGER_TRIAD_LAST_MAIL,
                              }"
                              @click="showLastMail(triad.tutor.id)"
                            >
                              <FontAwesomeIcon icon="fa-light fa-envelope" />
                            </button>
                            <button
                              v-if="triad.tutor.resetNumber"
                              class="btn btn-icon ms-1 no-clickable"
                              v-tooltip="{
                                title: $t(
                                  'studea_manager.reset_password_mail_send',
                                  {
                                    number: triad.tutor.resetNumber,
                                  },
                                ),
                              }"
                            >
                              <FontAwesomeIcon icon="fa-light fa-key" />
                            </button>
                            <button
                              v-if="triad.tutor.welcomeNumber"
                              class="btn btn-icon ms-1 no-clickable"
                              v-tooltip="{
                                title: $t('studea_manager.welcome_mail_send', {
                                  number: triad.tutor.welcomeNumber,
                                }),
                              }"
                            >
                              <FontAwesomeIcon icon="fa-light fa-house" />
                            </button>
                          </div>
                        </div>
                        <div>{{ triad.tutor.email }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-else>--</div>
                </div>
                <div class="list__column">
                  <div class="list__title__column col-12 col-sm-3 d-lg-none">
                    {{ $t("studea_manager.triad.list.name") }} :
                    {{ apprenticeMasterProfileLabel.title }}<br />
                  </div>
                  <div
                    v-if="triad.apprenticeMaster"
                    class="d-flex justify-content-between col-12 col-sm-9 col-lg-12"
                  >
                    <div class="d-flex align-items-center w-100">
                      <ListCheckBoxInputComponent
                        v-if="triad.apprenticeMaster"
                        :id="triad.apprenticeMaster.id"
                        :checkedList="listCheck"
                        :key="key"
                        @checked="check"
                      />
                      <div
                        :class="
                          'w-100 ' +
                          getReportStatusColor(triad.apprenticeMaster)
                        "
                      >
                        <div
                          class="d-flex justify-content-between align-items-center w-100"
                        >
                          <span>{{ triad.apprenticeMaster.fullName }}</span>
                          <div>
                            <div
                              v-if="
                                !triad.apprenticeMaster.alreadyConnectedOnce
                              "
                              class="btn btn-icon no-clickable"
                              v-tooltip="{
                                title: $t(
                                  'studea_manager.user_never_connected',
                                ),
                              }"
                            >
                              <FontAwesomeIcon icon="fa-light fa-user-xmark" />
                            </div>
                            <button
                              v-if="
                                triad.apprenticeMaster.welcomeNumber ||
                                triad.apprenticeMaster.resetNumber
                              "
                              class="btn btn-icon ms-1"
                              v-tooltip="{
                                title: $t(
                                  'studea_manager.see_last_mail_content',
                                ),
                              }"
                              v-access:[permissions]="{
                                access: accessManager().EDIT,
                                code: studeaManagerManager()
                                  .STUDEA_MANAGER_TRIAD_LAST_MAIL,
                              }"
                              @click="showLastMail(triad.apprenticeMaster.id)"
                            >
                              <FontAwesomeIcon icon="fa-light fa-envelope" />
                            </button>
                            <button
                              v-if="triad.apprenticeMaster.resetNumber"
                              class="btn btn-icon ms-1 no-clickable"
                              v-tooltip="{
                                title: $t(
                                  'studea_manager.reset_password_mail_send',
                                  {
                                    number: triad.apprenticeMaster.resetNumber,
                                  },
                                ),
                              }"
                            >
                              <FontAwesomeIcon icon="fa-light fa-key" />
                            </button>
                            <button
                              v-if="triad.apprenticeMaster.welcomeNumber"
                              class="btn btn-icon ms-1 no-clickable"
                              v-tooltip="{
                                title: $t('studea_manager.welcome_mail_send', {
                                  number: triad.apprenticeMaster.welcomeNumber,
                                }),
                              }"
                            >
                              <FontAwesomeIcon icon="fa-light fa-house" />
                            </button>
                          </div>
                        </div>
                        <div>{{ triad.apprenticeMaster.email }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-else>--</div>
                </div>
                <div class="list__column">
                  <div class="list__title__column col-12 col-sm-3 d-lg-none">
                    {{ $t("studea_manager.triad.list.name") }} :
                    {{ companyManagerProfileLabel.title }}<br />
                  </div>
                  <div class="d-flex align-items-center w-100 col-9 col-lg-12">
                    <ListCheckBoxInputComponent
                      v-if="triad.gestionnary"
                      :id="triad.gestionnary.id"
                      :checkedList="listCheck"
                      :key="key"
                      @checked="check"
                    />
                    <div
                      v-if="triad.gestionnary"
                      :class="
                        'w-100 ' + getReportStatusColor(triad.gestionnary)
                      "
                    >
                      <div
                        class="d-flex justify-content-between align-items-center w-100"
                      >
                        <span>{{ triad.gestionnary.fullName }}</span>
                        <div>
                          <div
                            v-if="!triad.gestionnary.alreadyConnectedOnce"
                            class="btn btn-icon no-clickable"
                            v-tooltip="{
                              title: $t('studea_manager.user_never_connected'),
                            }"
                          >
                            <FontAwesomeIcon icon="fa-light fa-user-xmark" />
                          </div>
                          <button
                            v-if="
                              triad.gestionnary.welcomeNumber ||
                              triad.gestionnary.resetNumber
                            "
                            class="btn btn-icon ms-1"
                            v-tooltip="{
                              title: $t('studea_manager.see_last_mail_content'),
                            }"
                            v-access:[permissions]="{
                              access: accessManager().EDIT,
                              code: studeaManagerManager()
                                .STUDEA_MANAGER_TRIAD_LAST_MAIL,
                            }"
                            @click="showLastMail(triad.gestionnary.id)"
                          >
                            <FontAwesomeIcon icon="fa-light fa-envelope" />
                          </button>
                          <button
                            v-if="triad.gestionnary.resetNumber"
                            class="btn btn-icon ms-1 no-clickable"
                            v-tooltip="{
                              title: $t(
                                'studea_manager.reset_password_mail_send',
                                {
                                  number: triad.gestionnary.resetNumber,
                                },
                              ),
                            }"
                          >
                            <FontAwesomeIcon icon="fa-light fa-key" />
                          </button>
                          <button
                            v-if="triad.gestionnary.welcomeNumber"
                            class="btn btn-icon ms-1 no-clickable"
                            v-tooltip="{
                              title: $t('studea_manager.welcome_mail_send', {
                                number: triad.gestionnary.welcomeNumber,
                              }),
                            }"
                          >
                            <FontAwesomeIcon icon="fa-light fa-house" />
                          </button>
                        </div>
                      </div>
                      <div>{{ triad.gestionnary.email }}</div>
                    </div>
                    <div v-else>--</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlockLoader :loading="loading" />
      </div>
    </div>
    <div class="col-lg-3 order-0 order-lg-1">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="actions"
            :mobileCollapsable="true"
            :collapsable="false"
            :title="$t('global.actions.title')"
            collapseId="actions"
            @resetPassword="resetPassword"
            @sendWelcomeEmail="sendWelcomeEmail"
          />
        </div>
      </div>
    </div>
    <ModalComponent
      :title="mailTitle"
      :content="mailBody"
      :opened="mailOpened"
      @hidden="mailHidden"
    />
  </div>
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { sortManager } from "@/services/utilities/sort-manager";
import ListCheckBoxInputComponent from "@/components/form/ListCheckBoxInputComponent.vue";
import { mapState } from "pinia";
import ModalComponent from "@/components/ModalComponent.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { accessManager } from "@/services/security/access-manager";
import { useUserStore } from "@/store/user/user";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "SendUserInfosView",
  components: {
    ModalComponent,
    ListCheckBoxInputComponent,
    FontAwesomeIcon,
    BlockLoader,
    ActionsBlock,
  },
  data() {
    return {
      key: 0,
      crews: [],
      crewSortWay: {
        apprentice: "ASC",
        tutor: "ASC",
        apprenticeMaster: "ASC",
        CompanyManager: "ASC",
      },
      listCheck: [],
      loading: false,
      sortWay: "ASC",
      mailTitle: "",
      mailBody: "",
      mailOpened: false,
      actions: [
        {
          translation: "studea_manager.triad.send_welcome_email",
          icon: "house",
          type: "success",
          actionType: "button",
          action: "sendWelcomeEmail",
          cfaStyle: false,
          disabled: true,
        },
        {
          translation: "studea_manager.triad.reset_password",
          icon: "key",
          type: "success",
          actionType: "button",
          action: "resetPassword",
          cfaStyle: false,
          disabled: true,
        },
      ],
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
      companyManagerProfileLabel: (store) => store.companyManagerProfileLabel,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    studeaManagerManager() {
      return studeaManagerManager;
    },
    accessManager() {
      return accessManager;
    },
    init() {
      this.loading = true;
      studeaManagerManager
        .list("triad", this.$route.params.trainingCourseId, false, [])
        .then((res) => {
          this.crews = res;
          this.loading = false;
        });
    },

    check(payload) {
      if (!this.listCheck.includes(payload.id)) {
        this.listCheck.push(payload.id);
      }
      if (!payload.checked) {
        const index = this.listCheck.indexOf(payload.id);
        this.listCheck.splice(index, 1);
      }

      this.update();
    },

    checkAll(e, key, crew, all = true) {
      e.stopPropagation();
      Object.values(crew.triads).forEach((triad) => {
        if (triad[key]) {
          this.check({ id: triad[key].id, checked: all });
        }
      });
    },

    update() {
      this.actions[0].disabled = !Object.keys(this.listCheck).length;
      this.actions[1].disabled = !Object.keys(this.listCheck).length;
    },

    sort(field, crew) {
      crew.sortWay = crew.sortWay && crew.sortWay === "DESC" ? "ASC" : "DESC";
      crew.triads = sortManager.sort(crew.triads, field, crew.sortWay);
    },

    sendWelcomeEmail() {
      this.loading = true;
      studeaManagerManager
        .sendWelcome(this.listCheck, this.$route.params.trainingCourseId)
        .then(() => {
          this.init();
          this.listCheck = [];
          this.key++;
        });
    },

    resetPassword() {
      this.loading = true;
      studeaManagerManager
        .sendNewPassword(this.listCheck, this.$route.params.trainingCourseId)
        .then(() => {
          this.init();
          this.listCheck = [];
          this.key++;
        });
    },

    mailHidden() {
      this.mailOpened = false;
      this.mailTitle = "";
      this.mailBody = "";
    },

    formatDate(date) {
      return dateFormatter.format(date, "long");
    },

    showLastMail(id) {
      studeaManagerManager
        .getLastSendReport(id, this.$route.params.trainingCourseId)
        .then((data) => {
          this.mailTitle =
            data.subject && data.date
              ? data.subject + " - " + this.formatDate(data.date)
              : this.$t("admin.user.no_mail_title");
          this.mailBody = data.body ? data.body : this.$t("admin.user.no_mail");
          this.mailOpened = true;
        });
    },

    getReportStatusColor(profile) {
      if (profile && profile.lastSendReport && profile.lastSendReport.status) {
        switch (profile.lastSendReport.status) {
          case 0:
            return "red";
          case 1:
            return "green";
          case 2:
            return "red";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  .green {
    color: $green;
  }

  .red {
    color: $red;
  }

  &__triad {
    padding: 6px 0;
    border-top: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    @include up-to-lg {
      flex-direction: column;
      padding: 0;
    }

    &:first-child {
      border-top: none;
    }

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-lg {
        display: none;
      }
    }

    &:hover {
      background: $lighter-grey;

      @include up-to-lg {
        background-color: inherit;
      }
    }
  }

  &__checkAll {
    background: none;
    border: none;
    padding: 0;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__column {
    width: 25%;
    padding: 0 15px 0 25px;
    position: relative;

    @include up-to-lg {
      width: 100%;
      padding: 10px;
      display: flex;
    }

    @include mobile {
      flex-direction: column;
    }

    &:nth-child(2n + 1) {
      @include up-to-lg {
        background-color: $lighter-grey;
      }
    }

    &.-sortable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }

  &__title__column {
    color: var(--primary-color);
    font-weight: bold;
    padding-right: 15px;
  }

  .badge {
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    font-size: 10px;
    background: $orange;
    display: none;
    margin-right: 5px;

    @include up-to-lg {
      display: flex;
    }

    &.-apprentice {
      background: $green;
    }

    &.-tutor {
      background: $purple;
    }

    &.-apprenticeMaster {
      background: $red;
    }
  }
}

.mobile__heading {
  display: none;
  margin-bottom: 10px;
  color: var(--primary-color);

  @include up-to-lg {
    display: block;
  }

  .-name {
    font-weight: bold;
  }
}

.no-clickable {
  cursor: default;
}

.rotate {
  transform: rotate(180deg);
}

.btn {
  svg {
    height: 1em;
  }
}
</style>
