<template>
  <div class="row flex-lg-nowrap">
    <div class="container-widget order-lg-0 order-1">
      <div class="widget">
        <div class="widget-heading -list">
          <h1 class="textStyle">
            {{
              $tc("global.list.title", 1, {
                name: this.$tc("admin.log.title", 2).toLowerCase(),
              })
            }}
            {{
              isFiltered
                ? "(" +
                  (maxResult ? maxResult : Object.keys(datas).length) +
                  " résultat" +
                  ((maxResult ? maxResult : Object.keys(datas).length) > 1
                    ? "s"
                    : "") +
                  " filtré" +
                  ((maxResult ? maxResult : Object.keys(datas).length)
                    ? "s"
                    : "") +
                  ")"
                : ""
            }}
          </h1>
          <div>
            <button
              v-if="isFiltered"
              class="btn-icon me-1"
              v-tooltip="{ title: $t('init_search') }"
              type="button"
              @click="resetSearch"
            >
              <FontAwesomeIcon icon="fa-light fa-arrows-rotate" />
            </button>
            <button
              class="btn-icon"
              v-tooltip="{ title: $t('search_motor') }"
              type="button"
              @click="openSearch"
            >
              <FontAwesomeIcon icon="fa-light fa-magnifying-glass" />
            </button>
          </div>
        </div>
        <div class="widget-content">
          <SortTableComponent
            v-if="Object.values(datas)"
            :actions="actions"
            :apiFilters="true"
            :columns="columns"
            :datas="datas"
            :idKey="0"
            :loading="loading"
            :maxResult="maxResult"
            :isSearchAvailable="true"
            :defaultLimit="10"
            :searchInput="false"
            type="training"
            ref="sortTable"
            path="adminTrainingCourseEdit"
            @refresh="refresh"
            @openSearch="openSearch"
          />
        </div>
      </div>
    </div>
  </div>
  <ModalFormComponent
    :models="filterModels"
    :title="modalTitle"
    :storageKey="sessionStorageKey"
    @search="search"
  />
</template>

<script>
import { mapState } from "pinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { adminManager } from "@/services/admin/admin-manager";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { formManager } from "@/services/form/form-manager";
import { accessManager } from "@/services/security/access-manager";
import { useUserStore } from "@/store/user/user";

export default {
  name: "LogsListView",
  components: {
    ModalFormComponent,
    SortTableComponent,
    FontAwesomeIcon,
  },
  data() {
    return {
      isFullScreen: true,
      loading: false,
      listParams: [],
      datas: [],
      isSearchOpen: false,
      isFiltered: false,
      sessionStorageKey: "studea-admin-logs",
      maxResult: 0,
      modalTitle: "",
      modalType: "",
      actions: [
        {
          translation: "admin.log.view",
          icon: "info",
          type: "success",
          actionType: "router",
          action: "adminLogView",
          cfaStyle: false,
          alias: "log",
          access: accessManager.EDIT,
        },
      ],
      columns: [],
      filterModels: [],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  watch: {
    windowWidth() {
      this.updateWindowWidth();
    },
  },
  mounted() {
    this.init();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  beforeRouteUpdate() {
    this.init();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    refresh(params) {
      this.listParams = params;
      this.init();
    },

    search(params) {
      if (this.modalType === "search") {
        this.loading = true;
        this.listParams["offset"] = 0;
        this.$refs.sortTable.activePage = 1;
        this.listParams["search"] = params;
        this.getDatas();
        this.isFiltered = true;
      }
    },

    init() {
      this.loading = true;
      this.setParams();
      this.getDatas(true);
    },

    setParams() {
      if (!this.listParams || !Object.keys(this.listParams).length) {
        this.listParams = {
          sortByField: 0,
          sortOrder: "ASC",
          limit: 10,
          offset: 0,
        };
      }
    },

    getDatas() {
      if (window.sessionStorage.getItem(this.sessionStorageKey)) {
        this.listParams["search"] = JSON.parse(
          window.sessionStorage.getItem(this.sessionStorageKey),
        );
        this.isFiltered = true;
      }
      if (this.isFiltered) {
        this.listParams["search"] = formManager.getQuery(
          this.listParams["search"],
        );
      }
      adminManager.list("log", false, this.listParams).then((data) => {
        this.columns = sortableTableManager.getEntriesColumns(
          data.columns,
          this.columns,
        );
        let datas = [];
        Object.values(data.data).forEach((row) => {
          datas.push(Object.values(row));
        });
        this.datas = datas;
        this.maxResult = data.count;
        this.loading = false;
      });
    },

    getFilters() {
      this.filterModels = [];
      this.modalType = "search";
      this.sessionStorageKey = "studea-admin-logs";
      this.modalTitle = this.$tc("global.search.title", 2, {
        name: this.$tc("admin.log.title", 2).toLowerCase(),
      });
      adminManager.form("log", null, {}, false, true).then((data) => {
        this.filterModels = data;
      });
    },

    openSearch() {
      this.getFilters();
      this.isSearchOpen = true;
    },

    resetSearch() {
      this.listParams = [];
      this.$refs.sortTable.activePage = 1;
      this.datas = [];
      this.columns = [];
      this.isFiltered = false;
      window.sessionStorage.removeItem(this.sessionStorageKey);
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-heading {
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.-list {
    cursor: initial;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &.collapsed {
    padding-bottom: 0;
  }
}

.widget {
  padding-bottom: 20px;
}

.widget-content {
  padding-bottom: 0;
}
</style>
