<template>
  <div class="widget">
    <div class="widget-heading">
      <h2>
        {{
          $tc("global.view.title", 1, {
            name: $t("admin.log.title"),
          })
        }}
      </h2>
    </div>
    <div class="widget-content">
      <div v-for="(value, key) in log.data" :key="key">
        <span class="fw-bold">{{ key }}</span> : {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
import { adminManager } from "@/services/admin/admin-manager";

export default {
  name: "LogViewView",
  components: {},
  data() {
    return {
      log: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getDatas();
    },

    getDatas() {
      adminManager.get("log", this.$route.params.id).then((data) => {
        this.log = data;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
