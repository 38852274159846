import DashboardLayoutView from "@/views/Dashboard/DashboardLayoutView.vue";
import DashboardView from "@/views/Dashboard/DashboardView.vue";
import SkillsView from "@/views/Dashboard/Skill/SkillsView.vue";
import TrainingCourseListView from "@/views/TrainingCourse/TrainingCourseListView.vue";
import LessonView from "@/views/Dashboard/LessonView.vue";
import WeblinksView from "@/views/Dashboard/WeblinksView.vue";
import GradebookView from "@/views/Dashboard/GradebookView.vue";
import AttestationsView from "@/views/Dashboard/AttestationsView.vue";
import ChatView from "@/views/Dashboard/Chat/ChatView.vue";
import { i18n } from "@/i18n";
import AbsenceView from "@/views/Dashboard/Absence/AbsenceView.vue";
import AbsenceLayoutView from "@/views/Dashboard/Absence/AbsenceLayoutView.vue";
import AbsenceListView from "@/views/Dashboard/Absence/AbsenceListView.vue";
import QuestionnaireView from "@/views/Questionnaire/QuestionnaireView.vue";
import SkillsStatsView from "@/views/Dashboard/Skill/SkillsStatsView.vue";
import DocumentView from "@/views/Dashboard/Document/DocumentView.vue";
import NotepadView from "@/views/Dashboard/Notepad/NotepadView.vue";
import NewsListView from "@/views/News/NewsListView.vue";
import NewsView from "@/views/News/NewsView.vue";

export default {
  path: "/dashboard",
  name: "DashboardLayout",
  redirect: "home",
  component: DashboardLayoutView,
  meta: {
    authRequired: true,
    breadcrumb: true,
    title: i18n.global.t("page.home"),
  },
  children: [
    {
      path: "home",
      name: "Dashboard",
      component: DashboardView,
      meta: {
        title: i18n.global.t("page.dashboard"),
        breadcrumb: false,
      },
    },
    {
      path: "skills",
      name: "MODULE_STUDEA_SKILL",
      component: SkillsView,
      meta: {
        title: i18n.global.t("page.skills"),
        code: "MODULE_STUDEA_SKILL",
        breadcrumb: true,
        help: true,
      },
    },
    {
      path: "skills/stats",
      name: "skillsStats",
      component: SkillsStatsView,
      meta: {
        title: i18n.global.t("page.skills_stats"),
        breadcrumb: true,
      },
    },
    {
      path: "weblinks",
      name: "MODULE_STUDEA_WEBLINKS",
      component: WeblinksView,
      meta: {
        title: i18n.global.t("page.weblinks"),
        code: "MODULE_STUDEA_WEBLINKS",
        breadcrumb: true,
        help: true,
      },
    },
    {
      path: "gradebook",
      name: "MODULE_STUDEA_NOTE",
      component: GradebookView,
      meta: {
        title: i18n.global.t("page.gradebook"),
        code: "MODULE_STUDEA_NOTE",
        breadcrumb: true,
        help: true,
      },
    },
    {
      path: "attestations",
      name: "attestations",
      component: AttestationsView,
      meta: {
        title: i18n.global.t("page.attestations"),
        code: "attestations",
        breadcrumb: true,
        help: true,
      },
    },
    {
      path: "message",
      name: "MODULE_STUDEA_MESSAGE",
      component: ChatView,
      meta: {
        title: i18n.global.t("page.message"),
        breadcrumb: true,
        code: "MODULE_STUDEA_MESSAGE",
        help: true,
      },
      children: [
        {
          path: ":id",
          name: "showMessage",
          component: ChatView,
          meta: {
            title: i18n.global.t("page.message"),
            breadcrumb: false,
          },
        },
        {
          path: "new",
          name: "newMessage",
          component: ChatView,
          meta: {
            title: i18n.global.t("page.new_message"),
            breadcrumb: true,
          },
        },
      ],
    },
    {
      path: "document",
      name: "MODULE_STUDEA_DOCUMENT",
      component: DocumentView,
      meta: {
        title: i18n.global.t("page.document"),
        breadcrumb: true,
        help: true,
        code: "MODULE_STUDEA_DOCUMENT",
      },
      children: [
        {
          path: ":alias(add)/doc",
          name: "dashboardAddDoc",
          component: DocumentView,
          meta: {
            title: i18n.global.t("page.document_add"),
            breadcrumb: true,
            help: false,
            key: "documents",
          },
        },
      ],
    },
    {
      path: "notepad",
      name: "MODULE_STUDEA_NOTEPAD",
      component: NotepadView,
      meta: {
        title: i18n.global.t("page.notepad"),
        breadcrumb: true,
        help: true,
        code: "MODULE_STUDEA_NOTEPAD",
      },
    },
    {
      path: "lessons",
      name: "MODULE_STUDEA_COURSE",
      component: LessonView,
      meta: {
        title: i18n.global.t("page.lessons"),
        breadcrumb: true,
        help: true,
        code: "MODULE_STUDEA_COURSE",
      },
    },
    {
      path: "lesson/:id",
      name: "lesson",
      component: LessonView,
      meta: {
        title: i18n.global.t("page.lessons"),
        breadcrumb: true,
        help: true,
      },
    },
    {
      path: "timetable",
      name: "MODULE_STUDEA_ABSENCE",
      redirect: { name: "absenceCalendar" },
      component: AbsenceLayoutView,
      meta: {
        title: i18n.global.t("page.timetable"),
        breadcrumb: true,
        code: "MODULE_STUDEA_ABSENCE",
      },
      children: [
        {
          path: "calendar",
          name: "absenceCalendar",
          component: AbsenceView,
          meta: {
            title: i18n.global.t("page.timetable"),
            breadcrumb: false,
            code: "timetable",
          },
        },
        {
          path: "list/absence",
          name: "absenceListEvent",
          component: AbsenceListView,
          meta: {
            title: i18n.global.t("absence.list_planning"),
            breadcrumb: true,
          },
        },
      ],
    },
    {
      path: "questionnaire/:triadId/:questionnaireId",
      name: "questionnaire",
      component: QuestionnaireView,
      meta: {
        title: i18n.global.t("page.questionnaire"),
        breadcrumb: true,
        code: "MODULE_STUDEA_QUESTIONNAIRE",
      },
    },
    {
      path: "training-course-list",
      name: "TrainingCourseList",
      component: TrainingCourseListView,
      meta: {
        title: i18n.global.t("page.training_course_list"),
        breadcrumb: true,
        help: true,
      },
    },
    {
      path: "dashboard-news",
      name: "newsLayout",
      redirect: { name: "adminNewsList" },
      component: DashboardLayoutView,
      meta: {
        title: i18n.global.t("admin.news.list.title"),
        breadcrumb: true,
      },
      children: [
        {
          path: "",
          name: "newsList",
          component: NewsListView,
          meta: {
            title: i18n.global.t("admin.news.list.title"),
            breadcrumb: false,
          },
        },
        {
          path: ":id",
          name: "newsShow",
          component: NewsView,
          meta: {
            title: i18n.global.t("admin.news.list.title"),
            breadcrumb: true,
          },
        },
      ],
    },
  ],
};
