<template>
  <div>
    <div v-if="skill && skill.apprenticeReply" class="reply">
      <AccordionSkillReplyComponent :skillReply="skill.apprenticeReply" />
    </div>
    <div v-else class="reply">--</div>
    <div v-if="skill && skill.tutorReply" class="reply">
      <AccordionSkillReplyComponent :skillReply="skill.tutorReply" />
    </div>
    <div v-else class="reply">--</div>
    <div v-if="skill && skill.apprenticeMasterReply" class="reply">
      <AccordionSkillReplyComponent :skillReply="skill.apprenticeMasterReply" />
    </div>
    <div v-else class="reply">--</div>
    <div v-if="skill && skill.leaManagerReply" class="reply">
      <AccordionSkillReplyComponent :skillReply="skill.leaManagerReply" />
    </div>
    <div v-else class="reply">--</div>
  </div>
</template>

<script>
import AccordionSkillReplyComponent from "@/components/table/accordion/AccordionSkillReplyComponent.vue";
import skill from "@/model/dashboard/skill";

export default {
  name: "AccordionTriadSkillRepliesComponent",
  components: { AccordionSkillReplyComponent },
  props: {
    triad: {
      type: [],
      required: true,
    },
    crew: {
      type: [],
      required: true,
    },
    questionnaire: {
      type: [],
      required: true,
    },
  },
  data() {
    return {
      skill: {},
      status: {},
      data: skill,
    };
  },
  mounted() {
    this.skill = this.findSkill(this.questionnaire.id, this.triad.id);
  },
  methods: {
    findSkill(questionnaireId, triadId) {
      return this.data.replies.find(
        (reply) =>
          reply.questionnaireId === questionnaireId &&
          reply.triadId === triadId,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.reply {
  margin-bottom: 5px;
}

.success {
  color: $green;
}

.fail {
  color: $red;
}

.warning {
  color: $orange;
}
</style>
