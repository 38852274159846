<template>
  <div class="row">
    <div class="col-lg-10 order-1 order-lg-0">
      <div class="widget">
        <div class="widget-heading -list d-block">
          <h1 class="textStyle">
            <router-link
              :to="{ name: 'adminUsersList' }"
              class="btn-icon"
              v-tooltip="{ title: $t('back') }"
              v-access:[permissions]="{
                access: accessManager().EDIT,
                code: adminManager().STUDEA_ADMIN_USER,
              }"
            >
              <FontAwesomeIcon icon="fa-light fa-arrow-left" />
            </router-link>
            {{ title ? title : "" }}
          </h1>
          <p class="fst-italic mt-1">
            <FontAwesomeIcon icon="fa-light fa-circle-info" />
            {{ $t("admin.user.profile_user_info") }}
          </p>
        </div>
        <div class="widget-content list">
          <div v-if="data && data.length">
            <SortTableComponent
              :actions="actions"
              :apiFilters="false"
              :columns="columns"
              :datas="data"
              :loading="loading"
              @refresh="refresh"
              @remove="remove"
            />
          </div>
          <div v-else class="text-center">
            <div class="noResult blockStyle">
              {{ $t("No data available") }}<br />
              {{ $t("Please click on the add button") }}
            </div>
            <router-link
              :to="{
                name: 'adminStudeaManagerProfileAdd',
                params: { alias: 'studea-manager-profile' },
              }"
              v-access:[permissions]="accessManager().EDIT"
              class="btn btn-primary mb-3"
              >{{ add ? add : $t("admin.add") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 order-0 order-lg-1">
      <div class="widget">
        <div class="widget-heading">
          <h2 class="textStyle">Actions</h2>
        </div>
        <div class="widget-content">
          <router-link
            :to="{
              name: 'adminStudeaManagerProfileAdd',
              params: { alias: 'studea-manager-profile' },
            }"
            v-access:[permissions]="accessManager().EDIT"
            class="btn btn-primary mb-2 me-2 btnStyle w-100"
          >
            <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
            {{ add ? add : $t("admin.add") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import { adminManager } from "@/services/admin/admin-manager";
import { accessManager } from "@/services/security/access-manager";
import { mapState } from "pinia";
import { useUserStore } from "@/store/user/user";

export default {
  name: "UsersProfileListView",
  components: {
    SortTableComponent,
  },
  data() {
    return {
      data: [],
      columns: [],
      loading: true,
      alias: "studea-manager-profile",
      listParams: [],
      actions: [],
      title: "",
      add: "",
      tradPrefix: "studeaManagerProfile",
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    adminManager() {
      return adminManager;
    },
    accessManager() {
      return accessManager;
    },
    remove(row) {
      adminManager.remove(this.alias, row.row.id, 1, false).then(() => {
        this.init();
      });
    },

    refresh(listParams) {
      this.listParams = {
        search: listParams.search,
        sortByField: listParams.sortByField,
        sortOrder: listParams.sortOrder,
        limit: listParams.limit,
        offset: listParams.offset,
      };
      this.init();
    },
    initActions() {
      this.actions = [
        {
          translation: "admin.modify",
          translationParams: { name: "name", id: "id" },
          icon: "pen",
          type: "success",
          actionType: "router",
          action: "adminStudeaManagerProfileEdit",
          alias: "studea-manager-profile",
          access: accessManager.EDIT,
        },
        {
          translation: "admin.delete",
          translationParams: { name: "name", id: "id" },
          icon: "trash",
          type: "danger",
          action: "remove",
          access: accessManager.DELETE,
        },
      ];
    },
    init() {
      this.initActions();
      this.loading = true;
      this.title = this.$tc("global.list.title", 2, {
        name: this.$tc("admin." + this.tradPrefix + ".title", 2).toLowerCase(),
      });
      this.add = this.$tc("global.add.title", 1, {
        name: this.$t("page.profile").toLowerCase(),
      });
      this.$router.currentRoute.value.meta.title = this.title;

      adminManager.list(this.alias, false, this.listParams).then((data) => {
        this.data = data;
        if (data && data.length) {
          this.columns = [
            {
              name: "name",
              sortable: true,
              sortWay: "",
            },
          ];
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.noResult {
  margin: 50px auto 20px;
  border: 1px solid $light-grey;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  font-size: 16px;
  max-width: 380px;
  width: 100%;
  text-align: center;
  border-radius: 6px;

  @include up-to-md {
    position: relative;
    transform: translateX(-50%);
    top: initial;
    font-size: 14px;
    text-align: center;
    width: 100%;
    padding: 15px;
  }
}

.widget-content {
  &.list {
    min-height: 175px;
    padding: 20px;
  }
}
</style>
