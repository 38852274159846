<template>
  <div :class="'user__card bg-' + checkCardColor()">
    <div class="user__card__name mb-2">
      <span class="fw-bold">{{ user.firstName }} {{ user.lastName }}</span> (#{{
        user.id
      }})
    </div>
    <div class="user__card__item">
      {{ $t("admin.user.mail_address") }} :
      <span class="ms-0 ms-lg-1 fw-bold"> {{ user.email }}</span>
    </div>
    <div class="user__card__item">
      {{ $t("admin.user.username") }} :
      <span class="ms-0 ms-lg-1 fw-bold" v-if="user.username">{{
        user.username
      }}</span>
      <span v-else class="ms-0 ms-lg-1 fw-bold">--</span>
    </div>
    <div class="user__card__item">
      {{ $t("admin.user.already_connected") }} :
      <span
        v-if="user.alreadyConnectedOne"
        :class="
          'ms-0 ms-lg-1 fw-bold ' +
          (!isDeletedInputCheck && !isKeepedInputCheck ? 'text-success' : '')
        "
      >
        {{ $t("admin.user.yes") }}
      </span>
      <span
        v-else
        :class="
          'ms-0 ms-lg-1 fw-bold ' +
          (!isDeletedInputCheck && !isKeepedInputCheck ? 'text-danger' : '')
        "
      >
        {{ $t("admin.user.no") }}
      </span>
    </div>
    <div class="user__card__item">
      {{ $t("admin.user.account_creation") }} :
      <span class="ms-0 ms-lg-1 fw-bold">{{ formatDate(user.createdAt) }}</span>
    </div>
    <div class="user__card__item">
      {{ $t("admin.user.external_id") }} :
      <span class="ms-0 ms-lg-1 fw-bold" v-if="user.externalId">
        {{ user.externalId }}
      </span>
      <span v-else class="ms-0 ms-lg-1 fw-bold">--</span>
    </div>
    <div class="user__card__item">
      {{ $t("admin.user.birth_date") }} :
      <span class="ms-0 ms-lg-1 fw-bold" v-if="formatDate(user.birthDate)">{{
        formatDate(user.birthDate)
      }}</span>
      <span v-else class="ms-0 ms-lg-1 fw-bold">--</span>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-2">
      <fieldset v-if="actions && Object.keys(actions).length">
        <RadioTypeComponent
          :model="keepAction"
          @update="updateAction"
          :componentKey="componentKey"
        />
        <RadioTypeComponent
          :model="deleteAction"
          @update="updateAction"
          :componentKey="componentKey"
        />
      </fieldset>
      <button
        v-if="isDeletedInputCheck || isKeepedInputCheck"
        type="button"
        class="btn-icon"
        v-tooltip="{ title: $t('admin.user.reset_choices') }"
        @click="resetValue"
      >
        <FontAwesomeIcon icon="fa-light fa-rotate-right" />
      </button>
    </div>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";
import RadioTypeComponent from "@/components/form/RadioTypeComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "TriadDuplicatedUserDetailComponent",
  components: { FontAwesomeIcon, RadioTypeComponent },
  props: {
    user: {
      type: Object,
      required: true,
    },
    itemToAdd: {
      type: {},
      required: true,
    },
    referenceUserNotDuplicated: {
      type: Boolean,
      required: true,
    },
    isKeepUserAlreadySet: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      componentKey: 0,
      keepAction: {
        vars: {
          block_prefixes: [],
          id: "radioKeep" + this.user.id,
          value: "",
          disabled: false,
          name: "userKeep-" + this.user.id,
          choices: [
            {
              label: this.$t("admin.user.keep"),
              value: "1",
              data: 1,
              attr: [],
            },
          ],
        },
      },

      deleteAction: {
        vars: {
          block_prefixes: [],
          id: "radioDelete" + this.user.id,
          value: "",
          disabled: false,
          name: "userDelete-" + this.user.id,
          choices: [
            {
              label: this.$t("admin.user.delete"),
              value: "0",
              data: 0,
              attr: [],
            },
          ],
        },
      },

      actions: {
        vars: {
          block_prefixes: [],
          id: "radio" + this.user.id,
          value: "",
          disabled: false,
          name: "user-" + this.user.id,
          choices: [
            {
              label: this.$t("admin.user.keep"),
              value: "1",
              data: 1,
              attr: [],
            },
            {
              label: this.$t("admin.user.delete"),
              value: "0",
              data: 0,
              attr: [],
            },
          ],
        },
      },
      isDeletedInputCheck: false,
      isKeepedInputCheck: false,
    };
  },
  watch: {
    referenceUserNotDuplicated() {
      this.actions.vars.disabled = !this.actions.vars.disabled;
      if (this.referenceUserNotDuplicated) {
        this.isDeletedInputCheck = false;
        this.isKeepedInputCheck = false;
        this.keepAction.vars.value = "";
        this.deleteAction.vars.value = "";
        this.keepAction.vars.disabled = true;
        this.deleteAction.vars.disabled = true;
      } else {
        this.keepAction.vars.disabled = false;
        this.deleteAction.vars.disabled = false;
      }
    },
    isKeepUserAlreadySet() {
      //Warning Prettier simplification break the condition
      if (this.isKeepUserAlreadySet && !this.keepAction.vars.value) {
        this.keepAction.vars.disabled = true;
      } else {
        this.keepAction.vars.disabled = false;
      }
    },
  },
  methods: {
    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    checkCardColor() {
      if (this.isDeletedInputCheck) {
        return "danger";
      }
      if (this.isKeepedInputCheck) {
        return "success";
      }
    },

    updateAction(payload) {
      if (payload === "0") {
        this.isDeletedInputCheck = true;
        this.isKeepedInputCheck = false;
        this.keepAction.vars.value = "";
        this.$emit("update", {
          delete: true,
          userId: this.user.id,
        });
      } else {
        this.isDeletedInputCheck = false;
        this.isKeepedInputCheck = true;
        this.deleteAction.vars.value = "";
        this.$emit("update", {
          keep: true,
          userId: this.user.id,
        });
      }
    },

    resetValue() {
      this.isKeepedInputCheck = false;
      this.isDeletedInputCheck = false;
      this.keepAction.vars.value = "";
      this.deleteAction.vars.value = "";
      this.$emit("update", {
        reset: true,
        userId: this.user.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user__card {
  font-weight: 200;
  color: var(--primary-color);
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  font-size: 15px;

  &:not(:last-child) {
    margin-bottom: 8px;
    @include up-to-lg {
      margin-bottom: 10px;
    }
  }

  &.-success {
    background-color: #6ac7a4;
  }

  &__name {
    font-weight: 700;
    @include up-to-lg {
      margin-bottom: 10px;
    }
  }

  &__item {
    display: flex;
    @include up-to-lg {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
}

.input-hidden {
  visibility: hidden;
}

.reload {
  cursor: pointer;
}
</style>
