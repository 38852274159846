import { notificationManager } from "@/services/utilities/notification-manager";
import router from "@/router";
import { loginManager } from "@/services/security/login-manager";
import { adminManager } from "@/services/admin/admin-manager";
import { userStore } from "@/main";

class AccessManager {
  ALL = 4;
  DELETE = 3;
  EDIT = 2;
  VIEW = 1;
  NONE = 0;

  checkRoleAccess(to, from, next) {
    if (to.meta && to.meta.authRequired) {
      let user = userStore.user;
      if (!Object.keys(user).length) {
        loginManager.refreshConnection().then(() => {
          userStore.fetchUserInfos().then(() => {
            let user = userStore.user;
            if (user && user.roles) {
              this.checkAccessByRoles(to, from, next, user);
              this.checkAccessByPermission(to, from, next);
            } else {
              router.push({ name: "Login" });
            }
          });
        });
      } else {
        if (user.roles) {
          this.checkAccessByRoles(to, from, next, user);
          this.checkAccessByPermission(to, from, next);
        } else {
          router.push({ name: "Login" });
        }
      }
    } else {
      return next();
    }
  }

  checkAccessByRoles(to, from, next, user) {
    if (user && user.roles) {
      if (
        to.meta.required_role &&
        !user.roles.includes(to.meta.required_role) &&
        !user.roles.includes("ROLE_STUDEA_ADMIN")
      ) {
        this.redirect(from);
      } else {
        return next();
      }
    }
  }

  checkAccessByPermission(to, from, next) {
    let permissions = userStore.permissions;
    let trainingCourse;
    if (
      userStore.user.roles &&
      !userStore.user.roles.includes("ROLE_STUDEA_ADMIN")
    ) {
      if (to.params.trainingCourseId) {
        trainingCourse = to.params.trainingCourseId;
      } else if (window.localStorage.getItem("studea-oldTrainingCourse")) {
        trainingCourse = window.localStorage.getItem(
          "studea-oldTrainingCourse",
        );
      }
    }
    if (
      !Object.values(permissions).length ||
      (trainingCourse &&
        trainingCourse.toString() !==
          window.localStorage.getItem("studea-oldTrainingCourse"))
    ) {
      userStore.fetchPermissions(trainingCourse).then(() => {
        this.checkPermission(to, from, next);
      });
    } else {
      this.checkPermission(to, from, next);
    }
  }

  checkPermission(to, from, next) {
    const available = this.isAvailableForUser(to);
    if (!available) {
      this.redirect(from);
    } else {
      return next();
    }
  }

  isAvailableForUser(to = null, code = null, access = null) {
    if (!code) {
      if (to && to.meta) {
        if (to.meta.code) {
          code = to.meta.code;
        } else if (to.meta.generic) {
          code = this.getGenericCode(to.meta.generic, to.params.alias);
        }
        if (to.meta.access) {
          access = to.meta.access;
        }
      } else if (router.currentRoute.value.meta.code) {
        code = router.currentRoute.value.meta.code;
      }
    }
    const permissions = userStore.permissions;
    if (code && access) {
      return this.getPermissionLevel(permissions[code]) >= access;
    }
    return true;
  }

  getGenericCode(type, alias) {
    switch (type) {
      case "admin":
        switch (alias) {
          case " ":
            return adminManager.STUDEA_ADMIN_DEGREE;
          case "contract-type":
            return adminManager.STUDEA_ADMIN_CONTRACT_TYPE;
          case "plan-contract":
            return adminManager.STUDEA_ADMIN_PLAN_CONTRACT;
          case "title-rncp":
            return adminManager.STUDEA_ADMIN_TITLE_RNCP;
          case "document":
          case "document-category":
            return adminManager.STUDEA_ADMIN_DOCUMENT;
          case "template":
            return adminManager.STUDEA_ADMIN_TEMPLATE;
          case "news":
            return adminManager.STUDEA_ADMIN_NEWS;
        }
    }
  }

  getPermissionLevel(value) {
    switch (value) {
      case "ALL":
        return this.ALL;
      case "DELETE":
        return this.DELETE;
      case "EDIT":
        return this.EDIT;
      case "VIEW":
        return this.VIEW;
      case "NONE":
        return this.NONE;
    }
  }

  redirect(from) {
    router.push(from);
    notificationManager.showAlert(
      "error",
      "Accès non autorisé",
      "Attention, vous n'avez pas les autorisations nécessaires pour accéder à cette page",
    );
  }
}

export const accessManager = new AccessManager();
