<template>
  <div ref="container" class="widget">
    <div class="legend mb-3">
      <span
        class="badge badge-primary d-inline-flex align-items-center"
        @click="handleTypeClick($event, 1)"
      >
        {{ $t("course") }}
        <FontAwesomeIcon
          v-if="this.activedType === 1"
          class="ms-2 fs-6"
          icon="fa-light fa-xmark"
        />
      </span>
      <span
        class="badge badge-success ms-2 d-inline-flex align-items-center"
        @click="handleTypeClick($event, 0)"
      >
        {{ $t("company") }}
        <FontAwesomeIcon
          v-if="this.activedType === 0"
          class="ms-2 fs-6"
          icon="fa-light fa-xmark"
        />
      </span>
      <span
        v-if="!editable"
        class="badge badge-warning ms-2 d-inline-flex align-items-center"
        @click="handleTypeClick($event, 3)"
      >
        {{ $t("absence") }}
        <FontAwesomeIcon
          v-if="this.activedType === 3"
          class="ms-2 fs-6"
          icon="fa-light fa-xmark"
        />
      </span>
    </div>
    <FullCalendar ref="fullCalendar" :options="calendarOptions">
      <template v-slot:eventContent="arg">
        <div class="event d-md-block d-none">
          {{ arg.event.extendedProps.hours }}<br />
          <span class="event__title">{{ arg.event.title }}</span>
        </div>
      </template>
    </FullCalendar>
    <div
      ref="contextMenu"
      :class="'contextMenu' + (contextMenu ? ' show' : '')"
    >
      <button
        v-if="!contextMenuEvent"
        class="contextMenu__btn"
        v-access:[permissions]="accessManager().EDIT"
        @click="addEvent($event)"
      >
        <FontAwesomeIcon class="contextMenu__icon" icon="fa-light fa-plus" />
        <span>{{ $t("absence.context_menu.add") }}</span>
      </button>
      <button
        v-if="!contextMenuEvent"
        class="contextMenu__btn"
        v-access:[permissions]="accessManager().EDIT"
        @click="addAbsence($event)"
      >
        <FontAwesomeIcon class="contextMenu__icon" icon="fa-light fa-plus" />
        <span>{{ $t("absence.context_menu.add_absence") }}</span>
      </button>
      <button
        v-if="contextMenuEvent"
        class="contextMenu__btn"
        @click="editEvent($event)"
        v-access:[permissions]="accessManager().EDIT"
      >
        <FontAwesomeIcon class="contextMenu__icon" icon="fa-light fa-pen" />
        <span>{{ $t("absence.context_menu.modify") }}</span>
      </button>
      <button
        v-if="contextMenuEvent"
        class="contextMenu__btn"
        @click="deleteEvent($event)"
        v-access:[permissions]="accessManager().DELETE"
      >
        <FontAwesomeIcon class="contextMenu__icon" icon="fa-light fa-trash" />
        <span>{{ $t("absence.context_menu.delete") }}</span>
      </button>
      <button
        v-if="contextMenuEvent"
        class="contextMenu__btn"
        @click="copyEvent($event)"
        v-access:[permissions]="accessManager().EDIT"
      >
        <FontAwesomeIcon class="contextMenu__icon" icon="fa-light fa-copy" />
        <span>{{ $t("absence.context_menu.copy") }}</span>
      </button>
      <button
        v-if="contextMenuEvent"
        class="contextMenu__btn"
        @click="cutEvent($event)"
        v-access:[permissions]="accessManager().EDIT"
      >
        <FontAwesomeIcon class="contextMenu__icon" icon="fa-light fa-cut" />
        <span>{{ $t("absence.context_menu.cut") }}</span>
      </button>
      <button
        v-if="paste"
        class="contextMenu__btn"
        @click="pasteEvent($event)"
        v-access:[permissions]="accessManager().EDIT"
      >
        <FontAwesomeIcon class="contextMenu__icon" icon="fa-light fa-paste" />
        <span>{{ $t("absence.context_menu.paste") }}</span>
      </button>
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { mapState } from "pinia";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { accessManager } from "@/services/security/access-manager";
import { useUserStore } from "@/store/user/user";
import { useThemeStore } from "@/store/theme/theme";

export default {
  name: "CalendarBlockComponent",
  props: {
    events: {
      type: [],
      required: true,
      default: [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    paste: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      contextMenu: false,
      contextMenuEvent: null,
      clickedDate: null,
      activedType: null,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        editable: this.editable,
        initialView: "dayGridMonth",
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        datesSet: this.handleDateSet,
        eventDrop: this.handleEventDrop,
        eventDidMount: this.eventDidMount,
        events: this.events,
        locale: this.locale,
        firstDay: 1,
        selectable: true,
        buttonText: {
          today: this.$t("Today"),
          month: this.$t("Month"),
          week: this.$t("Week"),
          day: this.$t("Day"),
        },
        height: "700px",
        headerToolbar: {
          start: "prev,next today",
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay",
        },
      },
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
    ...mapState(useThemeStore, {
      locale: (store) => store.locale,
    }),
  },
  watch: {
    events() {
      this.initCalendar();
    },
  },
  created() {
    this.initCalendar();
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    handleDateClick: function (arg) {
      this.$emit("dayClicked", arg.dateStr);
    },
    handleEventClick: function (arg) {
      this.$emit("dayClicked", arg.event.startStr);
    },
    handleDateSet: function (arg) {
      this.removeDayRightClick();
      this.$emit("viewChanged", arg);
      this.initDayRightClick();
    },
    handleEventDrop: function (arg) {
      this.$emit("eventMoved", arg);
    },
    eventDidMount: function (arg) {
      if (this.editable) {
        const event = arg.event;
        arg.el.addEventListener("contextmenu", (e) => {
          this.openContextMenu(e, event);
        });

        arg.el.addEventListener("dblclick", (e) => {
          e.stopPropagation();
          this.$emit("show", event);
        });
      }
    },

    openContextMenu(e, event = null) {
      e.preventDefault();
      e.stopPropagation();
      let target = e.target;
      if (!target.classList.contains("fc-day")) {
        target = e.target.closest("[data-date]");
      }
      this.clickedDate = target.getAttribute("data-date");
      this.contextMenu = true;
      this.contextMenuEvent = event;
      let rect = this.$refs.container.getBoundingClientRect();
      let x = e.clientX - rect.left;
      let y = e.clientY - rect.top;
      const contextMenu = this.$refs.contextMenu;
      contextMenu.style.top = y + "px";
      contextMenu.style.left = x + "px";
      document.addEventListener("click", this.closeContextMenu);
    },

    handleTypeClick: function (e, type) {
      let title = "";
      this.activedType = this.activedType === type ? null : type;
      switch (this.activedType) {
        case 0:
          title = this.$t("absence.company");
          break;
        case 1:
          title = this.$t("absence.course");
          break;
        case 3:
          title = this.$t("absence.absence");
          break;
      }

      this.$emit("typeClicked", { type: this.activedType, title: title });
    },

    closeContextMenu() {
      this.contextMenu = false;
      this.contextMenuEvent = null;
      this.clickedDate = null;
    },

    addEvent(e) {
      e.stopPropagation();
      this.$emit("add", this.clickedDate);
      this.closeContextMenu();
    },

    addAbsence(e) {
      e.stopPropagation();
      this.$emit("addAbsence", this.clickedDate);
      this.closeContextMenu();
    },

    editEvent(e) {
      e.stopPropagation();
      this.$emit("edit", this.contextMenuEvent);
      this.closeContextMenu();
    },

    deleteEvent(e) {
      e.stopPropagation();
      this.$emit("delete", this.contextMenuEvent);
      this.closeContextMenu();
    },

    copyEvent(e) {
      e.stopPropagation();
      this.$emit("copy", this.contextMenuEvent);
      this.closeContextMenu();
    },

    cutEvent(e) {
      e.stopPropagation();
      this.$emit("cut", this.contextMenuEvent);
      this.closeContextMenu();
    },

    pasteEvent(e) {
      e.stopPropagation();
      this.$emit("paste", this.clickedDate);
      this.closeContextMenu();
    },

    initCalendar() {
      this.initEvents();
      this.initLocale();
      this.initResponsive();
      this.initDayRightClick();
    },

    initResponsive() {
      const $this = this;
      window.addEventListener("resize", function () {
        if (window.innerWidth < 768) {
          $this.calendarOptions.height = "700px";
        } else {
          delete $this.calendarOptions.height;
        }
      });
    },

    initLocale() {
      this.calendarOptions.locale = this.locale;
    },

    initDayRightClick() {
      if (this.editable) {
        const days = document.querySelectorAll(".fc-day");
        for (let day of days) {
          day.addEventListener("contextmenu", this.openContextMenu);
        }
      }
    },

    removeDayRightClick() {
      if (this.editable) {
        const days = document.querySelectorAll(".fc-day");
        for (let day of days) {
          day.removeEventListener("contextmenu", this.openContextMenu);
        }
      }
    },

    initEvents() {
      const $this = this;
      let events = [];
      if (Object.keys(this.events).length) {
        Object.values(this.events).forEach(function (event) {
          let className = "";
          switch (event.type) {
            case 0:
              className = "bg-success";
              break;
            case 1:
              className = "bg-primary";
              break;
            case 3:
              className =
                "bg-warning" +
                (event.justified ? " justified" : " notJustified");
              break;
          }
          events.push({
            id: event.id,
            title: event.type === 3 ? $this.$t("absence.absence") : event.title,
            date: event.start_date,
            startDate: event.start_date,
            endDate: event.end_date,
            hours: event.hours,
            className: className,
            type: event.type === 3 ? "absence" : "event",
          });
        });
      }
      this.calendarOptions.events = events;
    },
  },
  components: {
    FontAwesomeIcon,
    BlockLoader,
    FullCalendar,
  },
};
</script>

<style lang="scss" scoped>
.widget {
  padding: 20px;
  margin-bottom: 0;
  position: relative;
}

.justified,
.notJustified {
  overflow: hidden;
  z-index: 2;
  position: relative;

  .event {
    position: initial;

    &:before {
      content: "";
      width: 10px;
      height: 100%;
      position: absolute;
      background: $red;
      right: 0;
      top: 0;
    }
  }
}

.justified {
  .event {
    &:before {
      background: $green;
    }
  }
}

.event {
  text-align: center;
  font-size: 11px;
  position: relative;

  &__title {
    font-weight: 700;
  }
}

.btn-icon {
  color: $white;
}

.legend {
  display: flex;

  .badge {
    cursor: pointer;
  }
}

.contextMenu {
  position: absolute;
  padding: 5px;
  background: $white;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1000;
  border-radius: 6px;
  border: 1px solid var(--primary-color);
  display: none;

  &.show {
    display: flex;
  }

  &__btn {
    background: $white;
    border: none;
    color: var(--primary-color);
    padding: 5px 10px;
    transition: all 300ms ease-in-out;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-radius: 6px;

    &:hover {
      background: var(--primary-color);
      color: $white;
    }
  }

  &__icon {
    margin-right: 8px;
  }
}
</style>
